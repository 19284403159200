import styles from './UserCard.module.scss';
import {UserCardDesc} from '../UserCardDesc';
import {Button} from '../Button';
import {Link} from 'react-router-dom';
import {ROUTS} from '../../constants';
import {useAuth} from '../../hooks';

export const UserCard = props => {
    const {nameTest, descStatus, id, picture, descriptionTest, finished, evaluated} = props;
    const {user} = useAuth();
    let btnText = '';
    let cardColor = '';
    let cardButtonColor = '';
    const CARD = {
        public: {
            status: 'Public',
            btnClass: 'button__user_pub',
            btnText: 'Start test',
        },
        available: {
            status: 'Available',
            btnClass: 'button__user_available',
            btnText: 'Request access',
        },
        eval: {
            status: 'On evaluation',
        },
        general: {
            btnClass: 'button__user',
        },
    };
    let path = '';
    switch (descStatus) {
        case CARD.public.status:
            btnText = CARD.public.btnText;
            cardColor = 'red';
            cardButtonColor = CARD.public.btnClass;
            break;
        case CARD.available.status:
            btnText = CARD.available.btnText;
            cardColor = 'red';
            cardButtonColor = CARD.available.btnClass;
            path = ROUTS.startTest;
            break;
        case CARD.eval.status:
            cardColor = 'red';
            break;
        default:
            btnText = '';
    }

    return (
        <div
            className={`${styles.card} ${!finished ? styles.card : evaluated ? styles.card__evaluation : styles.card__available}`}
        >
            <UserCardDesc
                picture={picture}
                descStatus={descStatus}
                nameTest={nameTest}
                descriptionTest={descriptionTest}
            />
            {!finished ? (
                <div className={styles.card__buttonContainer}>
                    <Link to={`${id}`}>
                        <Button
                            text={CARD.public.btnText}
                            nameClass={CARD.general.btnClass}
                            modifier={'button_addTest'}
                        />
                    </Link>
                </div>
            ) : evaluated ? (
                <div className={styles.card__buttonContainer}>
                    <Link to={`/user-checked/${user.id}/${id}`}>
                        <Button
                            text={`Checked ${evaluated}/10.00`}
                            nameClass={CARD.general.btnClass}
                            modifier={'button_addTest'}
                        />
                    </Link>
                </div>
            ) : (
                <div className={styles.title}>{'On evaluate'}</div>
            )}
        </div>
    );
};
