import {Account, Dashboard, Groups, Questionnaire, Tests, Users} from '../../assets/icons';

export const loggedInSeniorAdmin = routs => {
    return [
        {
            navigate: 'Dashboard',
            link: routs?.seniorDashboard,
            svg: <Dashboard />,
        },
        {
            navigate: 'Account',
            link: routs?.account,
            svg: <Account />,
        },
        {
            navigate: 'Users',
            link: routs?.users,
            svg: <Users />,
        },
        {
            navigate: 'Groups',
            link: routs?.groupsList,
            svg: <Groups />,
        },
        {
            navigate: 'Questionnaire',
            link: routs?.testsList,
            svg: <Questionnaire />,
        },
    ];
};
export const loggedInSuperAdmin = routs => {
    return [
        {
            navigate: 'Account',
            link: routs?.account,
            svg: <Account />,
        },
        {
            navigate: 'Company',
            link: routs?.companiesList,
            svg: <Groups />,
        },
    ];
};
export const loggedInUser = routs => {
    return [
        {
            navigate: 'Dashboard',
            link: routs?.userDashboard,
            svg: <Dashboard />,
        },
        {
            navigate: 'Account',
            link: routs?.account,
            svg: <Account />,
        },
    ];
};

export const notLoggedIn = routs => {
    return [
        {
            navigate: 'F.A.Q.',
            link: routs?.faq,
        },
        {
            navigate: 'About us',
            link: routs?.about,
        },
        {
            navigate: 'Contact',
            link: routs?.contact,
        },
    ];
};
