import {useState} from 'react';
import {Field} from 'formik';

import styles from './Input.module.scss';
import {ErrorText} from '../ErrorText';
import {EyeHideIcon, EyeShowIcon} from 'assets/icons';

const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), delay);
    };
};

export const Input = props => {
    const {type, name, nameClass, placeholder, as, id, disabled = false} = props;
    const [isShow, setIsShow] = useState(false);
    const isPassword = type === 'password';

    const handleTogglePassword = debounce(() => {
        setIsShow(prevState => !prevState);
    }, 200);

    return (
        <>
            <div className={styles.input_container}>
                {isPassword && (
                    <button
                        type="button"
                        className={styles.eye_button}
                        onClick={handleTogglePassword}
                    >
                        {isShow ? <EyeShowIcon /> : <EyeHideIcon />}
                    </button>
                )}

                <Field
                    className={`${styles[nameClass]} ${styles.input}`}
                    id={id}
                    type={`${isShow ? 'text' : type}`}
                    name={name}
                    placeholder={placeholder}
                    as={as}
                    disabled={disabled}
                />
            </div>

            <ErrorText
                name={name}
                tag={'p'}
            />
        </>
    );
};
