import {
    HomePage,
    LoginPage,
    RegisterPage,
    UserTestsPage,
    StartTestPage,
    AccountPage,
    ResetPasswordPage,
    DashboardPage,
    SingleQuizPage,
    SeniorDashboardPage,
    GroupPage,
    GroupsListPage,
    TestPage,
    TestsListPage,
    CompanyPage,
    CompaniesListPage,
    UsersPage,
    UserPage,
    UserCheckedPage,
    AssignedTests,
    UserDashboardPage,
} from '../pages';
import {PublicRoute} from '../routes/PublicRoute';
import {PrivateRoute} from '../routes/PrivateRoute';
import {saveDataBeforeRedirect} from 'utils';

export const ROUTS = {
    homePage: '/',
    register: '/register',
    testsList: '/tests-list',
    testEdit: '/tests-list/:id',
    testCreate: '/test-create',
    login: '/login',
    faq: '/faq',
    about: '/about-us',
    contact: '/contact',
    userTest: '/user-test',
    startTest: '/user-test/:id',
    assignTests: '/assign-tests/:id',
    account: '/account',
    dashboard: '/dashboard/:id',
    singleQuiz: '/dashboard/:id/:test_id',
    users: '/users',
    user: '/users/:id',
    usersDetails: '/users/:id',
    groupsList: '/groups-list',
    groupEdit: '/groups-list/:id',
    groupCreate: '/group-create',
    questionnaire: '/questionnaire',
    companiesList: '/companies-list',
    companyEdit: '/companies-list/:id',
    companyCreate: '/company-create',
    analytics: '/analytics',
    resetPassword: '/reset-password',
    seniorDashboard: '/senior-dashboard',
    userDashboard: '/user-dashboard',
    userCheckedTests: '/user-checked/:id/:test_id',
};
export const ROUTES_LIST = [
    {
        path: ROUTS.homePage,
        component: (
            <PublicRoute
                component={<HomePage />}
                redirectTo={ROUTS.account}
            />
        ),
    },
    {
        path: ROUTS.testsList,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<TestsListPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.testEdit,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<TestPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.testCreate,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<TestPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.register,
        component: (
            <PublicRoute
                component={<RegisterPage />}
                redirectTo={ROUTS.account}
            />
        ),
    },
    {
        path: ROUTS.login,
        component: (
            <PublicRoute
                component={<LoginPage />}
                redirectTo={ROUTS.account}
            />
        ),
    },
    {
        path: ROUTS.assignTests,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<AssignedTests />}
                redirectTo={ROUTS.assignTests}
            />
        ),
    },
    {
        path: ROUTS.userTest,
        component: (
            <PrivateRoute
                role={'User'}
                component={<UserTestsPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.startTest,
        component: (
            <PrivateRoute
                role={'User'}
                component={<StartTestPage />}
                redirectTo={ROUTS.login}
                beforeRedirect={saveDataBeforeRedirect}
            />
        ),
    },
    {
        path: ROUTS.faq,
        // component: <FAQPage/>,
    },
    {
        path: ROUTS.about,
        // component: <About/>,
    },
    {
        path: ROUTS.about,
        // component: <Contact/>,
    },
    {
        path: ROUTS.account,
        component: (
            <PrivateRoute
                role={'universal'}
                component={<AccountPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.userCheckedTests,
        component: (
            <PrivateRoute
                role={'universal'}
                component={<UserCheckedPage />}
                redirectTo={ROUTS.login}
                beforeRedirect={saveDataBeforeRedirect}
            />
        ),
    },
    {
        path: ROUTS.companiesList,
        component: (
            <PrivateRoute
                role={'Super Admin'}
                component={<CompaniesListPage />}
                redirectTo={ROUTS.login}
                pathComponent={ROUTS.dashboard}
            />
        ),
    },
    {
        path: ROUTS.companyEdit,
        component: (
            <PrivateRoute
                role={'Super Admin'}
                component={<CompanyPage />}
                redirectTo={ROUTS.login}
                pathComponent={ROUTS.dashboard}
            />
        ),
    },
    {
        path: ROUTS.companyCreate,
        component: (
            <PrivateRoute
                role={'Super Admin'}
                component={<CompanyPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.resetPassword,
        component: (
            <PublicRoute
                component={<ResetPasswordPage />}
                redirectTo={ROUTS.account}
            />
        ),
    },

    {
        path: ROUTS.dashboard,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<DashboardPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.seniorDashboard,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<SeniorDashboardPage />}
                redirectTo={ROUTS.account}
            />
        ),
    },
    {
        path: ROUTS.userDashboard,
        component: (
            <PrivateRoute
                role={'User'}
                component={<UserDashboardPage />}
                redirectTo={ROUTS.account}
            />
        ),
    },
    {
        path: ROUTS.singleQuiz,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<SingleQuizPage />}
                redirectTo={ROUTS.login}
                beforeRedirect={saveDataBeforeRedirect}
            />
        ),
    },
    {
        path: ROUTS.groupsList,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<GroupsListPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.groupEdit,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<GroupPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.groupCreate,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<GroupPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.users,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<UsersPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
    {
        path: ROUTS.usersDetails,
        component: (
            <PrivateRoute
                role={'Senior Admin'}
                component={<UserPage />}
                redirectTo={ROUTS.login}
            />
        ),
    },
];
