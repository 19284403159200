import styles from './SwitchContent.module.scss';
import {CheckBoxSwitch} from '../CheckBoxSwitch';
import {AddQuizPhoto} from '../AddQuizPhoto';
import React, {useState} from 'react';
import {AddQuizVideoLink} from '../AddQuizVideoLink';

export const SwitchContent = props => {
    const {
        photo = false,
        videoLink = false,
        formProps,
        checkedVideo,
        setCheckedVideo,
        recordedData,
        editTestId,
        title,
        subTitle,
        isEdit,
        personalInformation,
        update,
    } = props;

    const INPUT_ID_PHOTO = 'AddPhotoCheckbox';
    const INPUT_ID_VIDEO = 'VideoLinkCheckbox';
    const TITLE = 'Add photo';

    return (
        <>
            {photo && (
                <div className={styles.wrapper}>
                    <div className={styles.box}>
                        <h2 className={styles.title}>{title}</h2>
                        {subTitle && <span className={styles.text}>{subTitle}</span>}
                    </div>
                    <AddQuizPhoto
                        title={TITLE}
                        formProps={formProps}
                        recordedData={recordedData}
                        editTestId={editTestId}
                        isEdit={isEdit}
                        personalInformation={personalInformation}
                        update={update}
                    />
                </div>
            )}
            {videoLink && (
                <div className={`${styles.wrapper} ${styles.wrapper_video}`}>
                    <div className={`${styles.box} ${styles.box_video}`}>
                        <h2 className={styles.title}>Introductory video</h2>
                        <CheckBoxSwitch
                            checkedStatus={setCheckedVideo}
                            inputId={INPUT_ID_VIDEO}
                            formProps={formProps}
                            editTestId={editTestId}
                        />
                    </div>
                    {checkedVideo && <AddQuizVideoLink />}
                </div>
            )}
        </>
    );
};
