import styles from './CompaniesListPage.module.scss';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {Title} from '../../components/Title';
import {Table} from '../../components/Table';
import {Button} from '../../components/Button';
import {Search} from '../../assets/icons';
import {DEVICE, ROUTS, SORT_TYPES} from '../../constants';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks';
import {useEffect, useState} from 'react';
import {apiCompanies, apiEditCompany, apiRefreshUser} from '../../api';
import {useDispatch} from 'react-redux';
import {TYPE_TABLES} from '../../components/Table/mock';

export const CompaniesListPage = () => {
    const isShow = window.innerWidth >= DEVICE.desktop;

    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const handleClick = () => {
        navigate(ROUTS.companyCreate);
    };

    const fetchDeleteAndUpdateList = async ID => {
        await apiEditCompany(ID, 'DELETE');

        const data = await apiCompanies();

        setList(data?.data?.results);
    };

    const fetchData = async (flag, page) => {
        const {data} = await apiCompanies(flag, page);
        setList(data?.results);
        setCount(data?.count);
    };

    useEffect(() => {
        fetchData(sort, page);
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'Companies'} />
                        <div className={styles.wrapper}>
                            <label className={styles.label}>
                                {isShow ? 'Search' : null}
                                <div className={styles.inputBox}>
                                    <input
                                        className={styles.input}
                                        type={'text'}
                                        placeholder={'Search'}
                                    />
                                    <Search />
                                </div>
                            </label>
                            <Button
                                text={'Add new'}
                                modifier={'button_question'}
                                handleClick={handleClick}
                            />
                        </div>
                        <Table
                            pageCount={pageCount}
                            list={list}
                            setPage={setPage}
                            typeTables={TYPE_TABLES.companies}
                            fetch={fetchDeleteAndUpdateList}
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
