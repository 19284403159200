import styles from './CommentAudio.module.scss';
import {Play, StopVoice} from '../../assets/icons';
import {useRef, useState} from 'react';
import {CommentDelete} from '../CommentDelete';
import {useAuth} from '../../hooks';

export const CommentAudio = props => {
    const {item, fetch} = props;

    const [play, setPlay] = useState(true);
    const audio = useRef(null);
    const {user} = useAuth();
    const handlePlay = () => {
        audio.current.play();

        audio.current.onended = function () {
            setPlay(prev => !prev);
        };

        setPlay(prev => !prev);
    };

    const handlePause = () => {
        audio.current.pause();

        setPlay(prev => !prev);
    };

    return (
        <div className={styles.wrapper}>
            <audio
                className={styles.audio}
                controls
                src={item?.audio}
                ref={audio}
            ></audio>
            {play ? (
                <button
                    className={`${styles.button} ${styles.button_play}`}
                    type={'button'}
                    onClick={handlePlay}
                >
                    <Play />
                </button>
            ) : (
                <button
                    className={`${styles.button} ${styles.button_pause}`}
                    type={'button'}
                    onClick={handlePause}
                >
                    <StopVoice />
                </button>
            )}
            {user.type !== 'User' && (
                <CommentDelete
                    item={item}
                    fetch={fetch}
                />
            )}
        </div>
    );
};
