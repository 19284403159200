import styles from './CommentVideo.module.scss';
import {useState} from 'react';
import {Modal} from '../Modal';
import {Play} from '../../assets/icons';
import {CommentDelete} from '../CommentDelete';
import {useAuth} from '../../hooks';

export const CommentVideo = props => {
    const {item, fetch} = props;

    const [video, setVideo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const {user} = useAuth();
    const handleOpenVideo = video => {
        setVideo(video);

        setShowModal(prev => !prev);
    };

    return (
        <>
            {showModal && (
                <Modal
                    video={true}
                    setShowModal={setShowModal}
                    videoLink={video}
                />
            )}
            <div className={styles.wrapper}>
                <button
                    className={styles.play}
                    onClick={() => handleOpenVideo(item?.video)}
                    type={'button'}
                >
                    <Play />
                </button>
                {user.type !== 'User' && (
                    <CommentDelete
                        item={item}
                        fetch={fetch}
                    />
                )}
            </div>
        </>
    );
};
