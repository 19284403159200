import styles from './ButtonSwitch.module.scss';
import {Button} from '../../../components/Button';

export const ButtonSwitch = props => {
    const {isChange, handleChange, textChangeButton, formProps = false, setIsEdit, setUpdate = false} = props;

    const CANCEL = 'Cancel';
    const CONFIRM = 'Confirm';

    const handleCancel = () => {
        setIsEdit(prev => !prev);

        if (setUpdate && formProps) {
            setUpdate(prev => !prev);
            formProps.resetForm();
        }
    };

    return (
        <div className={styles.inner}>
            {isChange && (
                <Button
                    type={'button'}
                    text={textChangeButton}
                    handleClick={handleChange}
                />
            )}
            {!isChange && (
                <>
                    <Button
                        type={'button'}
                        text={CANCEL}
                        handleClick={handleCancel}
                    />
                    <Button
                        type={'submit'}
                        text={CONFIRM}
                        modifier={'button_question'}
                    />
                </>
            )}
        </div>
    );
};
