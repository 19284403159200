import React, {useEffect, useRef, useState} from 'react';
import Vimeo from '@u-wave/react-vimeo';

import {DEVICE, QUIZ_COMPONENTS} from '../../constants';

import {Button} from '../Button';

import styles from './Player.module.scss';

export const Player = props => {
    const {controls, video, fullVideo, handleClick, component, setButtonText} = props;

    const playerRef = useRef(null);
    const [play, setPlay] = useState(false);
    const [playAgain, setPlayAgain] = useState(false);
    const handleStartVideo = () => {
        if (playerRef.current) {
            setPlay(true);
            setPlayAgain(false);
            playerRef.current.player.play();
            if (controls && window.innerWidth <= DEVICE.mobile) {
                playerRef.current.player.requestFullscreen();
            }
        }
    };

    const handleEndVideo = () => {
        if (playerRef.current) {
            playerRef.current.player.exitFullscreen();
            setPlayAgain(true);
            handleClick(QUIZ_COMPONENTS.timer_message);
        }
    };

    useEffect(() => {
        if (playAgain) {
            if (component === QUIZ_COMPONENTS.start_test) {
                setButtonText('Start test');
            }
        }
    }, [playAgain]);

    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        setScreenHeight(window.innerHeight * 0.8);
        setScreenWidth(window.innerWidth);
    }, []);

    return (
        video && (
            <div className={`${fullVideo ? styles.startDesktop : styles.start}`}>
                <Vimeo
                    className={`${fullVideo ? styles.videoDesktopWrapper : styles.videoWrapper}`}
                    video={video.split('.com/')[1]}
                    controls={controls}
                    playsInline={true}
                    autoplay={false}
                    ref={playerRef}
                    onEnd={handleEndVideo}
                    width={screenWidth}
                    height={screenHeight}
                />
                {!play && !controls && component !== QUIZ_COMPONENTS.answer_to_question && (
                    <Button
                        nameClass={'button__start'}
                        text="Start question"
                        handleClick={handleStartVideo}
                    />
                )}
                {!play && controls && component !== QUIZ_COMPONENTS.answer_to_question && (
                    <Button
                        nameClass={'button__start'}
                        text="Learn about this test"
                        handleClick={handleStartVideo}
                    />
                )}
                {play && controls && playAgain && (
                    <Button
                        nameClass={'button__start'}
                        text="Watch again"
                        handleClick={handleStartVideo}
                    />
                )}
                {!play && controls && component === QUIZ_COMPONENTS.answer_to_question && (
                    <Button
                        nameClass={'button__start'}
                        text="Start video"
                        handleClick={handleStartVideo}
                    />
                )}
            </div>
        )
    );
};
