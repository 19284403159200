import {Navigate, useNavigate} from 'react-router-dom';

import {useAuth} from '../../hooks';
import {userAuthRedirect} from 'utils';

export const PrivateRoute = ({component: Component, redirectTo = '/', beforeRedirect = () => false, role}) => {
    const {isLoggedIn, isRefreshing, user} = useAuth();
    const shouldRedirect = !isRefreshing && !isLoggedIn;
    const navigate = useNavigate();

    if (shouldRedirect) {
        beforeRedirect();

        return <Navigate to={redirectTo} />;
    } else {
        // for login/registration redirect only
        userAuthRedirect(navigate);

        if (user.type === role) {
            return Component;
        } else if (role === 'universal') {
            return Component;
        } else {
            return <Navigate to={'/account'} />;
        }
    }
};
