import styles from './DashboardPage.module.scss';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {useEffect, useState} from 'react';
import {apiRefreshUser, apiUserTests} from '../../api';
import {SORT_TYPES} from '../../constants';
import {Footer} from '../../components/Footer';
import {Table} from '../../components/Table';
import {useDispatch} from 'react-redux';
import {TYPE_TABLES} from '../../components/Table/mock';
import {useAuth} from '../../hooks';
import {useParams} from 'react-router-dom';

export const DashboardPage = () => {
    const [listUser, setListUser] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const {id} = useParams();
    const {user} = useAuth();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);
    const fetchDataUser = async () => {
        const {data} = await apiUserTests(id);
        setListUser(data?.results);
        setCount(data?.count);
    };

    useEffect(() => {
        fetchDataUser(user?.id);
    }, []);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={true} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Table
                            pageCount={pageCount}
                            list={listUser}
                            setSort={setSort}
                            setPage={setPage}
                            typeTables={TYPE_TABLES.user}
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
