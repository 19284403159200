import React from 'react';
import {useNavigate} from 'react-router-dom';

import {QUIZ_COMPONENTS, ROUTS} from '../../constants';

import {ArrowLeft} from '../../assets/icons/ArrowLeft';
import {Button} from '../Button';
import {Title} from '../Title';

import styles from './QuizHeaderMobile.module.scss';

export const QuizHeaderMobile = props => {
    const {index, countTests, component, questionName, testName} = props;

    const navigate = useNavigate();
    const backButton = () => {
        navigate(ROUTS.user);
    };

    return (
        <>
            <div
                className={QUIZ_COMPONENTS.start_test === component ? styles.buttonContainer : styles.progressContainer}
            >
                {QUIZ_COMPONENTS.start_test === component && (
                    <Button
                        text={'Back'}
                        handleClick={backButton}
                        type={'button'}
                        nameClass={'button__backTests'}
                        image={<ArrowLeft />}
                    />
                )}

                {QUIZ_COMPONENTS.start_test !== component && (
                    <div className={styles.progress}>
                        Progress - {index + 1 < 10 ? `0${index + 1}` : index + 1}/
                        {countTests < 10 ? `0${countTests}` : countTests}
                    </div>
                )}
            </div>

            {QUIZ_COMPONENTS.start_test !== component && (
                <div>
                    <div className={styles.secondTitleTest}>{testName}</div>
                </div>
            )}

            {QUIZ_COMPONENTS.start_test !== component && (
                <div className={styles.title}>
                    {QUIZ_COMPONENTS.answer_to_question === component ? questionName : 'QUESTION'}
                </div>
            )}

            {QUIZ_COMPONENTS.start_test === component ? (
                <div className={styles.titleTest}>
                    <Title text={testName} />
                </div>
            ) : (
                <div className={styles.titleQuestion}>
                    <Title text={QUIZ_COMPONENTS.answer_to_question === component ? 'RIGHT ANSWER' : questionName} />
                </div>
            )}
        </>
    );
};
