import {useReactMediaRecorder} from 'react-media-recorder';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../../../hooks';
import {TYPE_COMMENT} from '../../../../../../constants';
import {validationSchema} from '../../../../../../utils';
import {VoiceRecorderComment} from '../../../../../VoiceRecorderComment';
import {ErrorText} from '../../../../../ErrorText';
import {Button} from '../../../../../Button';
import {Form, Formik} from 'formik';
import {Input} from '../../../../../Input';
import styles from './CommentQuiz.module.scss';
import * as Yup from 'yup';
import {apiCommentsTest} from '../../../../../../api';

export const NewCommentQuiz = props => {
    const {typeComment, setAddComment, fetchComments, item} = props;

    const {startRecording, stopRecording, mediaBlobUrl} = useReactMediaRecorder({audio: true});

    const [formProps, setFormProps] = useState({});
    const {user} = useAuth();

    const AUTHOR_ID = user?.id;
    const USER_ID = user?.id;
    const handleSubmit = async values => {
        const formDataValues = new FormData();
        for (const key in values) {
            let value = values[key];

            formDataValues.append(key, value);
        }
        // author - admin who leaves a comment
        formDataValues.append('author', AUTHOR_ID);
        // user - whom we leave the commit
        formDataValues.append('user', USER_ID);

        await apiCommentsTest(formDataValues, null, 'POST');

        fetchComments();

        setAddComment(prev => !prev);
    };

    let initialValues = {};
    let schemaValidation = null;

    switch (typeComment) {
        case TYPE_COMMENT.text:
            initialValues = {
                text: '',
                answer: item?.answers?.id,
            };
            schemaValidation = Yup.object({
                text: validationSchema.description,
                answer: validationSchema.commitQuestion,
            });
            break;
        case TYPE_COMMENT.video:
            initialValues = {
                video: '',
                answer: item?.answers?.id,
            };
            schemaValidation = Yup.object({
                video: validationSchema.commitVideo,
                answer: validationSchema.commitQuestion,
            });
            break;
        case TYPE_COMMENT.audio:
            initialValues = {
                audio: '',
                answer: item?.answers?.id,
            };
            schemaValidation = Yup.object({
                audio: validationSchema.commitAudio,
                answer: validationSchema.commitQuestion,
            });
            break;
        default:
            initialValues = {answer: item?.answers?.id};
    }

    const handleStartRecorder = () => {
        startRecording();
    };

    const handleStopRecorder = props => {
        stopRecording();

        setFormProps(props);
    };

    const handleClick = () => {
        setAddComment(prev => !prev);
    };

    const getAudio = async file => {
        let blob = await fetch(file).then(r => r.blob());

        let type = blob.type.split('/')[1];

        const audio = new File([blob], 'audio.' + type, {type: blob.type});

        formProps?.setFieldValue('audio', audio);
    };

    useEffect(() => {
        mediaBlobUrl && getAudio(mediaBlobUrl);
    }, [mediaBlobUrl]);

    return (
        <div className={styles.wrapper}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schemaValidation}
            >
                {formProps => (
                    <Form>
                        {typeComment === TYPE_COMMENT.text && (
                            <Input
                                type={'text'}
                                name={'text'}
                                placeholder={'Text here'}
                                nameClass={'input_commitText'}
                                as={'textarea'}
                            />
                        )}
                        {typeComment === TYPE_COMMENT.video && (
                            <Input
                                type={'url'}
                                name={'video'}
                                placeholder={'video link'}
                                nameClass={'input_commitVideo'}
                            />
                        )}
                        {typeComment === TYPE_COMMENT.audio && (
                            <>
                                <VoiceRecorderComment
                                    handleStartRecorder={handleStartRecorder}
                                    handleStopRecorder={handleStopRecorder}
                                    formProps={formProps}
                                />
                                <ErrorText
                                    name={'audio'}
                                    tag={'p'}
                                />
                            </>
                        )}
                        <div className={styles.buttonBox}>
                            <div className={styles.submit}>
                                <Button
                                    type={'submit'}
                                    text={'Save'}
                                    modifier={'button_accent'}
                                />
                            </div>
                            <div className={styles.submit}>
                                <Button
                                    type={'submit'}
                                    text={'Cancel'}
                                    handleClick={handleClick}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
