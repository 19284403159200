export const questions = [
    {
        title: 'Question name:',
        type: 'text',
        name: value => `questions.${value}.question_name`,
        placeholder: 'Question name',
        nameClass: 'title',
    },
    {
        title: 'Questions',
        type: 'url',
        name: value => `questions.${value}.question_link`,
        placeholder: 'Vimeo link',
        nameClass: 'title',
    },
    {
        title: 'Right answer:',
        type: 'url',
        name: value => `questions.${value}.question_answer`,
        placeholder: 'Vimeo link',
        nameClass: 'title',
    },
];
