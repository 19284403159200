export const Edit = () => {
    return (
        <svg
            viewBox="-8 -8 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.998718 11.6668C1.04852 11.6668 1.09898 11.661 1.14846 11.6499L4.21259 10.9429C4.33433 10.9149 4.44598 10.853 4.5342 10.7645L9.64162 5.65706C9.64423 5.65446 9.64781 5.65381 9.65041 5.6512C9.65302 5.6486 9.65367 5.64535 9.65627 5.64274L11.0811 4.21761C11.4577 3.84131 11.6654 3.3387 11.6654 2.80355C11.6654 2.26839 11.4577 1.76579 11.0814 1.38949L10.6097 0.91748C9.85549 0.162923 8.53452 0.162923 7.78094 0.91748L6.35256 2.34587C6.35126 2.34717 6.3493 2.34782 6.34768 2.34912C6.34605 2.35042 6.34572 2.35238 6.34442 2.35433L1.23439 7.46436C1.14585 7.5529 1.08433 7.66423 1.05601 7.78597L0.348978 10.8504C0.297546 11.0744 0.364929 11.3088 0.527364 11.4715C0.653666 11.5978 0.823913 11.6668 0.998718 11.6668ZM8.72365 1.86019C8.973 1.61149 9.41799 1.61149 9.66669 1.86019L10.1384 2.33219C10.2634 2.45719 10.3321 2.62451 10.3321 2.80355C10.3321 2.98258 10.2634 3.1499 10.1384 3.2749L9.17743 4.23584L7.76272 2.82113L8.72365 1.86019ZM2.31252 8.27165L6.82001 3.76383L8.23472 5.17855L3.72658 9.68636L1.88804 10.1108L2.31252 8.27165ZM13.6657 13.0002C13.6657 13.3687 13.3672 13.6668 12.999 13.6668H0.999043C0.630879 13.6668 0.332377 13.3687 0.332377 13.0002C0.332377 12.6317 0.630879 12.3335 0.999043 12.3335H12.999C13.3672 12.3335 13.6657 12.6317 13.6657 13.0002Z"
                fill="black"
            />
        </svg>
    );
};
