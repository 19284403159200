import styles from './BasicQuizInfo.module.scss';
import {Input} from '../Input';
import {mock} from './mock';

export const BasicQuizInfo = ({formProps}) => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Basic information</h2>
            {mock.map(item => (
                <label
                    key={item?.id}
                    className={styles.label}
                    htmlFor={item?.id}
                >
                    {item?.text}
                    <Input
                        type={item?.type}
                        name={item?.name}
                        placeholder={item?.placeholder}
                        nameClass={item?.nameClass}
                        id={item?.id}
                        as={item?.as}
                        formProps={formProps}
                    />
                </label>
            ))}
        </div>
    );
};
