import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import axios from 'axios';

import {QUIZ_COMPONENTS, ROUTS} from '../../constants';
import {apiFinishTest, apiRefreshUser, apiTestQuiz} from '../../api';

import {UserStartVideo} from '../../components/UserStartVideo';
import {StartTestVideo} from '../../components/StartTestVideo';
import {TimerMessage} from '../../components/TimerMessage';
import {VoiceRecorder} from '../../components/VoiceRecorder';
import {AnswerToQuestion} from '../../components/AnswerToQuestion';
import {QuizHeaderMobile} from '../../components/QuizHeaderMobile';
import {QuizHeaderDesktop} from '../../components/QuizHeaderDesktop';
import {ErrorRecording} from '../../components/ErrorRecording';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';

import styles from './StartTest.module.scss';

export const StartTestPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const windowWidth = window.innerWidth;

    const [isTimerEnd, setIsTimerEnd] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [test, setTest] = useState();
    const [component, setComponent] = useState(QUIZ_COMPONENTS.start_test);
    const [index, setIndex] = useState(0);
    const [width, setWidth] = useState(windowWidth);
    const [stream, setStream] = useState(null);
    const [error, setError] = useState(null);

    const nextQuestion = () => {
        setIndex(index + 1);
        setComponent(QUIZ_COMPONENTS.test_video);
        if (index + 1 === test?.questions.length) {
            apiFinishTest(id).then(() => {
                navigate(ROUTS.userDashboard, {state: {navigateToEvaluate: true}});
            });
        }
    };

    const handleClick = componentState => {
        setComponent(componentState);
    };

    const fetchData = async () => {
        const {data} = await apiTestQuiz(id);

        setTest(data);
        setCurrentQuestion(data?.questions[index]);
    };

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setCurrentQuestion(test?.questions[index]);
    }, [index]);

    useEffect(() => {
        if (component === QUIZ_COMPONENTS.timer_message && !stream) {
            if (stream) return;
            let didCancel = false;

            const getUserMedia = async () => {
                if (!didCancel) {
                    try {
                        setStream(await navigator.mediaDevices.getUserMedia({audio: true}));
                    } catch (e) {
                        setComponent(QUIZ_COMPONENTS.error_recording);
                        setError(e);
                    }
                }
            };

            const cancel = () => {
                didCancel = true;
                if (!stream) return;

                if (stream?.getAudioTracks) {
                    stream.getAudioTracks().map(track => track.stop());
                }
                if (stream?.stop) {
                    stream.stop();
                }
            };
            getUserMedia();
            return cancel;
        }

        if (component === QUIZ_COMPONENTS.timer_message) {
            setTimeout(function () {
                setIsTimerEnd(true);
                if (error) {
                    handleClick(QUIZ_COMPONENTS.error_recording);
                } else {
                    handleClick(QUIZ_COMPONENTS.voice_recorder);
                }
            }, 5000);
        }
    }, [error, stream, component]);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={true} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        {width <= 666 && (
                            <QuizHeaderMobile
                                index={index}
                                countTests={test?.questions.length}
                                component={component}
                                questionName={currentQuestion?.question_name}
                                testName={test?.name}
                            />
                        )}

                        {width >= 667 && (
                            <QuizHeaderDesktop
                                index={index}
                                countTests={test?.questions.length}
                                component={component}
                                questionName={currentQuestion?.question_name}
                                testName={test?.name}
                            />
                        )}

                        {(() => {
                            switch (component) {
                                case QUIZ_COMPONENTS.error_recording:
                                    return <ErrorRecording error={error.toString()} />;
                                case QUIZ_COMPONENTS.start_test:
                                    return (
                                        <UserStartVideo
                                            question_link={test?.test_introductory_video_link}
                                            handleClick={handleClick}
                                            component={component}
                                        />
                                    );
                                case QUIZ_COMPONENTS.test_video:
                                    return (
                                        <StartTestVideo
                                            handleClick={handleClick}
                                            video={currentQuestion?.question_link}
                                        />
                                    );
                                case QUIZ_COMPONENTS.timer_message:
                                    return <TimerMessage />;
                                case QUIZ_COMPONENTS.voice_recorder:
                                    return (
                                        <VoiceRecorder
                                            handleClick={handleClick}
                                            isTimerEnd={isTimerEnd}
                                            questionId={currentQuestion?.id}
                                            stream={stream}
                                        />
                                    );
                                case QUIZ_COMPONENTS.answer_to_question:
                                    return (
                                        <AnswerToQuestion
                                            textButton={index === test?.questions.length - 1 ? 'Finish' : 'Next'}
                                            answerLink={currentQuestion?.question_answer}
                                            handleClick={nextQuestion}
                                            component={component}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
