import React, {useEffect, useState} from 'react';
import {Table} from '../Table';
import {SORT_TYPES} from '../../constants';
import {apiOnEvaluateUserTests, apiRefreshUser} from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import {TYPE_TABLES} from '../Table/mock';

export const Onevaluate = () => {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const {data} = await apiOnEvaluateUserTests();

            setList(data?.results);
            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <>
            <Table
                isLoading={isLoading}
                pageCount={pageCount}
                list={list}
                setSort={setSort}
                setPage={setPage}
                typeTables={TYPE_TABLES.onEvaluateTests}
            />
        </>
    );
};
