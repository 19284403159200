import styles from './CommentText.module.scss';
import {CommentDelete} from '../CommentDelete';
import {useAuth} from '../../hooks';

export const CommentText = props => {
    const {item, fetch} = props;
    const {user} = useAuth();

    return (
        <div className={styles.wrapper}>
            <p className={styles.text}>{item?.text}</p>
            {user.type !== 'User' && (
                <CommentDelete
                    item={item}
                    fetch={fetch}
                />
            )}
        </div>
    );
};
