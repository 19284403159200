export const BASE_URL = process.env.REACT_APP_URL;

export const TESTS = next => `${BASE_URL}tests/${next ? '?page=' + next : ''}`;
export const SORT_TESTS = (flag, page) => `${BASE_URL}tests/${flag ? `?ordering=${flag}&page=${page}` : ''}`;
export const AVAILABLE_TESTS = (flag, page) => `${BASE_URL}tests/available_tests/${flag ? `?page=${page}` : ''}`;
export const ASSIGNED_TESTS = (flag, page, id) =>
    `${BASE_URL}tests/assigned_tests_for_user/${id}/${flag ? `?page=${page}` : ''}`;
export const CHECKED_TESTS = id => `${BASE_URL}tests/checked_tests_for_user/${id}/`;
export const UPDATE_TESTS = id => `${BASE_URL}tests/${id}/`;
export const ASSIGN_TESTS = `${BASE_URL}tests/assign_test/`;
export const UPDATE_QUESTIONS = id => `${BASE_URL}tests/question/${id}/`;
export const ANSWER_TO_QUESTION = `${BASE_URL}tests/answer/`;
export const FINISH_TEST = id => `${BASE_URL}tests/finish_test/${id}/`;
export const REGISTER = `${BASE_URL}authentication/register/`;
export const LOGIN = `${BASE_URL}authentication/login/`;
export const LOGOUT = `${BASE_URL}authentication/logout/`;
export const USER = `${BASE_URL}authentication/users/me/`;
export const USER_INFO = id => `${BASE_URL}authentication/users/${id}/`;
export const USERS = (flag, admin, company, page) =>
    `${BASE_URL}authentication/users/?is_admin=${admin}&organization__slug=${company}${flag ? `&ordering=${flag}&page=${page}` : ''}`;
export const DEFAULT_GROUP_USERS = company => `${BASE_URL}organizations/get_users_from_default_group/${company}/`;
export const RESET_PASSWORD = `${BASE_URL}authentication/users/reset-password/`;
export const USER_TESTS = id => `${BASE_URL}tests/evaluated_tests/${id}/`;
export const USER_EVALUATED_TESTS = (id, page) =>
    `${BASE_URL}tests/answer/by-user/${id}/?${page ? `page=${page}` : ''}`;
export const NEED_EVALUATION = id => `${BASE_URL}tests/tests_on_evaluation_for_users/`;
export const ADMIN_GROUPS = id => `${BASE_URL}/organizations/admined_groups/${id}/`;
export const NEED_ON_EVALUATION = () => `${BASE_URL}tests/on_evaluate/`;
export const USER_TEST = (userID, testID) => `${BASE_URL}tests/answer/by-user/${userID}/${testID}/`;
export const COMPANY = `${BASE_URL}organizations/object/`;
export const COMPANY_SETTINGS = `${BASE_URL}organizations/settings/`;
export const UPDATE_COMPANY = id => `${BASE_URL}organizations/object/${id}/`;
export const SORT_COMPANY = (flag, page) =>
    `${BASE_URL}organizations/object/${flag ? `?ordering=${flag}&page=${page}` : ''}`;
export const GROUPS = `${BASE_URL}organizations/groups/`;
export const UPDATE_GROUP = id => `${BASE_URL}organizations/groups/${id}/`;
export const SORT_GROUP = (flag, page, organization__slug) =>
    `${BASE_URL}organizations/groups/${flag ? `?ordering=${flag}&page=${page}` : ''}&organization__slug=${organization__slug}`;
export const EVALUATE = id => `${BASE_URL}${id ? `tests/answer/evaluate/${id}/` : 'tests/answer/evaluate/'}`;
export const COMMENTS = id => `${BASE_URL}${id ? `tests/comments/${id}/` : 'tests/comments/'}`;
export const COMMENTS_BY_ID = (test, user) =>
    `${BASE_URL}${test ? `tests/comments/by_test_id/?test=${test}&user=${user}` : ''}`;
export const REGISTER_REQUEST = (id, page) => `${BASE_URL}authentication/users/register-requests/${id}/?page=${page}`;
export const REGISTER_REQUEST_ACTIONS = `${BASE_URL}authentication/users/register-requests/action/`;
export const SEND_RESET_PASSWORD_CODE = `${BASE_URL}authentication/send-forgot-password-code/`;
export const ASSIGN_TEST = `${BASE_URL}tests/assign_test_to_user/`;
export const CHANGE_PASSWORD = `${BASE_URL}authentication/change-password/`;
