import React from 'react';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';
import {Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import {Footer} from '../../components/Footer';
import {Button} from '../../components/Button';
import {Header} from '../../components/Header';
import {Title} from '../../components/Title';
import {Input} from '../../components/Input';

import {validationSchema, userAuthRedirect} from '../../utils';
import {apiRegister} from '../../api';
import {ROUTS} from '../../constants';

import styles from './RegisterPage.module.scss';

export const RegisterPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organization = sessionStorage.getItem('organization');

    const handleSubmit = async values => {
        dispatch(apiRegister(values));
        userAuthRedirect(navigate);
    };

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        organization: organization,
        password: '',
    };

    // TODO коли нажали "запамятати мене" задаєм якийсь термін токену при запису в локалСтор

    return (
        <>
            <Header isLoggedIn={false} />
            <main className={styles.container}>
                <div className={styles.wrapper}>
                    <Title text={'Sign Up'} />
                    <p className={styles.subTitle}>Hello User, enter your register details</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            first_name: validationSchema.first_name,
                            last_name: validationSchema.last_name,
                            email: validationSchema.email,
                            password: validationSchema.password,
                            confirm_password: validationSchema.confirm_password,
                        })}
                        onSubmit={handleSubmit}
                    >
                        <Form className={styles.form}>
                            <label className={styles.label}>
                                First Name
                                <Input
                                    type={'text'}
                                    name={'first_name'}
                                    placeholder={'Write your name'}
                                />
                            </label>
                            <label className={styles.label}>
                                Last Name
                                <Input
                                    type={'text'}
                                    name={'last_name'}
                                    placeholder={'Write your last name'}
                                />
                            </label>
                            <label className={styles.label}>
                                E-mail
                                <Input
                                    type={'email'}
                                    name={'email'}
                                    placeholder={'Write your E-mail'}
                                />
                            </label>
                            <label className={styles.label}>
                                Password
                                <Input
                                    type={'password'}
                                    name={'password'}
                                    placeholder={'Write your password'}
                                />
                            </label>
                            <label className={styles.label}>
                                Confirm Password
                                <Input
                                    type={'password'}
                                    name={'confirm_password'}
                                    placeholder={'Confirm your password'}
                                />
                            </label>
                            <div className={styles.inner}></div>
                            <Button
                                type={'submit'}
                                text={'Register'}
                                modifier={'button_accent'}
                            />
                        </Form>
                    </Formik>
                    <div className={styles.box}>
                        <p className={styles.text}>
                            Already have an account?{' '}
                            <Link
                                to={ROUTS.login}
                                className={styles.goto}
                            >
                                Log in here.
                            </Link>
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};
