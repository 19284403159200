import React, {useEffect, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {Footer} from '../../components/Footer';
import {Title} from '../../components/Title';
import {UserHeader} from '../../components/UserHeader';
import {Header} from '../../components/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from '../../hooks';
import makeAnimated from 'react-select/animated';
import styles from './GroupPage.module.scss';
import {apiCreateGroup, apiDefaultListUsers, apiEditGroup, apiListUsers, apiRefreshUser} from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import {Input} from '../../components/Input';
import {MySelect} from '../../components/MySelect';
import {ErrorText} from '../../components/ErrorText';
import {Button} from '../../components/Button';
import {validationSchema} from '../../utils';
import {ROUTS} from '../../constants';

export const GroupPage = () => {
    const animatedComponents = makeAnimated();
    const {id} = useParams();
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [group, setGroup] = useState({});
    const {organization_id, organization_slug} = useSelector(state => state.user.user);

    const initialValues = {
        organization: organization_id,
        name: group,
        admin: '',
        user_ids: [],
    };
    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    const handleSubmit = async values => {
        try {
            if (!id) {
                const updatedValues = {
                    ...values,
                    user_ids: values.user_ids.join(', '),
                };
                const {data} = await apiCreateGroup(updatedValues);
                if (data) {
                    navigate(ROUTS.groupsList);
                }
            }

            if (id) {
                const updatedValues = {
                    ...values,
                    user_ids: values.user_ids.join(', '),
                };
                const {data} = await apiEditGroup(id, 'PUT', updatedValues);
                if (data) {
                    navigate(ROUTS.groupsList);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUsersData = async () => {
        const {data} = await apiDefaultListUsers(organization_slug);
        setUsers(data.results);
    };
    const fetchAdminsData = async () => {
        const {data} = await apiListUsers('', true, organization_slug);
        setAdmins(data?.results);
    };

    const fetchCurrentGroup = async id => {
        const {data} = await apiEditGroup(id, 'GET');
        setGroup(data);
        setInitialFormValues({
            organization: data.organization || 78,
            name: data.name || '',
            admin: data.admin || '',
            user_ids: data.users ? data.users.map(user => user.id) : [],
        });
        if (data.users) {
            if (data.name !== 'Default Group') {
                setUsers(prevUsers => {
                    const existingUserIds = new Set(prevUsers.map(user => user.id));
                    const newUsers = data.users.filter(user => !existingUserIds.has(user.id));
                    return [...prevUsers, ...newUsers];
                });
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const usersData = fetchUsersData();
            const adminsData = fetchAdminsData();
            await Promise.all([usersData, adminsData]);
            if (id) {
                fetchCurrentGroup(id);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, [dispatch]);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={id ? 'Edit group' : 'Create new group'} />
                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={handleSubmit}
                            validationSchema={Yup.object({
                                name: validationSchema.name,
                                admin: validationSchema.admin,
                                user_ids: validationSchema.user_ids,
                                organization: validationSchema.organization,
                            })}
                        >
                            {({values, setFieldValue}) => (
                                <Form className={styles.form}>
                                    <div className={styles.wrapper}>
                                        <label className={styles.label}>
                                            Group name*
                                            <Input
                                                type={'text'}
                                                name={'name'}
                                                placeholder={'Write group name'}
                                            />
                                        </label>
                                        <label className={`${styles.label}`}>
                                            Select administrator*
                                            <Field
                                                component={() => (
                                                    <MySelect
                                                        isDisabled={group.name === 'Default Group'}
                                                        value={admins.find(option => option.id === values.admin)}
                                                        options={admins}
                                                        onChange={option => setFieldValue('admin', option.id)}
                                                        placeholder={'Select administrator'}
                                                        margin={'1.2rem 0 0 0'}
                                                        minHeight={'5.1rem'}
                                                        getOptionLabel={option =>
                                                            `${option.first_name} ${option.last_name}`
                                                        }
                                                        getOptionValue={option => option.id}
                                                    />
                                                )}
                                                name={'admin'}
                                            />
                                            <ErrorText
                                                name={'admin'}
                                                tag={'p'}
                                            />
                                        </label>
                                        <label className={`${styles.label}`}>
                                            Select users
                                            <Field
                                                component={() => (
                                                    <MySelect
                                                        isDisabled={group.name === 'Default Group'}
                                                        closeMenuOnSelect={false}
                                                        value={users.filter(option =>
                                                            values.user_ids.includes(option.id)
                                                        )}
                                                        options={users}
                                                        components={animatedComponents}
                                                        isMulti={true}
                                                        onChange={selectedOptions =>
                                                            setFieldValue(
                                                                'user_ids',
                                                                selectedOptions
                                                                    ? selectedOptions.map(option => option.id)
                                                                    : []
                                                            )
                                                        }
                                                        placeholder={'Select users'}
                                                        margin={'1.2rem 0 0 0'}
                                                        minHeight={'5.1rem'}
                                                        getOptionLabel={user => `${user.first_name} ${user.last_name}`}
                                                        getOptionValue={option => option.id}
                                                    />
                                                )}
                                                name={'user_ids'}
                                            />
                                            <ErrorText
                                                name={'user_ids'}
                                                tag={'p'}
                                            />
                                        </label>
                                    </div>
                                    <div className={styles.box}>
                                        <Button
                                            text={id ? 'Update' : 'Create'}
                                            type={'submit'}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
