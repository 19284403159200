import styles from './TestsListPage.module.scss';
import {Title} from '../../components/Title';
import {Button} from '../../components/Button';
import {useNavigate} from 'react-router-dom';
import {ROUTS, SORT_TYPES} from '../../constants';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {Table} from '../../components/Table';
import {useEffect, useState} from 'react';
import {apiDeleteQuiz, apiListQuiz, apiRefreshUser, apiSortListQuiz} from '../../api';
import {useDispatch} from 'react-redux';
import {TYPE_TABLES} from '../../components/Table/mock';

export const TestsListPage = () => {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const handleClick = () => {
        navigate(ROUTS.testCreate);
    };

    const fetchDeleteAndUpdateList = async testID => {
        await apiDeleteQuiz(testID);

        const data = await apiListQuiz();

        setList(data?.data?.results);
    };

    const fetchData = async (flag, page) => {
        setIsLoading(true);
        try {
            const {data} = await apiSortListQuiz(flag, page);

            setList(data?.results);

            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(sort, page);
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={true} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'List of tests'} />
                        <Button
                            text={'+Add new test'}
                            type={'button'}
                            modifier={'button_addTest'}
                            handleClick={handleClick}
                        />
                        <Table
                            isLoading={isLoading}
                            pageCount={pageCount}
                            list={list}
                            // setList={setList}
                            setSort={setSort}
                            setPage={setPage}
                            typeTables={TYPE_TABLES.listQuiz}
                            fetch={fetchDeleteAndUpdateList}
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
