export const Search = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.8482 15.3649L11.7172 11.2367C12.799 10.0432 13.4637 8.46462 13.4637 6.72761C13.4632 3.01182 10.4494 0 6.73158 0C3.0137 0 0 3.01182 0 6.72761C0 10.4434 3.0137 13.4552 6.73158 13.4552C8.33795 13.4552 9.81129 12.891 10.9686 11.9529L15.1155 16.0973C15.3176 16.2995 15.6457 16.2995 15.8477 16.0973C16.0502 15.8952 16.0502 15.5671 15.8482 15.3649ZM6.73158 12.4201C3.58585 12.4201 1.03575 9.87151 1.03575 6.72761C1.03575 3.58371 3.58585 1.03508 6.73158 1.03508C9.87733 1.03508 12.4274 3.58371 12.4274 6.72761C12.4274 9.87151 9.87733 12.4201 6.73158 12.4201Z"
                fill="black"
            />
        </svg>
    );
};
