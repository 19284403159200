import styles from './Questions.module.scss';
import {SingleQuestion} from '../SingleQuestion';
import {FieldArray} from 'formik';
import {Button} from '../Button';
import {useEffect, useState} from 'react';

export const Questions = ({values}) => {
    const [questions, setQuestions] = useState([]);

    const ADD_QUESTION = '+ Add new question';

    const handleAdd = push => {
        push({
            question_name: '',
            question_link: '',
            question_answer: '',
        });
    };

    useEffect(() => {
        setQuestions(values?.questions);
    }, [values?.questions]);

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Questions</h2>
            <FieldArray name={'questions'}>
                {({remove, push}) => (
                    <>
                        {questions?.length > 0 &&
                            questions.map((item, index) => {
                                const quantity = index + 1;
                                return (
                                    <SingleQuestion
                                        key={index}
                                        quantity={quantity}
                                        indexQuestions={index}
                                        remove={remove}
                                    />
                                );
                            })}
                        <Button
                            handleClick={() => handleAdd(push)}
                            type={'button'}
                            text={ADD_QUESTION}
                            modifier={'button_question'}
                        />
                    </>
                )}
            </FieldArray>
        </div>
    );
};
