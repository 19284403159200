import styles from './CheckBoxSwitch.module.scss';
import {useEffect, useState} from 'react';
import {apiEditQuiz} from '../../api';

export const CheckBoxSwitch = ({checkedStatus, inputId, formProps, editTestId}) => {
    const [defaultStatus, setDefaultStatus] = useState(true);

    const handleChange = event => {
        const isChecked = event.target.checked;

        if (!isChecked) {
            formProps.setFieldValue('test_introductory_video_link', '');
        }

        checkedStatus(isChecked);
        setDefaultStatus(prev => !prev);
    };

    const checkVideoValue = async recordedData => {
        if (recordedData?.test_introductory_video_link?.length === 0) {
            setDefaultStatus(prev => !prev);
        }
    };

    const fetchCurrentTest = async id => {
        const {data} = await apiEditQuiz(id);

        await checkVideoValue(data);
    };

    useEffect(() => {
        if (!editTestId) return;

        fetchCurrentTest(editTestId);
    }, []);

    return (
        <>
            <div className={styles.switch__control}>
                <input
                    className={styles.switch__toggle}
                    type="checkbox"
                    id={inputId}
                    onChange={handleChange}
                    checked={defaultStatus}
                />
                <label
                    className={styles.switch__track}
                    htmlFor={inputId}
                ></label>
                <div className={styles.switch__marker}></div>
                <span className={styles.switch__on}>On</span>
                <span className={styles.switch__off}>Off</span>
            </div>
        </>
    );
};
