export const mock = [
    {
        id: 'title',
        type: 'text',
        name: 'name',
        placeholder: 'Write test name',
        nameClass: 'title',
        text: 'Test name',
        as: 'input',
    },
    {
        id: 'description',
        type: 'text',
        name: 'description',
        placeholder: 'Write description',
        nameClass: 'input_description',
        text: 'Description',
        as: 'textarea',
    },
];
