import styles from './CommentsItem.module.scss';
import {useState} from 'react';
import {CommentView} from '../CommentView';
import {View} from '../../assets/icons';

export const CommentsItem = props => {
    const {item, setCommentsList, fetch} = props;

    return (
        <div className={styles.item}>
            <CommentView
                item={item}
                setCommentsList={setCommentsList}
                fetch={fetch}
            />
        </div>
    );
};
