import {useRef, useState} from 'react';

import {Play, StopVoice} from '../../../../assets/icons';

import styles from './TableMobileDetailsColum.module.scss';

export const TableMobileDetailsColum = props => {
    const {quantity, elem, typeTables, handleOpenVideo, handlePause, handlePlay} = props;

    const [play, setPlay] = useState(true);
    const audio = useRef(null);

    return (
        <>
            <div
                className={styles.questionItem}
                key={quantity}
            >
                <p className={styles.quantity}>№ {quantity}</p>
                {elem?.question_name && <p className={styles.description}>{elem?.question_name}</p>}
                {typeTables?.sizeQuestions?.questions && (
                    <div className={`${styles.video} ${styles.item}`}>
                        <p>Question</p>
                        <button
                            onClick={() => handleOpenVideo(elem?.question_link)}
                            type={'button'}
                        >
                            <Play />
                        </button>
                    </div>
                )}
                {typeTables?.sizeQuestions?.rightAnswer && (
                    <div className={`${styles.video} ${styles.answer} ${styles.item}`}>
                        <p>Right answer</p>
                        <button
                            className={`${styles.button} ${styles.button_rightAnswer}`}
                            onClick={() => handleOpenVideo(elem?.question_answer)}
                            type={'button'}
                        >
                            <Play />
                        </button>
                    </div>
                )}
                {typeTables?.sizeQuestions?.userAnswer && (
                    <div className={`${styles.answer} ${styles.answer_user} ${styles.item}`}>
                        <span>User’s answer</span>
                        <audio
                            className={styles.audio}
                            controls
                            src={elem?.answers?.answer}
                            ref={audio}
                            type="audio/aac"
                        ></audio>
                        {play ? (
                            <button
                                className={`${styles.button} ${styles.button_play}`}
                                type={'button'}
                                onClick={() => handlePlay(audio, setPlay)}
                            >
                                <Play />
                            </button>
                        ) : (
                            <button
                                className={`${styles.button} ${styles.button_pause}`}
                                type={'button'}
                                onClick={() => handlePause(audio, setPlay)}
                            >
                                <StopVoice />
                            </button>
                        )}
                    </div>
                )}
                {typeTables?.sizeQuestions?.score && (
                    <div className={`${styles.answer} ${styles.answer_user} ${styles.item}`}>
                        <span>Score</span>
                        <span className={`${styles.answer} ${styles.answer_score} ${styles.item}`}>
                            {elem?.answers?.score?.value ? `${elem?.answers?.score?.value}/10` : 'not rated'}
                        </span>
                    </div>
                )}
                {typeTables?.users && (
                    <div className={`${styles.answer} ${styles.answer_user} ${styles.item}`}>
                        <span>User name</span>
                        <span className={`${styles.answer} ${styles.answer_score} ${styles.item}`}>
                            {elem?.full_name}
                        </span>
                    </div>
                )}
                {typeTables?.sizeQuestions?.email && (
                    <div className={`${styles.answer} ${styles.answer_user} ${styles.item}`}>
                        <span>E-mail</span>
                        {/*<span className={`${styles.answer} ${styles.answer_score} ${styles.item}`}>*/}
                        {/*   {elem?.email}*/}
                        {/*</span>*/}
                        <span className={`${styles.answer} ${styles.answer_score} ${styles.item}`}>
                            Example_1@gmail.com
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};
