import styles from './TestPage.module.scss';
import {FormCreationTest} from '../../components/FormCreationTest';
import {Title} from '../../components/Title';
import {useParams} from 'react-router-dom';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {useAuth} from '../../hooks';
import React, {useEffect} from 'react';
import {apiRefreshUser} from '../../api';
import {useDispatch} from 'react-redux';
import {Input} from '../../components/Input';
import {Button} from '../../components/Button';

export const TestPage = () => {
    const {id} = useParams();
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        {id ? <Title text={'Edit test'} /> : <Title text={'New test'} />}
                        <FormCreationTest editTestId={id} />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
