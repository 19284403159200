import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {BasicQuizInfo} from '../BasicQuizInfo';
import {SwitchContent} from '../SwitchContent';
import {Input} from '../Input';
import {Button} from '../Button';
import {Questions} from '../Questions';

import {onImageEdit, validationSchema} from '../../utils';
import {apiCreateQuiz, apiEditQuiz, apiUpdateQuiz} from '../../api';
import {ROUTS} from '../../constants';

import styles from './FormCreationTest.module.scss';

export const FormCreationTest = ({editTestId}) => {
    const {organization_id} = useSelector(state => state.user.user);
    const copyUrl = `${window.origin}?test=${editTestId}&organization=${organization_id}`;
    const navigate = useNavigate();

    const [checkedVideo, setCheckedVideo] = useState(true);
    const [copyText, setCopyText] = useState('Copy to clipboard');

    const initialValues = {
        name: 'name test',
        copy: copyUrl,
        description: 'description',
        picture: null,
        test_introductory_video_link: '',
        questions: [
            {
                question_name: 'question name 1',
                question_link: 'https://vimeo.com/636181579',
                question_answer: 'https://vimeo.com/636181579',
            },
        ],
        organization: organization_id,
    };
    const [recordedData, setRecordedData] = useState(initialValues);

    const CREATE_TEST = 'Create test';
    const UPDATE_TEST = 'Update';
    const PHOTO_TITLE = 'Add photo';

    const changeFormValues = async (values, fetch, id) => {
        const formDataValues = new FormData();

        for (const key in values) {
            let value = values[key];

            if (Array.isArray(value)) {
                value = JSON.stringify(value);
            }

            if (value !== null) {
                formDataValues.append(key, value);
            }
        }

        if (id) {
            const data = await fetch(id, formDataValues);

            if (data.status === 200) {
                navigate(ROUTS.testsList);

                toast.success('success');

                return;
            }
        }

        const data = await fetch(formDataValues);

        if (data) {
            navigate(ROUTS.testsList);

            toast.success('success');
        }
    };

    const handleSubmit = async values => {
        if (!editTestId) {
            await changeFormValues(values, apiCreateQuiz);

            return;
        }

        if (editTestId) {
            const urlPicture = values?.picture;

            if (!urlPicture) {
                await changeFormValues(values, apiUpdateQuiz, editTestId);

                return;
            }
            //when we change the picture
            if (urlPicture?.size) {
                values.picture = urlPicture;

                await changeFormValues(values, apiUpdateQuiz, editTestId);

                return;
            }

            if (urlPicture) {
                const file = await onImageEdit(urlPicture);

                //when we do not change the picture
                if (values?.picture) {
                    values.picture = file;

                    await changeFormValues(values, apiUpdateQuiz, editTestId);
                }
            }
        }
    };

    const fetchCurrentTest = async id => {
        const {data} = await apiEditQuiz(id);

        data.copy = copyUrl;

        await setRecordedData(data);
        await checkVideoValue();

        if (!data) {
            navigate(ROUTS.testsList);
        }
    };

    const checkVideoValue = () => {
        if (recordedData?.test_introductory_video_link?.length === 0) {
            setCheckedVideo(prev => !prev);
        }
    };

    useEffect(() => {
        if (!editTestId) return;

        fetchCurrentTest(editTestId);
    }, []);

    useEffect(() => {
        if (editTestId) {
            checkVideoValue();
        }
    }, [recordedData]);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(`${window.origin}?test=${editTestId}&organization=${organization_id}`);

            setCopyText('Text copied!');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Formik
                initialValues={editTestId ? recordedData : initialValues}
                enableReinitialize
                validationSchema={Yup.object({
                    copy: validationSchema.copy,
                    name: validationSchema.name,
                    description: validationSchema.description,
                    questions: validationSchema.questions,
                    organization: validationSchema.organization,
                })}
                onSubmit={handleSubmit}
            >
                {formProps => (
                    <Form>
                        {editTestId && (
                            <div className={styles.copy}>
                                <div className={styles.input}>
                                    <label className={`${styles.label}`}>
                                        <Input
                                            type={'text'}
                                            placeholder={'Write group name'}
                                            disabled={true}
                                            name={'copy'}
                                        />
                                    </label>
                                </div>
                                <div className={styles.button}>
                                    <Button
                                        handleClick={handleCopy}
                                        type={'button'}
                                        text={copyText}
                                    />
                                </div>
                            </div>
                        )}
                        <BasicQuizInfo formProps={formProps} />
                        <SwitchContent
                            photo={true}
                            title={PHOTO_TITLE}
                            formProps={formProps}
                            recordedData={recordedData}
                            editTestId={editTestId}
                        />
                        <SwitchContent
                            videoLink={true}
                            formProps={formProps}
                            checkedVideo={checkedVideo}
                            setCheckedVideo={setCheckedVideo}
                            editTestId={editTestId}
                        />
                        <Questions values={formProps?.values} />
                        <Button
                            type={'submit'}
                            text={editTestId ? UPDATE_TEST : CREATE_TEST}
                            modifier={'button_accent'}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};
