import React from 'react';
import {useNavigate} from 'react-router-dom';
import {BrowserView, MobileView, browserName, osName} from 'react-device-detect';

import {AUDIO_PERMISSION_MSG, ROUTS} from '../../constants';

import {ArrowLeft} from '../../assets/icons/ArrowLeft';
import {Button} from '../Button';

import styles from './ErrorRecording.module.scss';

export const ErrorRecording = props => {
    const {error} = props;

    const navigate = useNavigate();
    const backButton = () => {
        navigate(ROUTS.user);
    };

    const errorMessage = () => {
        if (
            error === AUDIO_PERMISSION_MSG.safari_refuse_microphone ||
            error === AUDIO_PERMISSION_MSG.chrome_refuse_microphone ||
            error === AUDIO_PERMISSION_MSG.firefox_refuse_microphone
        ) {
            return AUDIO_PERMISSION_MSG.allow_microphone;
        } else if (
            error === AUDIO_PERMISSION_MSG.chrome_system_microphone ||
            error === AUDIO_PERMISSION_MSG.firefox_system_microphone
        ) {
            return AUDIO_PERMISSION_MSG.allow_system_microphone;
        } else if (
            error === AUDIO_PERMISSION_MSG.chrome_headphones_allow ||
            error === AUDIO_PERMISSION_MSG.firefox_headphones_allow ||
            error === AUDIO_PERMISSION_MSG.safari_headphones_allow
        ) {
            return AUDIO_PERMISSION_MSG.allow_headphones;
        } else {
            return error;
        }
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.errorText}>{errorMessage()}</div>
            <BrowserView>
                <div className={styles.errorText}>
                    {browserName === 'Safari' &&
                        'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Parameters -> Websites -> Microphone, select Sale-Simulator, and choose Allow'}
                </div>
                <div className={styles.errorText}>
                    {browserName === 'Chrome' &&
                        'If you block the microphone you won’t be able to take the quiz.  To enable the microphone go to settings in menu browser -> Privacy and Security -> Site settings -> Select Sale-simulator -> Microphone -> Allow'}
                </div>
                <div className={styles.errorText}>
                    {browserName === 'Firefox' &&
                        'If you block the microphone you won’t be able to take the quiz.  To enable the microphone go to settings in menu browser -> Privacy and Security -> Permissions -> Microphone -> Microphone -> Select Sale-simulator -> Allow'}
                </div>
            </BrowserView>
            <MobileView>
                <div className={styles.errorText}>
                    {browserName === 'Mobile Safari' &&
                        'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Settings -> Safari -> Microphone and choose Allow'}
                </div>
                <div className={styles.errorText}>
                    {browserName === 'Chrome'
                        ? osName === 'iOS'
                            ? 'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Settings -> Chrome -> Microphone and choose Allow'
                            : 'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Settings -> Apps -> Chrome -> Permissions -> Microphone and choose Allow'
                        : null}
                </div>
                <div className={styles.errorText}>
                    {browserName === 'Firefox'
                        ? osName === 'iOS'
                            ? 'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Settings -> Firefox -> Microphone and choose Allow'
                            : 'If you block the microphone forever you won’t be able to take the quiz. To enable the microphone you need to go to the Settings -> Apps -> Firefox -> Permissions -> Microphone and choose Allow'
                        : null}
                </div>
            </MobileView>
            <div className={styles.buttonWrapper}>
                <Button
                    text={'Back'}
                    handleClick={backButton}
                    type={'button'}
                    nameClass={'button__backTests'}
                    image={<ArrowLeft />}
                />
            </div>
        </div>
    );
};
