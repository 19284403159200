import styles from './ModalConfirm.module.scss';
import {CrossSvg} from '../../../assets/icons';
import {Button} from '../../Button';

export const ModalConfirm = props => {
    const {onClose, updateFetch} = props;

    const handleConfirm = async () => {
        await updateFetch();

        onClose(prev => !prev);
    };

    return (
        <div className={styles.wrapper}>
            <button
                className={styles.deleteBtn}
                type={'button'}
                onClick={() => onClose(prev => !prev)}
            >
                <CrossSvg />
            </button>
            <h1 className={styles.title}>Confirmation</h1>
            <p className={styles.text}>Are you sure you want to delete this?</p>
            <div className={styles.box}>
                <Button
                    type={'button'}
                    text={'Cancel'}
                    handleClick={() => onClose(prev => !prev)}
                />
                <Button
                    type={'button'}
                    text={'Confirm'}
                    modifier={'button_accent'}
                    handleClick={handleConfirm}
                />
            </div>
        </div>
    );
};
