import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {FieldArray, Form, Formik} from 'formik';

import {Header} from '../../components/Header';
import {Title} from '../../components/Title';
import {Footer} from '../../components/Footer';
import {UserHeader} from '../../components/UserHeader';
import {SwitchContent} from '../../components/SwitchContent';
import {Input} from '../../components/Input';
import {ButtonSwitch} from './ButtonSwitch';
import {Button} from '../../components/Button';

import {onImageEdit, validationSchema} from '../../utils';
import {apiResetPassword, apiUpdateUser, apiSaveCompany} from '../../api';
import {useAuth} from '../../hooks';
import {mock} from './mock';
import defaultImage from '../../assets/img/default.jpg';

import styles from './AccountPage.module.scss';

const initialValuesPassword = {
    old_password: '',
    new_password: '',
    confirm_password: '',
};

let initialCompanyValues = {
    name: '',
    address: '',
    phone: '',
    logo: null,
    email: '',
    copyright: '',
    social_media: [{title: '', link: ''}],
};

const validationCompanySchema = Yup.object({
    name: validationSchema.companyName,
    address: validationSchema.address,
    phone: validationSchema.phone,
    logo: validationSchema.logo,
    email: validationSchema.company_email,
    copyright: validationSchema.copyright,
    social_media: validationSchema.social_media,
});

export const AccountPage = () => {
    const [isEdit, setIsEdit] = useState(true);
    const [isChangePassword, setIsChangePassword] = useState(true);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const {user, isLoggedIn} = useAuth();
    const fileInputRef = useRef(null);
    const formikRef = useRef(null);
    const [fileDataURL, setFileDataURL] = useState(defaultImage);
    const company = useSelector(state => state.company.company);
    initialCompanyValues = company;
    const EDIT = 'Edit';
    const CHANGE_PASSWORD = 'Change password';
    const AVATAR_TITLE = 'Your avatar';
    const SUB_TITLE = 'You can change your current photo by clicking on the button';
    const shouldInitial = Boolean(user?.first_name) && Boolean(user?.last_name);
    let initialValues;

    const setPicture = file => {
        let fileReader = false,
            isCancel = false;

        if (file) {
            fileReader = new FileReader();
            fileReader.onload = event => {
                const {result} = event.target;

                if (result && !isCancel) {
                    setFileDataURL(result);
                }
            };
            fileReader.readAsDataURL(file);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    };

    const handleChange = () => {
        setIsEdit(prev => !prev);
    };

    const handleChangePassword = () => {
        setIsChangePassword(prev => !prev);
    };

    const handleConfirmSubmit = async (values, action) => {
        const formDataValues = new FormData();

        const avatar = values?.avatar;

        if (!avatar) {
            for (const key in values) {
                let value = values[key];

                if (value !== null) {
                    formDataValues.append(key, value);
                }
            }

            dispatch(apiUpdateUser(formDataValues));

            setIsEdit(prev => !prev);
            return;
        }

        if (!avatar?.size && avatar) {
            const file = await onImageEdit(avatar);

            values.avatar = file;
        }

        for (const key in values) {
            let value = values[key];

            if (value !== null) {
                formDataValues.append(key, value);
            }
        }
        dispatch(apiUpdateUser(formDataValues));
    };

    const handlePasswordSubmit = async (values, action) => {
        const updateValues = {
            ...values,
        };

        delete updateValues.confirm_password;

        const isData = await apiResetPassword(updateValues);

        if (isData) {
            setIsChangePassword(prev => !prev);
        }
    };

    const handleDeleteLogo = setFieldValue => {
        setFieldValue('logo', null);
        setFileDataURL(defaultImage);
    };

    const handleLogoClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event, setFieldValue) => {
        const file = event.currentTarget.files[0];

        if (file && file.type.startsWith('image/')) {
            setFieldValue('logo', file);
            setPicture(file);
        } else {
            alert('Please select an image file');
            event.target.value = null;
        }
    };

    if (!shouldInitial) {
        initialValues = {
            first_name: user?.first_name,
            email: user?.email,
            last_name: user?.last_name,
            avatar: user?.avatar,
        };
    } else {
        initialValues = user;
    }

    const handleSubmit = async (values, actions) => {
        const formData = new FormData();

        formData.append('name', values.name);
        formData.append('address', values.address);
        formData.append('phone', values.phone);
        formData.append('social_media', JSON.stringify(values.social_media));
        formData.append('email', values.email);
        formData.append('copyright', values.copyright);

        if (typeof values.logo !== 'string') {
            formData.append('logo', values.logo);
        }

        dispatch(apiSaveCompany(formData));
        actions.setSubmitting(false);
    };

    useEffect(() => {
        if (isEdit) return;

        setIsEdit(prev => !prev);
    }, [user]);

    useEffect(() => {
        if (company?.logo !== null) {
            setFileDataURL(company?.logo);
        }

        if (company) {
            Object.entries(company).forEach(([key, value]) => {
                formikRef.current?.setFieldValue(key, value);
            });
        }
    }, [company]);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <div className={styles.form__wrapper}>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={Yup.object({
                                    first_name: validationSchema.first_name,
                                    email: validationSchema.email,
                                    last_name: validationSchema.last_name,
                                })}
                                onSubmit={handleConfirmSubmit}
                            >
                                {formProps => (
                                    <Form>
                                        <div className={styles.editWrapper}>
                                            <Title text={'Personal information'} />
                                            <ButtonSwitch
                                                isChange={isEdit}
                                                setIsEdit={setIsEdit}
                                                handleChange={handleChange}
                                                textChangeButton={EDIT}
                                                formProps={formProps}
                                                setUpdate={setUpdate}
                                            />
                                        </div>
                                        <SwitchContent
                                            personalInformation={true}
                                            photo={true}
                                            title={AVATAR_TITLE}
                                            formProps={formProps}
                                            subTitle={SUB_TITLE}
                                            isEdit={isEdit}
                                            update={update}
                                        />
                                        <div className={`${styles.title__wrapper}`}>
                                            <div className={styles.title__box}>
                                                <h2 className={styles.title__title}>Personal information</h2>
                                                <span className={styles.title__text}>
                                                    You can change your current personal information by clicking on the
                                                    button
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styles.form}>
                                            {mock.map((item, index) => (
                                                <label
                                                    key={index}
                                                    className={styles.label}
                                                >
                                                    {item?.label}
                                                    <Input
                                                        type={item?.type}
                                                        name={item?.name}
                                                        placeholder={item?.placeholder}
                                                        disabled={isEdit}
                                                    />
                                                </label>
                                            ))}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <Formik
                            initialValues={initialValuesPassword}
                            validationSchema={Yup.object({
                                old_password: validationSchema.old_password,
                                new_password: validationSchema.new_password,
                                confirm_password: validationSchema.confirm_password,
                            })}
                            onSubmit={handlePasswordSubmit}
                        >
                            <div className={styles.form__wrapper}>
                                <Form>
                                    <div className={`${styles.title__wrapper} ${styles.title__wrapper_password}`}>
                                        <div className={styles.title__box}>
                                            <h2 className={styles.title__title}>Change password</h2>
                                            <span className={styles.title__text}>
                                                You can change your current password by clicking on the button
                                            </span>
                                        </div>
                                        <ButtonSwitch
                                            isChange={isChangePassword}
                                            setIsEdit={setIsChangePassword}
                                            handleChange={handleChangePassword}
                                            textChangeButton={CHANGE_PASSWORD}
                                        />
                                    </div>
                                    {!isChangePassword && (
                                        <div className={`${styles.form} ${styles.form_password}`}>
                                            <label className={`${styles.label} ${styles.label_password}`}>
                                                Old password
                                                <Input
                                                    type={'password'}
                                                    name={'old_password'}
                                                    placeholder={'***********'}
                                                />
                                            </label>
                                            <label className={`${styles.label} ${styles.label_password}`}>
                                                New password
                                                <Input
                                                    type={'password'}
                                                    name={'new_password'}
                                                    placeholder={'***********'}
                                                />
                                            </label>
                                            <label className={`${styles.label} ${styles.label_password}`}>
                                                Repeat the new password
                                                <Input
                                                    type={'password'}
                                                    name={'confirm_password'}
                                                    placeholder={'***********'}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </Formik>
                        {user.type === 'Senior Admin' && (
                            <Formik
                                initialValues={initialCompanyValues}
                                validationSchema={validationCompanySchema}
                                onSubmit={handleSubmit}
                                innerRef={formikRef}
                            >
                                {({values, setFieldValue}) => {
                                    return (
                                        <Form>
                                            <div className={`${styles.title__wrapper}`}>
                                                <div className={styles.title__box}>
                                                    <h2 className={styles.title__title}>Company Information</h2>
                                                    <span className={styles.title__text}>
                                                        Add your company details and social media links
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className={`${styles.form} ${styles.form_company} ${styles.form__wrapper}`}
                                            >
                                                <label className={styles.label}>
                                                    Company Name
                                                    <Input
                                                        name={'name'}
                                                        placeholder={'Company Name'}
                                                    />
                                                </label>
                                                <label className={styles.label}>
                                                    Company Address
                                                    <Input
                                                        name={'address'}
                                                        placeholder={'Company Address'}
                                                    />
                                                </label>
                                                <label className={styles.label}>
                                                    Phone Number
                                                    <Input
                                                        name={'phone'}
                                                        placeholder={'Phone Number'}
                                                    />
                                                </label>
                                                <label className={styles.label}>
                                                    Company Email
                                                    <Input
                                                        name={'email'}
                                                        placeholder={'Company Email'}
                                                    />
                                                </label>
                                                <label className={styles.label}>
                                                    Copyright text
                                                    <Input
                                                        name={'copyright'}
                                                        placeholder={'Copyright text'}
                                                    />
                                                </label>
                                                <input
                                                    type={'file'}
                                                    ref={fileInputRef}
                                                    style={{display: 'none'}}
                                                    onChange={e => {
                                                        handleFileChange(e, setFieldValue);
                                                    }}
                                                    accept={'image/*'}
                                                />
                                            </div>
                                            <div className={`${styles.title__wrapper}`}>
                                                <div className={styles.title__box}>
                                                    <h2 className={styles.title__title}>Company Logo</h2>
                                                    <span className={styles.title__text}>Add your company logo</span>
                                                </div>
                                            </div>
                                            <div className={styles.logo_wrapper}>
                                                {fileDataURL && (
                                                    <img
                                                        className={styles.logo_image_wrapper}
                                                        src={fileDataURL}
                                                    />
                                                )}
                                                <Button
                                                    type={'button'}
                                                    text={values.logo ? 'Delete Logo' : 'Upload Logo'}
                                                    modifier={'button_accent'}
                                                    nameClass="button__saveLogo"
                                                    handleClick={() => {
                                                        values?.logo
                                                            ? handleDeleteLogo(setFieldValue)
                                                            : handleLogoClick();
                                                    }}
                                                />
                                            </div>
                                            <div className={`${styles.title__wrapper} ${styles.title__wrapper_social}`}>
                                                <div className={styles.title__box}>
                                                    <h2 className={styles.title__title}>Social Media Links</h2>
                                                    <span className={styles.title__text}>
                                                        Add your social media links
                                                    </span>
                                                </div>
                                            </div>
                                            <FieldArray name="social_media">
                                                {({push, remove}) => (
                                                    <div className={`${styles.form_social} ${styles.form__wrapper}`}>
                                                        {values.social_media.map((_, index) => (
                                                            <div
                                                                key={index}
                                                                className={styles.socialMediaEntry}
                                                            >
                                                                <label className={styles.label}>
                                                                    Title
                                                                    <Input
                                                                        name={`social_media.${index}.title`}
                                                                        placeholder="Platform (e.g., Twitter, LinkedIn)"
                                                                    />
                                                                </label>
                                                                <label className={styles.label}>
                                                                    Link
                                                                    <Input
                                                                        name={`social_media.${index}.link`}
                                                                        placeholder="https://..."
                                                                    />
                                                                </label>

                                                                <Button
                                                                    type={'button'}
                                                                    text={'Remove'}
                                                                    modifier={'button_accent'}
                                                                    handleClick={() => remove(index)}
                                                                />
                                                            </div>
                                                        ))}
                                                        <Button
                                                            type={'button'}
                                                            text={'Add Socials'}
                                                            modifier={'button_accent'}
                                                            handleClick={() => push({title: '', link: ''})}
                                                        />
                                                    </div>
                                                )}
                                            </FieldArray>

                                            <Button
                                                type={'submit'}
                                                text={'Save Company Information'}
                                                nameClass={'button__saveCompanyInfo'}
                                                modifier={'button_accent'}
                                            />
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
