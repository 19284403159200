export const VoiceDesktop = () => {
    return (
        <svg
            width="640"
            height="64"
            viewBox="0 0 640 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="9.53613"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="28.6138"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="32.4277"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="36.2471"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="30.5225"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="34.3335"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="11.4419"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="7.63037"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="26.7056"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="38.1528"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="40.061"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="13.3555"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="41.9668"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="19.0752"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="47.6919"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="3.81396"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="15.2612"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="43.8721"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="22.8916"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="51.5029"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="17.1694"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="45.7832"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="1.9082"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="5.72217"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="24.7998"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="20.9834"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="49.5972"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="116.364"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="135.441"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="139.255"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="143.075"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="137.35"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="141.161"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="118.27"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="114.458"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="133.533"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="144.98"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="146.889"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="120.183"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="148.794"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="125.903"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="154.517"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="110.642"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="122.089"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="150.7"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="106.828"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="129.719"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="158.331"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="123.997"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="152.611"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="108.736"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="112.55"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="131.627"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="127.811"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="156.425"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="62.9482"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="82.0259"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="85.8398"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="89.6592"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="83.9346"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="87.7456"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="64.854"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="61.0425"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="80.1177"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="91.5649"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="93.4731"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="66.7676"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="95.3789"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="72.4873"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="101.104"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="57.2261"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="68.6733"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="97.2842"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="53.4121"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="76.3037"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="104.915"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="70.5815"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="99.1953"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="55.3203"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="59.1343"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="78.2119"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="74.3955"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="103.009"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="169.777"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="188.855"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="192.669"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="196.488"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="190.764"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="194.575"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="171.683"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="167.872"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="186.947"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="198.394"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="200.302"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="173.597"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="202.208"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="179.316"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="207.933"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="164.055"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="175.502"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="204.113"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="160.241"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="183.133"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="211.744"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="177.411"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="206.024"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="162.149"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="165.963"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="185.041"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="181.225"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="209.838"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="223.19"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="242.268"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="246.082"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="249.901"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="244.176"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="247.987"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="225.096"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="221.284"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="240.359"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="251.807"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="253.715"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="227.009"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="255.621"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="232.729"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="261.343"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="217.468"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="228.915"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="257.526"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="213.654"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="236.545"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="265.157"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="230.823"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="259.437"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="215.562"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="219.376"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="238.454"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="234.637"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="263.251"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="330.018"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="349.095"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="352.909"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="356.729"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="351.001"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="354.815"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="331.923"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="328.112"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="347.187"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="358.634"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="360.542"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="333.837"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="362.448"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="339.557"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="368.17"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="324.295"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="335.743"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="364.354"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="320.481"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="343.373"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="371.984"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="337.651"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="366.265"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="322.39"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="326.204"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="345.279"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="341.465"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="370.079"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="276.605"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="295.683"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="299.497"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="303.316"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="297.592"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="301.408"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="278.517"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="274.7"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="293.775"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="305.222"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="307.13"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="280.425"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="309.036"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="286.145"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="314.761"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="270.883"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="282.331"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="310.941"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="267.069"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="289.961"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="318.572"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="284.239"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="312.853"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="268.978"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="272.792"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="291.869"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="288.053"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="316.667"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="383.433"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="402.511"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="406.325"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="410.144"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="404.419"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="408.23"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="385.339"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="381.527"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="400.603"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="412.05"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="413.958"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="387.252"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="415.864"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="392.972"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="421.589"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="377.711"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="389.158"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="417.769"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="373.897"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="396.789"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="425.4"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="391.066"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="419.68"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="375.805"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="379.619"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="398.697"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="394.88"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="423.494"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="436.836"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="455.914"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="459.728"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="463.547"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="457.82"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="461.634"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="438.742"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="434.931"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="454.006"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="465.453"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="467.361"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="440.656"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="469.267"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="446.375"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="474.989"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="431.114"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="442.562"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="471.172"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="427.3"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="450.192"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="478.803"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="444.47"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="473.083"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="429.208"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="433.022"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="452.098"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="448.284"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="476.897"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="543.664"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="562.742"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="566.556"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="570.375"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="564.65"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="568.461"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="545.57"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="541.758"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="560.833"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="572.281"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="574.189"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="547.483"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="576.095"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="553.203"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="581.82"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="537.942"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="549.389"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="578"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="534.128"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="557.02"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="585.631"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="551.297"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="579.911"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="536.036"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="539.85"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="558.928"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="555.111"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="583.725"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="490.249"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="509.326"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="513.14"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="516.959"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="511.235"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="515.046"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="492.154"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="488.343"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="507.418"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="518.865"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="520.773"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="494.068"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="522.679"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="499.788"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="528.404"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="484.526"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="495.974"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="524.584"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="480.712"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="503.604"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="532.215"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="497.882"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="526.496"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="482.621"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="486.435"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="505.512"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="501.696"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="530.31"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="597.076"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="616.154"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="619.968"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="623.785"
                y="3.76562"
                width="0.9538"
                height="56.4706"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="618.062"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="621.874"
                width="0.9538"
                height="64"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="598.982"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="595.17"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="614.246"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="625.693"
                y="11.2969"
                width="0.9538"
                height="41.4118"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="627.601"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="600.896"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="629.507"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="606.615"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="635.232"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="591.354"
                y="18.8252"
                width="0.9538"
                height="26.3529"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="602.801"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="631.412"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="587.54"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="610.432"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="639.043"
                y="26.3535"
                width="0.9538"
                height="11.2941"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="604.709"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="633.323"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="589.448"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="593.262"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="612.34"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="608.523"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
            <rect
                x="637.137"
                y="22.5908"
                width="0.9538"
                height="18.8235"
                rx="0.4769"
                fill="#2CA6E7"
            />
        </svg>
    );
};
