import styles from './UsersPage.module.scss';
import {Title} from '../../components/Title';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {useAuth} from '../../hooks';
import {useEffect, useState} from 'react';
import {apiCompanies, apiDeleteUser, apiEditCompany, apiListUsers, apiRefreshUser} from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import {TYPE_TABLES} from '../../components/Table/mock';
import {Table} from '../../components/Table';
import {SORT_TYPES} from '../../constants';

export const UsersPage = () => {
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.nameAscending);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(state => state.user.user.organization_slug);

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const {data} = await apiListUsers(sort, '', user, page);

            setList(data.results);
            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDeleteAndUpdateList = async ID => {
        await apiDeleteUser(ID);

        const {data} = await apiListUsers('', user);

        setList(data.results);
    };

    useEffect(() => {
        fetchData(sort, page);
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'Users page'} />
                        <Table
                            isLoading={isLoading}
                            pageCount={pageCount}
                            list={list}
                            setSort={setSort}
                            setPage={setPage}
                            typeTables={TYPE_TABLES.users}
                            fetch={fetchDeleteAndUpdateList}
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
