export * from './links';
export * from './routes';
export * from './recorder';

export const SORT_TYPES = {
    nameAscending: 'first_name',
    nameDescending: '-first_name',
    dateAscending: '_updated_at',
    dateDescending: '-_updated_at',
};

export const DEVICE = {
    mobile: 666,
    desktop: 667,
};

export const TYPE_USERS = {
    user: 'USER',
    admin: 'Admin',
    superAdmin: 'Super Admin',
    seniorAdmin: 'Senior Admin',
};

export const TYPE_COMMENT = {
    text: 'text',
    video: 'video',
    audio: 'audio',
};

export const REGISTER_REQUEST_ACTION = {
    acceptAdmin: 'ACCEPT_ADMIN',
    acceptUser: 'ACCEPT_USER',
    refuse: 'REFUSE',
};

export const loaderStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
};
