import {persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import {configureStore} from '@reduxjs/toolkit';
import {userReducer} from './user';
import {companyReducer} from './company';

export const store = configureStore({
    reducer: {
        user: userReducer,
        company: companyReducer,
    },
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        });
    },
});

export const persistor = persistStore(store);
