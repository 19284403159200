import styles from './AddQuizPhoto.module.scss';
import defaultImage from '../../assets/img/default.jpg';
import {Button} from '../Button';
import {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../hooks';

export const AddQuizPhoto = props => {
    const {formProps, recordedData, editTestId, isEdit, personalInformation, update} = props;

    const ADD_PHOTO = 'Add photo';
    const CHANGE_PHOTO = 'Change photo';

    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(defaultImage);
    const [changeText, setChangeText] = useState(ADD_PHOTO);
    const fileInput = useRef(null);
    const {user} = useAuth();

    const handleInputChange = event => {
        const file = event.target.files[0];

        setFile(file);

        if (personalInformation) {
            formProps.setFieldValue('avatar', file);
            return;
        }

        formProps.setFieldValue('picture', file);
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleClickRemove = () => {
        setFileDataURL(defaultImage);

        setChangeText(ADD_PHOTO);

        if (personalInformation) {
            formProps.setFieldValue('avatar', null);
            return;
        }

        formProps.setFieldValue('picture', null);
    };

    const setPicture = () => {
        let fileReader = false,
            isCancel = false;

        if (file) {
            fileReader = new FileReader();
            fileReader.onload = event => {
                const {result} = event.target;

                if (result && !isCancel) {
                    setFileDataURL(result);
                    setChangeText(CHANGE_PHOTO);
                }
            };
            fileReader.readAsDataURL(file);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    };

    const initialPictureEditTest = () => {
        const picture = recordedData?.picture ? recordedData?.picture : defaultImage;

        setFileDataURL(picture);

        setChangeText(CHANGE_PHOTO);
    };

    const initialPicturePersonalInformation = () => {
        formProps.setFieldValue('avatar', user?.avatar);

        user?.avatar ? setChangeText(CHANGE_PHOTO) : setChangeText(ADD_PHOTO);

        const picture = user?.avatar ? user?.avatar : defaultImage;

        setFileDataURL(picture);
    };

    useEffect(() => {
        if (editTestId) {
            initialPictureEditTest();
        }
    }, [recordedData, update]);

    useEffect(() => {
        setPicture();
    }, [file]);

    useEffect(() => {
        if (personalInformation) {
            initialPicturePersonalInformation();
        }
    }, [user?.avatar]);

    useEffect(() => {
        if (personalInformation) {
            initialPicturePersonalInformation();
        }
    }, [isEdit]);

    return (
        <div className={styles.inner}>
            <input
                type={'file'}
                accept={'image/*'}
                hidden={true}
                ref={fileInput}
                onChange={handleInputChange}
            />
            <img
                className={styles.inner__img}
                src={fileDataURL}
                alt="avatar"
            />
            {!isEdit && (
                <div className={styles.inner__package}>
                    <Button
                        handleClick={handleClick}
                        type={'button'}
                        text={changeText}
                        modifier={'button_accent'}
                    />
                    <Button
                        text={'Remove photo'}
                        type={'button'}
                        handleClick={handleClickRemove}
                    />
                </div>
            )}
        </div>
    );
};
