import styles from './CompanyPage.module.scss';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Title} from '../../components/Title';
import {Footer} from '../../components/Footer';
import {useAuth} from '../../hooks';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {validationSchema} from '../../utils';
import {Input} from '../../components/Input';
import {mock} from './mock';
import {Button} from '../../components/Button';
import {apiCreateCompany, apiEditCompany, apiRefreshUser} from '../../api';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ROUTS} from '../../constants';
import {useNavigate, useParams} from 'react-router-dom';
import toast from 'react-hot-toast';

const initialValues = {
    name: '',
    senior_admin: {
        email: '',
        first_name: '',
        password: '',
        confirm_password: '',
    },
};

export const CompanyPage = () => {
    const {id} = useParams();
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState(initialValues);
    const handleSubmit = async (values, {setSubmitting, setErrors, setFieldValue}) => {
        if (!id) {
            try {
                delete values.senior_admin.confirm_password;

                const data = await apiCreateCompany(values);

                if (data) {
                    navigate(ROUTS.companiesList);
                }
            } catch (error) {
                setErrors(error.errors);
            }

            setSubmitting(false);
            setFieldValue('senior_admin.confirm_password', '');
            setFieldValue('senior_admin.password', '');
        }

        if (id) {
            const data = await apiEditCompany(id, 'PUT', values);

            if (data?.status === 200) {
                toast.success('success');
                navigate(ROUTS.companiesList);
            }
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const fetchCurrentCompany = async id => {
        const {data} = await apiEditCompany(id, 'GET');

        const initialSchema = {
            name: '',
            senior_admin: {
                id: null,
                email: '',
                first_name: '',
                last_name: '',
            },
        };

        initialSchema.name = data?.name;
        initialSchema.senior_admin.id = data?.senior_admin?.id;
        initialSchema.senior_admin.email = data?.senior_admin?.email;
        initialSchema.senior_admin.first_name = data?.senior_admin?.first_name;
        initialSchema.senior_admin.last_name = data?.senior_admin?.last_name;

        setCompanyData(initialSchema);
    };

    useEffect(() => {
        if (id) {
            fetchCurrentCompany(id);
        }
    }, [id]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, [dispatch]);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={id ? 'Edit company' : 'Create new company'} />
                        <Formik
                            enableReinitialize
                            initialValues={id ? companyData : initialValues}
                            validationSchema={
                                id
                                    ? Yup.object({
                                          name: validationSchema.name,
                                          senior_admin: validationSchema.senior_admin_edit,
                                      })
                                    : Yup.object({
                                          name: validationSchema.name,
                                          senior_admin: validationSchema.senior_admin,
                                      })
                            }
                            onSubmit={handleSubmit}
                        >
                            <Form className={styles.form}>
                                <label className={`${styles.label}`}>
                                    Company name*
                                    <Input
                                        type={'text'}
                                        name={'name'}
                                        placeholder={'Write company name'}
                                    />
                                </label>
                                <h2 className={styles.subTitle}>Senior Admin information</h2>
                                <div className={styles.wrapper}>
                                    {mock.map((item, index) => (
                                        <label
                                            key={index}
                                            className={styles.label}
                                        >
                                            {item?.label}
                                            <Input
                                                type={item?.type}
                                                name={item?.name}
                                                placeholder={item?.placeholder}
                                            />
                                        </label>
                                    ))}
                                </div>
                                {!id && (
                                    <>
                                        <h2 className={styles.subTitle}>Set password</h2>
                                        <div className={styles.wrapper}>
                                            <label className={`${styles.label}`}>
                                                Password*
                                                <Input
                                                    type={'password'}
                                                    name={'senior_admin.password'}
                                                    placeholder={'***********'}
                                                />
                                            </label>
                                            <label className={`${styles.label}`}>
                                                Repeat password*
                                                <Input
                                                    type={'password'}
                                                    name={'senior_admin.confirm_password'}
                                                    placeholder={'***********'}
                                                />
                                            </label>
                                        </div>
                                    </>
                                )}
                                <div className={styles.box}>
                                    <Button
                                        text={'Cancel'}
                                        type={'button'}
                                        handleClick={handleCancel}
                                    />
                                    <Button
                                        text={id ? 'Update' : 'Save'}
                                        type={'submit'}
                                    />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
