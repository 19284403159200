export const StopVoiceTest = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill="#ff3333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2198 14.22H32.7799C33.3307 14.22 33.7799 14.6692 33.7799 15.22V32.7801C33.7799 33.3309 33.3307 33.7801 32.7799 33.7801H15.2198C14.669 33.7801 14.2197 33.3309 14.2197 32.7801V15.22C14.2198 14.6692 14.669 14.22 15.2198 14.22Z"
                fill="white"
            />
        </svg>
    );
};
