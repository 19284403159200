export const EyeHideIcon = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#949494"
        >
            <path d="M16.875 17.5016C16.793 17.5017 16.7117 17.4856 16.6359 17.4541C16.5601 17.4227 16.4912 17.3765 16.4334 17.3183L2.68337 3.56829C2.57297 3.44981 2.51287 3.2931 2.51572 3.13119C2.51858 2.96927 2.58417 2.81478 2.69868 2.70027C2.81319 2.58576 2.96768 2.52017 3.1296 2.51731C3.29152 2.51445 3.44822 2.57456 3.5667 2.68496L17.3167 16.435C17.404 16.5224 17.4634 16.6337 17.4875 16.7548C17.5116 16.876 17.4992 17.0016 17.4519 17.1157C17.4047 17.2299 17.3247 17.3274 17.222 17.3961C17.1193 17.4648 16.9986 17.5015 16.875 17.5016Z" />
            <path d="M9.68692 12.3384L7.66442 10.3167C7.65572 10.3081 7.64514 10.3017 7.63353 10.298C7.62191 10.2942 7.60958 10.2932 7.59752 10.2951C7.58546 10.297 7.574 10.3016 7.56406 10.3087C7.55412 10.3158 7.54596 10.3251 7.54025 10.3359C7.53272 10.3502 7.52981 10.3665 7.53192 10.3825C7.61373 10.9072 7.86019 11.3924 8.23571 11.7679C8.61122 12.1434 9.09637 12.3899 9.62108 12.4717C9.63723 12.4741 9.65374 12.4714 9.66826 12.464C9.68277 12.4565 9.69455 12.4446 9.70192 12.43C9.70933 12.4152 9.71186 12.3985 9.70917 12.3822C9.70648 12.3659 9.69869 12.3508 9.68692 12.3392V12.3384Z" />
            <path d="M10.3117 7.65915L12.3375 9.68415C12.3491 9.69586 12.364 9.70364 12.3803 9.70637C12.3965 9.7091 12.4132 9.70665 12.4279 9.69936C12.4427 9.69207 12.4548 9.68032 12.4625 9.66577C12.4702 9.65122 12.4731 9.63462 12.4708 9.61831C12.3894 9.09279 12.1428 8.60682 11.7667 8.23078C11.3907 7.85473 10.9047 7.60812 10.3792 7.52665C10.3628 7.52416 10.3461 7.52692 10.3314 7.53454C10.3167 7.54216 10.3047 7.55424 10.2973 7.56906C10.2899 7.58387 10.2874 7.60065 10.2901 7.61699C10.2929 7.63333 10.3007 7.64838 10.3125 7.65998L10.3117 7.65915Z" />
            <path d="M19.1791 10.6783C19.3084 10.4752 19.3768 10.2393 19.376 9.99856C19.3753 9.75779 19.3055 9.52229 19.1749 9.32C18.1416 7.72167 16.7999 6.36417 15.2974 5.39333C13.6324 4.31833 11.7957 3.75 9.98657 3.75C9.03285 3.75145 8.08563 3.90707 7.18157 4.21083C7.15625 4.21912 7.13348 4.23376 7.11544 4.25335C7.09739 4.27295 7.08466 4.29684 7.07848 4.32275C7.07229 4.34866 7.07285 4.37573 7.08011 4.40136C7.08736 4.42699 7.10106 4.45034 7.1199 4.46917L8.96574 6.315C8.9849 6.3342 9.00874 6.34807 9.0349 6.35523C9.06106 6.36239 9.08864 6.3626 9.1149 6.35583C9.74041 6.20346 10.3946 6.21469 11.0145 6.38844C11.6344 6.56219 12.1991 6.89261 12.6543 7.34788C13.1095 7.80316 13.4398 8.36795 13.6135 8.98789C13.7871 9.60782 13.7982 10.262 13.6457 10.8875C13.6392 10.9137 13.6397 10.9412 13.647 10.9672C13.6543 10.9932 13.6682 11.0168 13.6874 11.0358L16.3416 13.6925C16.3694 13.7199 16.4064 13.7359 16.4454 13.7375C16.4844 13.739 16.5225 13.726 16.5524 13.7008C17.5741 12.8302 18.4595 11.8114 19.1791 10.6783Z" />
            <path d="M9.99972 13.75C9.43196 13.75 8.87162 13.6211 8.36094 13.373C7.85026 13.1249 7.40258 12.7641 7.05167 12.3178C6.70076 11.8715 6.45577 11.3513 6.33518 10.7965C6.2146 10.2417 6.22156 9.66674 6.35555 9.11502C6.36197 9.0888 6.36151 9.06137 6.35421 9.03538C6.34691 9.00939 6.33302 8.98573 6.31389 8.96669L3.70305 6.35419C3.67526 6.32679 3.63824 6.31075 3.59925 6.3092C3.56025 6.30766 3.52209 6.32073 3.49222 6.34585C2.53889 7.15835 1.65555 8.14752 0.848886 9.30335C0.707514 9.5062 0.629654 9.7465 0.625202 9.99372C0.620751 10.2409 0.689909 10.4839 0.823886 10.6917C1.85555 12.3059 3.18222 13.6659 4.66222 14.6234C6.33055 15.7025 8.12139 16.2492 9.98722 16.2492C10.9507 16.2467 11.9079 16.0946 12.8247 15.7984C12.8502 15.79 12.8732 15.7753 12.8914 15.7556C12.9096 15.7359 12.9225 15.7119 12.9289 15.6859C12.935 15.6599 12.9344 15.6328 12.9271 15.6071C12.9198 15.5814 12.9061 15.5581 12.8872 15.5392L11.0331 13.685C11.0141 13.6658 10.9904 13.6519 10.9644 13.6446C10.9384 13.6373 10.9109 13.6368 10.8847 13.6434C10.595 13.714 10.2979 13.7498 9.99972 13.75Z" />
        </svg>
    );
};
