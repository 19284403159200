import React, {useEffect, useState} from 'react';

import {Evaluate} from '../../../../Evaluate';
import {Button} from '../../../../Button';
import {apiDeleteCommit, apiEvaluateQuestion, apiUpdateComments, apiUserAnswersTest} from '../../../../../api';
import {DEVICE, TYPE_COMMENT} from '../../../../../constants';

import styles from './Rating.module.scss';
import {NewCommentQuiz} from './components';
import {useAuth} from '../../../../../hooks';
import {CommentsList} from '../../../../CommentsList';
import {useParams} from 'react-router-dom';

export const Rating = props => {
    const {item, evaluateValue, setShowQuiz, setEvaluateValue, questionId, setQuestionId} = props;
    const [evaluate, setEvaluate] = useState(null);
    const [addComment, setAddComment] = useState(true);
    const [typeComment, setTypeComment] = useState('');
    const [commentsList, setCommentsList] = useState([]);
    const {user} = useAuth();
    const {id, test_id} = useParams();
    const ADD_TEXT_DESKTOP = '+ Add text comment';
    const ADD_TEXT_MOBILE = 'Text';
    const ADD_VIDEO_DESKTOP = '+ Add video comment';
    const ADD_VIDEO_MOBILE = 'Video';
    const ADD_AUDIO_DESKTOP = '+ Add audio comment';
    const ADD_AUDIO_MOBILE = 'Audio';
    const windowWidth = window.innerWidth;
    const value = {
        value: evaluate,
        answer: item?.answers?.id,
    };

    const handleAddText = () => {
        setAddComment(prev => !prev);
        setTypeComment(TYPE_COMMENT.text);
    };

    const handleAddVideo = () => {
        setAddComment(prev => !prev);
        setTypeComment(TYPE_COMMENT.video);
    };

    const handleAddAudio = () => {
        setAddComment(prev => !prev);
        setTypeComment(TYPE_COMMENT.audio);
    };

    const handleClick = async () => {
        if (questionId && evaluateValue) {
            const {data} = await apiEvaluateQuestion(value, questionId, 'PATCH');

            setEvaluateValue(data?.value);
            setShowQuiz(prev => !prev);
            return;
        }

        if (!evaluateValue) {
            const {data} = await apiEvaluateQuestion(value, null, 'POST');

            setEvaluateValue(data?.value);
            setQuestionId(data?.id);
            setShowQuiz(prev => !prev);
        }
    };

    const fetchComments = async () => {
        const data = await apiUpdateComments(id, test_id);
        data?.data.map(i => {
            if (i.id === item.id) {
                setCommentsList([i]);
            }
        });
    };

    const fetchDeleteAndUpdateList = async (userID, testID, commentID) => {
        await apiDeleteCommit(commentID);
        const data = await apiUpdateComments(id, test_id);
        data?.data.map(i => {
            if (i.id === item.id) {
                setCommentsList([i]);
            }
        });
    };

    // get comments on questions
    useEffect(() => {
        // testID - ід тесту юзера, userID - ід юзера який проходив тест
        fetchComments(id, test_id);
    }, []);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.body}>
                    <div className={styles.rating}>
                        <span className={styles.text}>Select a grade for this question</span>
                        <Evaluate
                            disabled={user.type === 'User'}
                            setEvaluate={setEvaluate}
                            evaluateValue={evaluateValue}
                        />
                        {user.type !== 'User' && (
                            <Button
                                type={'button'}
                                text={'Confirm'}
                                modifier={'button_accent'}
                                handleClick={handleClick}
                            />
                        )}
                    </div>
                    <div>
                        <div className={styles.comment}>
                            {user.type !== 'User' && (
                                <>
                                    <h2 className={styles.commentTitle}>Add comment</h2>
                                    {addComment && (
                                        <div className={styles.box}>
                                            <Button
                                                type={'button'}
                                                text={windowWidth > DEVICE.desktop ? ADD_TEXT_DESKTOP : ADD_TEXT_MOBILE}
                                                handleClick={handleAddText}
                                            />
                                            <Button
                                                type={'button'}
                                                text={
                                                    windowWidth > DEVICE.desktop ? ADD_VIDEO_DESKTOP : ADD_VIDEO_MOBILE
                                                }
                                                handleClick={handleAddVideo}
                                            />
                                            <Button
                                                type={'button'}
                                                text={
                                                    windowWidth > DEVICE.desktop ? ADD_AUDIO_DESKTOP : ADD_AUDIO_MOBILE
                                                }
                                                handleClick={handleAddAudio}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {!addComment && (
                                <NewCommentQuiz
                                    typeComment={typeComment}
                                    setAddComment={setAddComment}
                                    fetchComments={() => fetchComments(user?.id, item.test)}
                                    item={item}
                                />
                            )}
                            <h2 className={styles.commentTitle}>Comments</h2>
                            {commentsList?.length > 0 && (
                                <CommentsList
                                    list={commentsList}
                                    setCommentsList={setCommentsList}
                                    fetch={fetchDeleteAndUpdateList}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
