import React, {useEffect, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import {useParams} from 'react-router-dom';

import {Footer} from '../../components/Footer';
import {Title} from '../../components/Title';
import {UserHeader} from '../../components/UserHeader';
import {Header} from '../../components/Header';
import {MySelect} from '../../components/MySelect';
import {ErrorText} from '../../components/ErrorText';
import {Button} from '../../components/Button';

import {useAuth} from '../../hooks';
import {validationSchema} from '../../utils';
import {apiAssignedListQuiz, apiListQuiz} from '../../api';
import {ASSIGN_TESTS} from '../../constants';

import styles from './GroupPage.module.scss';
import toast from 'react-hot-toast';

export const AssignedTests = () => {
    const {isLoggedIn} = useAuth();
    const {id} = useParams();

    const [tests, setTests] = useState([]);
    const [initialAssignedTests, setInitialAssignedTests] = useState([]);

    const animatedComponents = makeAnimated();

    const handleSubmit = async values => {
        try {
            const data = {
                users: [Number(id)],
                tests: values.test_ids,
            };
            const response = await axios.post(ASSIGN_TESTS, data);

            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
        }
    };

    const getTests = async () => {
        try {
            const {data} = await apiListQuiz('');
            const formattedTests = data.results.map(({id, name}) => ({
                value: Number(id),
                label: name,
            }));
            setTests(formattedTests);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    const getAssignTests = async () => {
        try {
            if (id) {
                const {data} = await apiAssignedListQuiz('', '', id);
                if (data.results && data.results.length > 0) {
                    const formattedAssignedTests = data.results.map(({id, name}) => ({
                        value: Number(id),
                        label: name,
                    }));
                    setInitialAssignedTests(formattedAssignedTests);
                }
            }
        } catch (error) {
            console.error('Error fetching assigned tests:', error);
        }
    };

    useEffect(() => {
        getTests();
        getAssignTests();
    }, [id]);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'Assign tests'} />
                        <Formik
                            initialValues={{
                                test_ids: initialAssignedTests.map(test => test.value),
                            }}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}
                            validationSchema={Yup.object({
                                test_ids: validationSchema.test_ids,
                            })}
                        >
                            {({values, setFieldValue}) => (
                                <Form className={styles.form}>
                                    <div className={styles.wrapper}>
                                        <label className={styles.label}>
                                            Select tests
                                            <Field name="test_ids">
                                                {({field}) => (
                                                    <MySelect
                                                        {...field}
                                                        options={tests}
                                                        value={tests.filter(option =>
                                                            values.test_ids.includes(option.value)
                                                        )}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        onChange={selectedOptions => {
                                                            const selectedIds = selectedOptions.map(
                                                                option => option.value
                                                            );
                                                            setFieldValue('test_ids', selectedIds);
                                                        }}
                                                        placeholder={'Select tests'}
                                                        margin={'1.2rem 0 0 0'}
                                                        minHeight={'5.1rem'}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorText
                                                name={'test_ids'}
                                                tag={'p'}
                                            />
                                        </label>
                                    </div>
                                    <div className={styles.box}>
                                        <Button
                                            text={'Save'}
                                            type={'submit'}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
