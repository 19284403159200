import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Button} from '../Button';

import {apiLogOut} from '../../api';
import {useAuth} from '../../hooks';
import {clearCompany} from '../../redux/company';

import {Notification, Polygon} from '../../assets/icons';
import defaultImage from '../../assets/img/default.jpg';

import styles from './UserHeader.module.scss';

export const UserHeader = () => {
    const {user} = useAuth();
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const company = useSelector(state => state.company.company);

    const [isShowSubMenu, setIsShowSubMenu] = useState(false);
    const wrapperRef = useRef(null);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShowNotification = () => {
        console.log('notification');
    };

    const handleShowSubMenu = () => {
        setIsShowSubMenu(prev => !prev);
    };
    const handleLogOut = () => {
        dispatch(apiLogOut());
        dispatch(clearCompany());
    };

    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsShowSubMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                {id && (
                    <button
                        type={'button'}
                        onClick={handleBack}
                        className={styles.back}
                    >
                        {'< Back'}
                    </button>
                )}
                <div className={styles.company_name}>{company?.name}</div>
                <button
                    className={styles.notification}
                    onClick={handleShowNotification}
                    type={'button'}
                >
                    <Notification />
                </button>
                <div className={styles.body}>
                    <div className={styles.box}>
                        <img
                            className={styles.img}
                            src={user?.avatar ? user?.avatar : defaultImage}
                            alt=""
                        />
                        <button
                            className={styles.name}
                            type={'button'}
                            onClick={handleShowSubMenu}
                            ref={wrapperRef}
                        >
                            <span>{user?.first_name}</span>
                            <Polygon />
                        </button>
                    </div>
                    {isShowSubMenu && (
                        <div className={`${styles.subMenu}`}>
                            <Button
                                type={'button'}
                                text={'Log out'}
                                nameClass={'button__logout'}
                                modifier={'button_accent'}
                                handleClick={handleLogOut}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
