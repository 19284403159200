export const Play = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 0C5.3828 0 0 5.38331 0 12C0 18.6167 5.3828 24 12 24C18.6172 24 24 18.6167 24 12C24 5.38331 18.6172 0 12 0ZM16.7705 12.4204L9.77053 16.9204C9.6885 16.9736 9.59377 17 9.50002 17C9.41798 17 9.33497 16.9795 9.26077 16.939C9.09961 16.8511 9 16.6831 9 16.5V7.5C9 7.31691 9.09961 7.14891 9.26077 7.06102C9.41897 6.97411 9.6172 6.97945 9.77053 7.07958L16.7705 11.5796C16.9131 11.6714 17 11.8301 17 12C17 12.1699 16.9131 12.3286 16.7705 12.4204Z"
                fill="#6DD0EF"
            />
        </svg>
    );
};
