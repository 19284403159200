import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import ReactDOM from 'react-dom/client';

import {AppRouter} from './routes';
import {persistor, store} from './redux/store';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate
                loading={null}
                persistor={persistor}
            >
                <AppRouter />
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
