import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {apiLogIn, apiLogOut, apiRefreshUser, apiRegister, apiUpdateUser} from '../../api';

const initialState = {
    user: {
        id: null,
        name: '',
        email: '',
        avatar: null,
        last_name: '',
        type: '',
        organization_slug: '',
        organization_id: null,
    },
    token: null,
    isLoggedIn: false,
    isRefreshing: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(apiLogIn.fulfilled, (state, action) => {
                state.user.email = action.payload.data.email;
                state.token = action.payload.token;
                state.isLoggedIn = true;
            })
            .addCase(apiLogIn.rejected, state => {
                state.isRefreshing = false;
                state.isLoggedIn = false;
            })

            .addCase(apiRegister.fulfilled, (state, action) => {
                state.user.email = action.payload.data.email;
                state.token = action.payload.token;
                state.isLoggedIn = true;
            })

            .addCase(apiLogOut.fulfilled, state => {
                state.user = {name: '', email: ''};
                state.token = null;
                state.isLoggedIn = false;
                state.isRefreshing = false;
            })

            .addCase(apiRefreshUser.pending, state => {
                state.isRefreshing = true;
            })
            .addCase(apiRefreshUser.fulfilled, (state, action) => {
                state.user.id = action.payload.id;
                state.user.first_name = action.payload.first_name;
                state.user.email = action.payload.email;
                state.user.avatar = action.payload.avatar;
                state.user.last_name = action.payload.last_name;
                state.user.type = action.payload.type;
                state.user.organization_slug = action.payload.organization_slug;
                state.user.organization_id = action.payload.organization_id;
                state.isLoggedIn = true;
                state.isRefreshing = false;
            })
            .addCase(apiRefreshUser.rejected, state => {
                state.isRefreshing = false;
                state.token = null;
                state.isLoggedIn = false;
            })

            .addCase(apiUpdateUser.fulfilled, (state, action) => {
                state.user.first_name = action.payload.first_name;
                state.user.email = action.payload.email;
                state.user.avatar = action.payload.avatar;
                state.user.last_name = action.payload.last_name;
            });
    },
});

const userConfig = {
    key: 'user',
    storage,
    whitelist: ['token', 'isLoggedIn', 'user'],
};

export const userReducer = persistReducer(userConfig, userSlice.reducer);
