import React, {useEffect, useState} from 'react';

import {TableDesktop} from './TableDesktop';
import {TableMobile} from './TableMobile';

import {DEVICE} from '../../constants';

export const Table = props => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {pageCount, list, isLoading, isModalVideo, setSort, setPage, typeTables, fetch} = props;

    useEffect(() => {
        let timeoutId;

        function handleResize() {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                setWindowWidth(window.innerWidth);
            }, 200);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            {windowWidth <= DEVICE.mobile && (
                <TableMobile
                    isLoading={isLoading}
                    pageCount={pageCount}
                    list={list}
                    setPage={setPage}
                    typeTables={typeTables}
                    fetch={fetch}
                />
            )}
            {windowWidth >= DEVICE.desktop && (
                <TableDesktop
                    isLoading={isLoading}
                    pageCount={pageCount}
                    list={list}
                    setSort={setSort}
                    setPage={setPage}
                    typeTables={typeTables}
                    fetch={fetch}
                    isModalVideo={isModalVideo}
                />
            )}
        </>
    );
};
