import axios from 'axios';
import {
    ANSWER_TO_QUESTION,
    AVAILABLE_TESTS,
    CHECKED_TESTS,
    COMMENTS,
    COMMENTS_BY_ID,
    COMPANY,
    EVALUATE,
    FINISH_TEST,
    GROUPS,
    LOGIN,
    LOGOUT,
    REGISTER,
    REGISTER_REQUEST,
    REGISTER_REQUEST_ACTIONS,
    RESET_PASSWORD,
    SORT_COMPANY,
    SORT_GROUP,
    SORT_TESTS,
    TESTS,
    UPDATE_COMPANY,
    UPDATE_GROUP,
    UPDATE_TESTS,
    USER,
    USER_TEST,
    USER_TESTS,
    USERS,
    USER_INFO,
    USER_EVALUATED_TESTS,
    NEED_EVALUATION,
    DEFAULT_GROUP_USERS,
    NEED_ON_EVALUATION,
    ADMIN_GROUPS,
    ASSIGNED_TESTS,
    COMPANY_SETTINGS,
    ASSIGN_TEST,
} from '../constants';
import {createAsyncThunk} from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const token = {
    set(token) {
        axios.defaults.headers.common.Authorization = `token ${token}`;
    },
    unset() {
        axios.defaults.headers.common.Authorization = '';
    },
};

export const apiRegister = createAsyncThunk('user/register', async credentials => {
    try {
        const {data} = await axios.post(REGISTER, credentials);
        console.log('register');
        token.set(data.token);

        return data;
    } catch (e) {
        const data = e.response.data;
        const responseData = Object.values(data).find(value => Array.isArray(value) && value.length > 0)?.[0];

        await toast.error(responseData ?? 'something goes wrong');
        return Promise.reject(new Error('error registration'));
    }
});

export const apiLogIn = createAsyncThunk('user/login', async (credentials, thunkAPI) => {
    try {
        const {data} = await axios.post(LOGIN, credentials);
        if (data.token.length === 0) {
            return Promise.reject(new Error('invalid token'));
        }

        token.set(data.token);

        toast('Welcome', {
            icon: '👋',
        });

        return data;
    } catch (error) {
        toast.error('error');

        return thunkAPI.rejectWithValue(error.message);
        // return Promise.reject(new Error('error'));
    }
});

export const apiLogOut = createAsyncThunk('user/logout', async (_, thunkAPI) => {
    try {
        await axios.post(LOGOUT);

        token.unset();

        toast('Goodbye', {
            icon: '👋',
        });
    } catch (error) {
        toast.error('error');
        console.log(error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const apiRefreshUser = createAsyncThunk('user/refresh', async (_, thunkAPI) => {
    // Reading the token from the state via getState()
    const state = thunkAPI.getState();

    const persistedToken = state.user.token;
    if (persistedToken === null) {
        // If there is no token, exit without performing any request
        return thunkAPI.rejectWithValue('Unable to fetch user');
    }

    try {
        // If there is a token, add it to the HTTP header and perform the request
        token.set(persistedToken);

        const {data} = await axios.get(USER);
        return data;
    } catch (error) {
        if (error?.response?.data?.detail) {
            token.unset();
        }

        return thunkAPI.rejectWithValue(error.message);
    }
});

export const apiUpdateUser = createAsyncThunk('user/update', async (credentials, thunkAPI) => {
    try {
        const {data} = await axios.put(USER, credentials);
        toast.success('success');

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const apiCreateQuiz = async tasks => {
    try {
        const data = await axios.post(TESTS(), tasks);

        return data.status === 201 ? true : false;
    } catch (error) {
        return false;
    }
};

export const apiFinishTest = async id => {
    try {
        const data = await axios.post(FINISH_TEST(id));

        return data.status === 201 ? true : false;
    } catch (error) {
        return false;
    }
};

export const apiUserInfo = async id => {
    try {
        return await axios.get(USER_INFO(id));
    } catch (error) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiDeleteUser = async id => {
    try {
        return await axios.delete(USER_INFO(id));
    } catch (error) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiListQuiz = async page => {
    try {
        return await axios.get(TESTS(page));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiAvailableListQuiz = async (flag, page) => {
    try {
        return await axios.get(AVAILABLE_TESTS(flag, page));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiAssignedListQuiz = async (flag, page, id) => {
    try {
        return await axios.get(ASSIGNED_TESTS(flag, page, id));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiCheckedListQuiz = async id => {
    try {
        return await axios.get(CHECKED_TESTS(id));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiListUsers = async (sort, admin, company, page) => {
    try {
        return await axios.get(USERS(sort, admin, company, page));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};
export const apiDefaultListUsers = async company => {
    try {
        return await axios.get(DEFAULT_GROUP_USERS(company));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiTestQuiz = async id => {
    try {
        return await axios.get(UPDATE_TESTS(id));
    } catch (e) {
        toast.error('error');
        console.log(e);
    }
};

export const apiDeleteQuiz = async id => {
    try {
        await axios.delete(UPDATE_TESTS(id));

        toast.success('removed');
    } catch (e) {
        toast.error('error');
        console.log(e);
    }
};

export const apiAnswerToQuestion = async data => {
    try {
        await axios.post(ANSWER_TO_QUESTION, data);
        toast.success('success');
    } catch (e) {
        toast.error('error');
        console.log(e);
    }
};

export const apiEditQuiz = async id => {
    try {
        return axios.get(UPDATE_TESTS(id));
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const apiUpdateQuiz = async (id, tasks) => {
    try {
        return await axios.put(UPDATE_TESTS(id), tasks);
    } catch (e) {
        toast.error('error');
        console.log(e);
    }
};

export const apiSortListQuiz = async (flag, page) => {
    try {
        return await axios.get(SORT_TESTS(flag, page));
    } catch (e) {
        // вибиває помилка на Firefox
        // console.log(e);
    }
};

export const apiResetPassword = async value => {
    try {
        const data = await axios.post(RESET_PASSWORD, value);

        if (data.status === 200) {
            toast.success('success');

            return true;
        }
        return false;
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};

export const apiUserTests = async id => {
    try {
        return await axios.get(USER_TESTS(id));
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};

export const apiProvenUserTests = async (id, page) => {
    try {
        return await axios.get(USER_EVALUATED_TESTS(id, page));
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};

export const apiOnEvaluateUserTests = async (id, page) => {
    try {
        return await axios.get(NEED_ON_EVALUATION(id, page));
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};
export const apiAdminGroups = async id => {
    try {
        return await axios.get(ADMIN_GROUPS(id));
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};
export const apiNeedEvaluationTests = async id => {
    try {
        return await axios.get(NEED_EVALUATION(id));
    } catch (e) {
        console.log(e);
        toast.error('error');

        return false;
    }
};

export const apiCreateCompany = async value => {
    try {
        const data = await axios.post(COMPANY, value);

        if (data.status === 201) {
            toast.success('success');

            return data;
        }

        return false;
    } catch (error) {
        if (error.response && error.response.data.name && error.response.data.name[0]) {
            toast.error(`${error?.response?.data?.name[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }

        if (error.response && error.response.data.email && error.response.data.email[0]) {
            toast.error(`${error?.response?.data?.email[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }
    }
};

export const apiCompanies = async (flag, page) => {
    try {
        return await axios.get(SORT_COMPANY(flag, page));
    } catch (e) {
        console.log('e', e);
    }
};

export const apiEditCompany = async (id, method, value = null) => {
    try {
        const data = await axios({
            method: method,
            url: UPDATE_COMPANY(id),
            data: value,
        });

        if (data.status === 201 || data.status === 200) {
            return data;
        }

        if (data.status === 204) {
            toast.success('remove');
            return;
        }

        return false;
    } catch (error) {
        if (error.response && error.response.data.name && error.response.data.name[0]) {
            toast.error(`${error?.response?.data?.name[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }

        if (error.response && error.response.data.email && error.response.data.email[0]) {
            toast.error(`${error?.response?.data?.email[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }
    }
};

export const apiEditGroup = async (id, method, value = null) => {
    try {
        const data = await axios({
            method: method,
            url: UPDATE_GROUP(id),
            data: value,
        });
        if (data.status === 201 || data.status === 200) {
            return data;
        }

        if (data.status === 204) {
            toast.success('remove');
            return;
        }

        return false;
    } catch (error) {
        if (error.response && error.response.data.name && error.response.data.name[0]) {
            toast.error(`${error?.response?.data?.name[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }

        if (error.response && error.response.data.email && error.response.data.email[0]) {
            toast.error(`${error?.response?.data?.email[0]}`, {
                style: {
                    fontWeight: 700,
                    fontSize: '1.5rem',
                },
            });
        }
    }
};

export const apiCreateGroup = async value => {
    try {
        const data = await axios.post(GROUPS, value);

        if (data.status === 201) {
            toast.success('success');

            return data;
        }

        return false;
    } catch (error) {
        console.log('error', error);
    }
};

export const apiGroups = async (flag, page, organization_id) => {
    try {
        return await axios.get(SORT_GROUP(flag, page, organization_id));
    } catch (e) {
        console.log('e', e);
    }
};

export const apiUserAnswersTest = async (user, test) => {
    try {
        return await axios.get(USER_TEST(user, test));
    } catch (e) {
        console.log('e', e);
        toast.error('error');

        return false;
    }
};

export const assignTest = async value => {
    try {
        return await axios.post(ASSIGN_TEST, value);
    } catch (e) {
        console.log('e', e);
        toast.error('error');

        return false;
    }
};

export const apiEvaluateQuestion = async (value, id, method) => {
    try {
        const data = await axios({
            method: method,
            url: EVALUATE(id),
            data: value,
        });

        if (data.status === 201 || data.status === 200) {
            toast.success('success');

            return data;
        }

        return false;
    } catch (error) {
        toast.error('error');

        return false;
    }
};

// add comments on the question
export const apiCommentsTest = async (value, id, method) => {
    try {
        const data = await axios({
            method: method,
            url: COMMENTS(id),
            data: value,
        });

        if (data.status === 201 || data.status === 200) {
            toast.success('success');

            return data;
        }

        return false;
    } catch (e) {
        toast.error('error');

        return false;
    }
};

export const apiDeleteCommit = async id => {
    try {
        const data = await axios.delete(COMMENTS(id));

        if (data.status === 204) {
            toast.success('removed');
        }
    } catch (e) {
        toast.error('error');
        console.log(e);
    }
};

// get comments on questions
export const apiUpdateComments = async (user, test) => {
    try {
        return await axios.get(COMMENTS_BY_ID(test, user));
    } catch (e) {
        toast.error('error');

        return false;
    }
};

export const apiListRegisterRequest = async (id, page) => {
    try {
        return await axios.get(REGISTER_REQUEST(id, page));
    } catch (e) {
        toast.error('error');

        return false;
    }
};

export const apiRegisterRequestActions = async value => {
    try {
        const data = await axios.post(REGISTER_REQUEST_ACTIONS, value);

        if (data.status === 201) {
            toast.success('success');

            return data;
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const apiSaveCompany = createAsyncThunk('company/set', async (value, thunkAPI) => {
    try {
        const {data} = await axios.put(COMPANY_SETTINGS, value);

        toast.success('success');

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getCompany = createAsyncThunk('company/get', async (_, thunkAPI) => {
    try {
        const {data} = await axios.get(COMPANY_SETTINGS);

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});
