import React, {useEffect, useState} from 'react';
import {Table} from '../Table';
import {TYPE_TABLES} from '../Table/mock';
import {apiListRegisterRequest, apiRefreshUser} from '../../api';
import {useDispatch} from 'react-redux';
import {useAuth} from '../../hooks';

export const RegisterRequests = () => {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const {user} = useAuth();
    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const {data} = await apiListRegisterRequest(user.organization_id, page);

            setList(data?.results);

            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(user.organization_id, page);
    }, [page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <Table
            isLoading={isLoading}
            list={list}
            pageCount={pageCount}
            setPage={setPage}
            typeTables={TYPE_TABLES.seniorAdmin}
            fetch={fetchData}
        />
    );
};
