import styles from './UsersInGroup.module.scss';
import {TableDesktopDetailsRow} from '../../../TableDesktop/TableDesktopDetailsRow';

export const UsersInGroup = props => {
    const {typeTables, list} = props;

    return (
        <>
            <div className={styles.body}>
                <div className={styles.questionTable}>
                    <div className={styles.header}>
                        <div
                            className={styles.number}
                            style={typeTables?.sizeQuestions?.number}
                        >
                            <span>№</span>
                        </div>
                        <div
                            className={styles.name}
                            style={typeTables?.sizeQuestions?.name}
                        >
                            <span>{typeTables?.textQuestions?.name}</span>
                        </div>
                        {typeTables?.sizeQuestions?.email && (
                            <div
                                className={styles.email}
                                style={typeTables?.sizeQuestions?.email}
                            >
                                <span>E-mail</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.questions && (
                            <div
                                className={styles.question}
                                style={typeTables?.sizeQuestions?.questions}
                            >
                                <span>Question</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.rightAnswer && (
                            <div
                                className={styles.answer}
                                style={typeTables?.sizeQuestions?.rightAnswer}
                            >
                                <span>Right answer</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.userAnswer && (
                            <div
                                className={`${styles.answer} ${styles.answer_user}`}
                                style={typeTables?.sizeQuestions?.userAnswer}
                            >
                                <span>User answer</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.score && (
                            <div
                                className={styles.score}
                                style={typeTables?.sizeQuestions?.score}
                            >
                                <span>Score</span>
                            </div>
                        )}
                    </div>
                    {list?.users?.length > 0 &&
                        list?.users.map((elem, index) => {
                            const quantity = index + 1;
                            return (
                                <TableDesktopDetailsRow
                                    key={index}
                                    quantity={quantity}
                                    elem={elem}
                                    typeTables={typeTables}
                                />
                            );
                        })}
                </div>
            </div>
        </>
    );
};
