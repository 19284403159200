import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {getCompany, apiSaveCompany} from '../../api';

const initialState = {
    company: {
        social_media: [],
        name: '',
        address: '',
        logo: '',
        phone: '',
        email: '',
        copyright: '',
    },
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        clearCompany: state => {
            state.company = {
                social_media: [],
                name: '',
                address: '',
                logo: '',
                phone: '',
                email: '',
                copyright: '',
            };
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getCompany.fulfilled, (state, action) => {
                state.company.social_media = action.payload.social_media;
                state.company.name = action.payload.name;
                state.company.address = action.payload.address;
                state.company.logo = action.payload.logo;
                state.company.phone = action.payload.phone;
                state.company.email = action.payload.email;
                state.company.copyright = action.payload.copyright;
            })
            .addCase(getCompany.rejected, state => {
                state.social_media = [];
                state.name = '';
                state.address = '';
                state.logo = '';
                state.phone = '';
                state.email = '';
                state.copyright = '';
            })
            .addCase(apiSaveCompany.fulfilled, (state, action) => {
                state.company.social_media = action.payload.social_media;
                state.company.name = action.payload.name;
                state.company.address = action.payload.address;
                state.company.logo = action.payload.logo;
                state.company.phone = action.payload.phone;
                state.company.email = action.payload.email;
                state.company.copyright = action.payload.copyright;
            });
    },
});

const companyConfig = {
    key: 'company',
    storage,
    whitelist: ['company'],
};

export const companyReducer = persistReducer(companyConfig, companySlice.reducer);

export const {clearCompany} = companySlice.actions;
