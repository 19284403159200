export const Questionnaire = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9219 2.15625C13.3148 2.15625 10.334 4.88658 9.93623 8.38894C9.06248 7.99866 8.09527 7.78125 7.07812 7.78125C3.17522 7.78125 0 10.9355 0 14.8125C0 16.1658 0.380297 17.466 1.10325 18.5954L0.00421875 21.8438H7.07812C10.6847 21.8438 13.6652 19.1141 14.0636 15.6123C14.9575 16.0107 15.9274 16.2188 16.9219 16.2188H23.9958L22.8967 12.9704C23.6197 11.841 24 10.5408 24 9.1875C24 5.31047 20.8248 2.15625 16.9219 2.15625ZM7.07812 20.4375H1.96453L2.66255 18.3743C2.5732 18.205 1.40625 16.9565 1.40625 14.8125C1.40625 11.7109 3.95062 9.1875 7.07812 9.1875C10.1798 9.1875 12.7031 11.7109 12.7031 14.8125C12.7031 17.9141 10.1798 20.4375 7.07812 20.4375ZM22.0355 14.8125H16.9219C15.9094 14.8125 14.9291 14.5436 14.066 14.0337C13.8474 12.0588 12.8079 10.3279 11.2969 9.19106C11.2969 9.18989 11.2969 9.18867 11.2969 9.18745C11.2969 6.08583 13.8202 3.56245 16.9219 3.56245C20.0494 3.56245 22.5938 6.08583 22.5938 9.18745C22.5938 10.3688 22.2318 11.4991 21.5471 12.4562L21.3375 12.7493L22.0355 14.8125Z"
                fill="black"
            />
            <path
                d="M6.375 17.625H7.78125V19.0312H6.375V17.625Z"
                fill="black"
            />
            <path
                d="M7.07812 10.5938C5.91502 10.5938 4.96875 11.54 4.96875 12.7031H6.375C6.375 12.3154 6.69042 12 7.07812 12C7.46583 12 7.78125 12.3154 7.78125 12.7031C7.78125 12.8846 7.71234 13.0568 7.58723 13.1881C6.80555 14.0082 6.375 15.0836 6.375 16.2188L7.78125 16.2163C7.78125 15.4466 8.07384 14.7158 8.60517 14.1584C8.98069 13.7644 9.1875 13.2475 9.1875 12.7031C9.1875 11.54 8.24123 10.5938 7.07812 10.5938Z"
                fill="black"
            />
            <path
                d="M19.0312 8.48438H20.4375V9.89062H19.0312V8.48438Z"
                fill="black"
            />
            <path
                d="M16.2188 8.48438H17.625V9.89062H16.2188V8.48438Z"
                fill="black"
            />
            <path
                d="M13.4062 8.48438H14.8125V9.89062H13.4062V8.48438Z"
                fill="black"
            />
        </svg>
    );
};
