import styles from './UserPage.module.scss';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {useAuth} from '../../hooks';
import {useEffect, useState} from 'react';
import {apiRefreshUser, apiUserInfo} from '../../api';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {UserDetailsPage} from '../UserDetailsPage';
import {AdminDetailsPage} from '../AdminDetailsPage';

export const UserPage = () => {
    const {isLoggedIn} = useAuth();
    const dispatch = useDispatch();

    const [typeUser, setTypeUser] = useState('');

    const {id} = useParams();
    const fetchData = async () => {
        const {data} = await apiUserInfo(id);
        setTypeUser(data.type);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    {(() => {
                        switch (typeUser) {
                            case 'User':
                                return <UserDetailsPage />;
                            case 'Admin':
                                return <AdminDetailsPage />;
                            default:
                                return null;
                        }
                    })()}
                </main>
            </div>
            <Footer />
        </div>
    );
};
