import styles from './MySelect.module.scss';
import React from 'react';
import Select from 'react-select';
import {ErrorText} from '../ErrorText';

export const MySelect = props => {
    const {
        options,
        value,
        placeholder,
        onChange,
        errorName,
        margin,
        minHeight,
        isMulti,
        onBlur,
        components,
        closeMenuOnSelect,
        getOptionLabel,
        getOptionValue,
        isDisabled,
    } = props;

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Roboto',
            fontSize: '1.6rem',
            lineHeight: '1.9rem',
            height: '100%',
            borderRadius: 4,
            minHeight: minHeight ? minHeight : null,
            margin: margin ? margin : null,
            // borderColor: state.isFocused ? '#333' : '#ccc',
            '&:hover': {
                // borderColor: '#333',
            },
        }),
        valueContainer: provided => ({
            ...provided,
            fontSize: '1.6rem',
            lineHeight: '1.9rem',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '1.6rem',
            lineHeight: '1.9rem',
        }),
        menu: base => ({
            ...base,
            fontFamily: 'Roboto',
            fontSize: '1.6rem',
            lineHeight: '1.9rem',
        }),
        placeholder: base => ({
            ...base,
            color: '#000',
            fontWeight: '400',
            fontSize: '1.6rem',
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            padding: '0.5rem',
        }),
    };

    return (
        <div className={styles.select}>
            <Select
                isDisabled={isDisabled}
                className={styles.customSelect}
                styles={customStyles}
                options={options}
                value={value}
                onBlur={onBlur}
                isMulti={isMulti}
                onChange={onChange}
                placeholder={placeholder}
                components={components}
                closeMenuOnSelect={closeMenuOnSelect}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
            />
            {errorName && (
                <ErrorText
                    name={errorName}
                    tag={'p'}
                />
            )}
        </div>
    );
};
