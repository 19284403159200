export const Groups = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4844 12.569H18.1351C17.8154 12.569 17.5058 12.6127 17.2112 12.693C16.63 11.5506 15.4435 10.7656 14.0763 10.7656H9.92367C8.55647 10.7656 7.36997 11.5506 6.78881 12.693C6.4942 12.6127 6.18464 12.569 5.86491 12.569H3.51562C1.57711 12.569 0 14.1461 0 16.0846V19.8402C0 21.0033 0.946266 21.9495 2.10938 21.9495H21.8906C23.0537 21.9495 24 21.0033 24 19.8402V16.0846C24 14.1461 22.4229 12.569 20.4844 12.569ZM6.40805 14.2813V20.5433H2.10938C1.72167 20.5433 1.40625 20.2279 1.40625 19.8402V16.0847C1.40625 14.9216 2.35252 13.9753 3.51562 13.9753H5.86491C6.05578 13.9753 6.24061 14.0012 6.41658 14.049C6.41152 14.1259 6.40805 14.2033 6.40805 14.2813ZM16.1857 20.5433H7.8143V14.2813C7.8143 13.1182 8.76056 12.1719 9.92367 12.1719H14.0763C15.2394 12.1719 16.1857 13.1182 16.1857 14.2813V20.5433ZM22.5938 19.8402C22.5938 20.2279 22.2783 20.5433 21.8906 20.5433H17.592V14.2813C17.592 14.2032 17.5885 14.1258 17.5834 14.0489C17.7594 14.0012 17.9442 13.9753 18.1351 13.9753H20.4844C21.6475 13.9753 22.5938 14.9215 22.5938 16.0846V19.8402Z"
                fill="black"
            />
            <path
                d="M4.69044 5.92969C2.96783 5.92969 1.56641 7.33111 1.56641 9.05372C1.56636 10.7763 2.96783 12.1777 4.69044 12.1777C6.413 12.1777 7.81447 10.7763 7.81447 9.05372C7.81447 7.33111 6.41305 5.92969 4.69044 5.92969ZM4.69039 10.7715C3.74319 10.7715 2.97261 10.0009 2.97261 9.05372C2.97261 8.10652 3.74319 7.33594 4.69039 7.33594C5.63759 7.33594 6.40817 8.10652 6.40817 9.05372C6.40817 10.0009 5.63759 10.7715 4.69039 10.7715Z"
                fill="black"
            />
            <path
                d="M12.0018 2.05078C9.70041 2.05078 7.82812 3.92306 7.82812 6.22444C7.82812 8.52581 9.70041 10.3981 12.0018 10.3981C14.3032 10.3981 16.1754 8.52581 16.1754 6.22444C16.1754 3.92311 14.3032 2.05078 12.0018 2.05078ZM12.0018 8.99184C10.4758 8.99184 9.23438 7.75041 9.23438 6.22444C9.23438 4.69852 10.4758 3.45703 12.0018 3.45703C13.5278 3.45703 14.7692 4.69847 14.7692 6.22444C14.7692 7.75041 13.5278 8.99184 12.0018 8.99184Z"
                fill="black"
            />
            <path
                d="M19.3115 5.92969C17.589 5.92969 16.1875 7.33111 16.1875 9.05372C16.1875 10.7763 17.589 12.1777 19.3115 12.1777C21.0341 12.1777 22.4356 10.7763 22.4356 9.05372C22.4356 7.33111 21.0341 5.92969 19.3115 5.92969ZM19.3115 10.7715C18.3644 10.7715 17.5938 10.0009 17.5938 9.05372C17.5938 8.10652 18.3644 7.33594 19.3115 7.33594C20.2587 7.33594 21.0293 8.10652 21.0293 9.05372C21.0293 10.0009 20.2587 10.7715 19.3115 10.7715Z"
                fill="black"
            />
        </svg>
    );
};
