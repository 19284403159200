import React, {useEffect, useState} from 'react';
import styles from './Tab.module.scss';

export const Tab = props => {
    const {setTab, navigateToEvaluate = false, tabs} = props;
    const defaultTab = navigateToEvaluate ? tabs[1].name : tabs[0].name;
    const [activeTab, setActiveTab] = useState(null);

    const handleTabClick = name => {
        setActiveTab(name);
    };

    useEffect(() => {
        setTab(defaultTab);
        handleTabClick(defaultTab);
        window.history.replaceState({}, '');
    }, []);

    return (
        <div className={styles.tab__container}>
            {tabs.map(tab => (
                <button
                    key={tab.name}
                    className={`${styles.tab__button} ${tab.name === activeTab ? styles.active : ''}`}
                    onClick={() => {
                        setTab(tab.name);
                        handleTabClick(tab.name);
                    }}
                >
                    {tab.name}
                </button>
            ))}
        </div>
    );
};
