import {useEffect, useState} from 'react';
import {apiListQuiz, apiRefreshUser} from '../../api';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {Paginate} from '../../components/Paginate';
import {Title} from '../../components/Title';
import {UserCard} from '../../components/UserCard/';
import styles from './UserTestsPage.module.scss';
import {useDispatch} from 'react-redux';

export const UserTestsPage = () => {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async page => {
        const {data} = await apiListQuiz(page);

        setList(data?.results);

        setCount(data?.count);
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);
    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={true} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'Tests'} />
                        <div className={styles.card}>
                            {list.map(elem => {
                                return (
                                    <UserCard
                                        id={elem?.id}
                                        key={elem?.id}
                                        questions={elem?.questions}
                                        descStatus={'Public'}
                                        nameTest={elem?.name}
                                        descriptionTest={elem?.description}
                                        picture={elem?.picture}
                                        finished={elem?.is_finished}
                                        evaluated={elem?.is_evaluated}
                                        assessCount={'4/10'}
                                        scoreCount={'8/10'}
                                    />
                                );
                            })}
                            {pageCount > 1 && (
                                <div className={styles.paginate}>
                                    <Paginate
                                        pageCount={pageCount}
                                        setPage={setPage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
