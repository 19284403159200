export const Copy = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path
                    d="M1765 4831 c-196 -56 -355 -215 -411 -411 -11 -37 -18 -116 -21 -237
l-5 -183 -163 0 c-256 0 -376 -41 -510 -175 -81 -81 -127 -161 -155 -271 -19
-75 -20 -115 -20 -1414 0 -917 3 -1355 11 -1396 38 -208 212 -397 419 -453 73
-21 95 -21 1223 -21 1277 0 1203 -4 1342 68 91 47 198 154 243 242 54 107 63
157 69 357 l5 183 163 0 c256 0 376 41 510 175 81 81 127 161 155 271 19 75
20 115 20 1190 0 750 -3 1122 -10 1141 -12 32 -890 916 -932 939 -23 12 -166
14 -950 13 -843 0 -928 -2 -983 -18z m1705 -670 c0 -365 0 -368 23 -401 12
-18 35 -43 50 -54 27 -20 41 -21 403 -24 l374 -3 -2 -1022 c-3 -993 -4 -1023
-23 -1062 -25 -50 -80 -105 -130 -130 -39 -20 -67 -20 -1180 -20 l-1140 0 -40
22 c-52 28 -109 88 -132 138 -17 38 -18 114 -21 1359 -3 1458 -6 1380 61 1467
18 25 55 55 86 71 l53 28 809 0 809 0 0 -369z m475 -11 l150 -150 -153 0 -152
0 0 150 c0 83 1 150 3 150 1 0 70 -67 152 -150z m-2613 -1507 c4 -1159 -2
-1068 75 -1215 64 -124 196 -234 332 -281 66 -22 67 -22 899 -25 l834 -3 -4
-163 c-3 -158 -4 -164 -33 -221 -34 -66 -93 -118 -153 -135 -24 -6 -416 -10
-1157 -10 l-1120 0 -51 24 c-27 13 -66 42 -87 66 -71 84 -67 -13 -67 1450 0
1496 -6 1386 81 1471 69 67 108 77 296 78 l152 1 3 -1037z"
                />
                <path
                    d="M2280 3128 c-66 -34 -93 -79 -88 -149 5 -65 32 -109 82 -133 30 -14
107 -16 716 -16 582 0 685 2 710 15 62 32 99 115 81 183 -13 46 -65 99 -112
112 -20 6 -290 10 -689 10 l-656 0 -44 -22z"
                />
                <path
                    d="M2272 2273 c-49 -24 -75 -68 -80 -132 -5 -70 22 -115 88 -148 l44
-23 656 0 c399 0 669 4 689 10 47 13 99 66 112 112 18 68 -19 151 -81 183 -25
13 -128 15 -712 15 -609 0 -687 -2 -716 -17z"
                />
            </g>
        </svg>
    );
};
