import React from 'react';

import {Player} from '../Player';

import styles from './StartTestVideo.module.scss';

export const StartTestVideo = props => {
    const {handleClick, video} = props;

    return (
        <div className={styles.videoWrapper}>
            <Player
                controls={false}
                handleClick={handleClick}
                video={video}
                fullVideo={true}
            />
        </div>
    );
};
