import React from 'react';
import styles from './RequestsMobile.module.scss';
import {Button} from '../../../../Button';

export const RequestsMobile = props => {
    const {typeTables, list} = props;
    return (
        <div className={styles.body}>
            <div className={styles.accept}>
                <Button
                    nameClass="button__seniorDashboard"
                    text={'Accept admin'}
                />
                <Button
                    nameClass="button__seniorDashboard"
                    text={'Accept user'}
                />
            </div>
            <div className={styles.refuse}>
                <Button
                    nameClass="button__seniorRefuse"
                    text={'Refuse'}
                />
            </div>
            {typeTables?.sizeQuestions?.mail && (
                <div className={styles.info}>
                    <span>Mail</span>
                    <span>{list?.mail}</span>
                </div>
            )}
            {typeTables?.sizeQuestions?.phone && (
                <div className={styles.info}>
                    <span>Phone</span>
                    <span>{list?.phone}</span>
                </div>
            )}
            {typeTables?.sizeQuestions?.date && (
                <div className={styles.info}>
                    <span>Date</span>
                    <span>{list?.updated_at}</span>
                </div>
            )}
        </div>
    );
};
