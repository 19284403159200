import styles from './SingleQuestion.module.scss';
import {CrossSvg} from '../../assets/icons';
import {Input} from '../Input';
import {questions} from './mock';

export const SingleQuestion = props => {
    const {quantity, indexQuestions, remove} = props;

    const handleDelete = () => {
        remove(indexQuestions);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <p className={styles.quantity}>№ {quantity}</p>
                <button
                    className={styles.delete}
                    type={'button'}
                    onClick={handleDelete}
                >
                    <CrossSvg />
                </button>
            </div>
            <div className={styles.box}>
                {questions.map((question, index) => (
                    <div
                        key={index}
                        className={styles.item}
                    >
                        <label className={styles.title}>
                            {question?.title}
                            <Input
                                type={question?.type}
                                name={question?.name(indexQuestions)}
                                placeholder={question?.placeholder}
                                nameClass={question?.nameClass}
                            />
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};
