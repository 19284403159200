import styles from './AddQuizVideoLink.module.scss';
import {Input} from '../Input';

export const AddQuizVideoLink = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <label
                    className={styles.label}
                    htmlFor={'test_introductory_video_link'}
                >
                    Vimeo link
                </label>
                <Input
                    type={'url'}
                    name={'test_introductory_video_link'}
                    placeholder={'Vimeo link'}
                    nameClass={'input_video'}
                    id={'test_introductory_video_link'}
                />
            </div>
        </div>
    );
};
