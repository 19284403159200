import styles from './CommentDelete.module.scss';
import {useState} from 'react';
import {useAuth} from '../../hooks';
import {CrossSvg} from '../../assets/icons';
import {Modal} from '../Modal';
import {TEST_ID} from '../../pages';

export const CommentDelete = props => {
    const {item, fetch} = props;

    const [showModal, setShowModal] = useState(false);
    const {user} = useAuth();

    const handleClick = () => {
        setShowModal(prev => !prev);
    };

    return (
        <>
            {showModal && (
                <Modal
                    confirm={true}
                    setShowModal={setShowModal}
                    updateFetch={() => {
                        fetch(user?.id, TEST_ID, item?.id);
                    }}
                />
            )}
            <button
                className={styles.delete}
                type={'button'}
                onClick={handleClick}
            >
                <CrossSvg />
            </button>
        </>
    );
};
