export const DeleteQuiz = () => {
    return (
        <svg
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_52359)">
                <path
                    d="M16.9701 6.65565C20.0895 9.77503 20.0895 14.85 16.9701 17.9694C13.8507 21.0887 8.77577 21.0887 5.65639 17.9694C2.53701 14.85 2.53745 9.77459 5.65639 6.65565C8.77533 3.53671 13.8507 3.53627 16.9701 6.65565ZM6.53274 17.093C9.16837 19.7286 13.4577 19.7291 16.0938 17.093C18.7298 14.4569 18.7294 10.1676 16.0938 7.53199C13.4581 4.89637 9.16881 4.89592 6.53274 7.532C3.89667 10.1681 3.89667 14.4569 6.53274 17.093Z"
                    fill="black"
                />
                <path
                    d="M9.6005 9.64437L11.3532 11.3971L13.1059 9.64437C13.3478 9.4025 13.7399 9.40205 13.9823 9.64437C14.2246 9.88669 14.2241 10.2789 13.9823 10.5207L12.2296 12.2734L13.9823 14.0261C14.2246 14.2685 14.2241 14.6606 13.9823 14.9025C13.7404 15.1444 13.3482 15.1448 13.1059 14.9025L11.3532 13.1498L9.6005 14.9025C9.35862 15.1444 8.96644 15.1448 8.72413 14.9025C8.48181 14.6602 8.48225 14.268 8.72413 14.0261L10.4768 12.2734L8.72413 10.5207C8.48181 10.2784 8.48225 9.88625 8.72413 9.64437C8.966 9.4025 9.35818 9.40205 9.6005 9.64437Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_52359">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.627 12.3125)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
