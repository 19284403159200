import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useNavigate, useLocation} from 'react-router-dom';

import {Header} from '../../components/Header';
import {Title} from '../../components/Title';
import {Footer} from '../../components/Footer';
import {Button} from '../../components/Button';
import {Input} from '../../components/Input';

import {validationSchema} from '../../utils';
import {apiRefreshUser} from '../../api';
import {CHANGE_PASSWORD, ROUTS, SEND_RESET_PASSWORD_CODE} from '../../constants';

import styles from './ResetPasswordPage.module.scss';

let initialValues = {
    email: '',
};

const initialValuesCode = {
    code: '',
    password: '',
    confirm_password: '',
};

export const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {saveEmail} = location.state || {};

    const [isEmail, setIsEmail] = useState(true);
    const [email, setEmail] = useState(saveEmail ?? '');
    initialValues.email = saveEmail ?? '';
    const dispatch = useDispatch();
    const [isCode, setIsCode] = useState(false);

    const customEmailErrorToast = () => {
        const handleLink = () => {
            toast.dismiss();

            navigate(ROUTS.register);
        };

        return (
            <div>
                The email address email is not associated with any account. Please check for typos or{' '}
                <span
                    onClick={handleLink}
                    className={styles.toast_link}
                >
                    register a new account
                </span>{' '}
                if you haven't signed up yet.
            </div>
        );
    };

    const handleSubmit = async values => {
        try {
            await axios.post(SEND_RESET_PASSWORD_CODE, values);

            toast.success('Code sent again successfully!');

            setIsCode(true);
            setIsEmail(false);
            setEmail(values.email);
        } catch (error) {
            toast.error(customEmailErrorToast());
        }
    };

    const handleSubmitCode = async values => {
        try {
            await axios.post(CHANGE_PASSWORD, {...values, email});

            toast.success('Code reset successfully!');

            navigate(ROUTS.login);
        } catch (error) {
            toast.error('Failed to reset code. Please try again.');
        }
    };

    const handleSendAgain = async () => {
        try {
            await axios.post(SEND_RESET_PASSWORD_CODE, {email});

            toast.success('Code sent again successfully!');
        } catch (error) {
            toast.error('Failed to resend code. Please try again.');
        }
    };

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    // TODO якщощ такого емейлу немає: "This email does not exist"
    return (
        <>
            <Header isLoggedIn={false} />
            <main className={styles.container}>
                <div className={styles.wrapper}>
                    <Title text={'Reset password'} />
                    <p className={styles.subTitle}>Enter your Email. A password recovery code will be sent to him</p>
                    {isEmail && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={Yup.object({
                                email: validationSchema.email,
                            })}
                            onSubmit={handleSubmit}
                        >
                            <Form className={`${styles.form} ${styles.form_email}`}>
                                <label className={`${styles.label} ${styles.label_email}`}>
                                    E-mail
                                    <Input
                                        type={'email'}
                                        name={'email'}
                                        placeholder={'Example@gmail.com'}
                                    />
                                </label>
                                <Button
                                    type={'submit'}
                                    text={'Send'}
                                    modifier={'button_accent'}
                                />
                            </Form>
                        </Formik>
                    )}

                    {isCode && (
                        <Formik
                            initialValues={initialValuesCode}
                            validationSchema={Yup.object({
                                code: validationSchema.code_email,
                                password: validationSchema.new_password,
                                confirm_password: validationSchema.confirm_password,
                            })}
                            onSubmit={handleSubmitCode}
                        >
                            <Form className={`${styles.form} ${styles.form_code}`}>
                                <label className={`${styles.label}`}>
                                    Code
                                    <Input
                                        type={'text'}
                                        name={'code'}
                                        placeholder={'Write your code'}
                                    />
                                </label>
                                <label className={`${styles.label}`}>
                                    New password
                                    <Input
                                        type={'password'}
                                        name={'password'}
                                        placeholder={'********'}
                                    />
                                </label>
                                <label className={`${styles.label}`}>
                                    Repeat the new password
                                    <Input
                                        type={'password'}
                                        name={'confirm_password'}
                                        placeholder={'********'}
                                    />
                                </label>
                                <div className={styles.repeat_wrapper}>
                                    <button
                                        className={styles.repeat_text}
                                        type={'button'}
                                        onClick={handleSendAgain}
                                        // disabled={true}
                                    >
                                        send code again
                                    </button>
                                </div>
                                <Button
                                    type={'submit'}
                                    text={'Reset'}
                                    modifier={'button_accent'}
                                />
                            </Form>
                        </Formik>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};
