import styles from './UserCardDesc.module.scss';
import defaultImage from '../../assets/img/default.jpg';
import {InfoSvg} from '../../assets/icon/InfoSvg';
import {Modal} from '../Modal';
import {useState} from 'react';

export const UserCardDesc = props => {
    const {descStatus, nameTest, descriptionTest, picture} = props;

    const [modalVisible, setVisibleVisible] = useState(false);

    return (
        <div className={styles.desc}>
            <div className={styles.desc__block}>
                <div className={styles.infoContainer}>
                    <div
                        className={styles.photo}
                        data-wrapper="wrapper"
                    >
                        <img
                            src={picture ? picture : defaultImage}
                            alt=""
                        />
                    </div>
                    <div className={styles.desc__textContainer}>
                        <h3 className={styles.desc__textStatus}>{descStatus}</h3>
                        <p className={styles.desc__text}>{nameTest}</p>
                    </div>
                </div>
                <div>
                    <div
                        className={styles.info}
                        onClick={() => {
                            setVisibleVisible(true);
                        }}
                    >
                        <InfoSvg
                            height={22}
                            width={22}
                        />
                    </div>
                </div>
            </div>

            {modalVisible && (
                <Modal
                    description={descriptionTest}
                    setShowModal={setVisibleVisible}
                    testDescription={true}
                />
            )}
        </div>
    );
};
