import React, {useEffect, useState} from 'react';
import {Title} from '../../components/Title';
import defaultImage from '../../assets/img/default.jpg';
import styles from './UserDetailsPage.module.scss';
import {Button} from '../../components/Button';
import {Table} from '../../components/Table';
import {TYPE_TABLES} from '../../components/Table/mock';
import {SORT_TYPES} from '../../constants';
import {useDispatch} from 'react-redux';
import {apiCheckedListQuiz, apiProvenUserTests, apiRefreshUser, apiUserInfo, apiUserTests} from '../../api';
import {useNavigate, useParams} from 'react-router-dom';

const array = [
    {name: 'How to properly catch a crucian carp?', assigned: 'Assigned'},
    {name: 'How to properly catch a crucian carp?', assigned: 'Refused'},
    {name: 'How to properly catch a crucian carp?'},
    {name: 'How to properly catch a crucian carp?'},
    {name: 'How to properly catch a crucian carp?'},
];

export const UserDetailsPage = () => {
    const [list, setList] = useState(array);
    const [listUser, setListUser] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const [user, setUser] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async () => {
        const {data} = await apiUserInfo(id);
        setUser(data);
    };
    const fetchDataTests = async () => {
        const {data} = await apiUserTests(id);
        setList(data?.results);
    };

    const handleClick = () => {
        navigate(`/dashboard/${id}`, {replace: true});
    };

    const fetchDataUser = async () => {
        const {data} = await apiProvenUserTests(id);
        setListUser(data?.results);
        setCount(data?.count);
    };

    useEffect(() => {
        fetchDataUser(user?.id);
    }, []);

    useEffect(() => {
        fetchDataTests();
        fetchData();
    }, []);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.container}>
            <Title text={'User - Sales Simulator'} />
            <div className={styles.userInfoContainer}>
                <img
                    className={styles.img}
                    src={user.avatar ? user.avatar : defaultImage}
                    alt="avatar"
                />
                <div>
                    <div className={styles.userInfo}>
                        <div className={styles.nameText}>
                            <div className={styles.userInfoItem}>Name:</div>
                            <div className={styles.userInfoItem}>Email:</div>
                            <div className={styles.userInfoItem}>Company:</div>
                        </div>
                        <div className={styles.valueText}>
                            <div className={styles.userInfoItem}>
                                {user.first_name} {user.last_name}
                            </div>
                            <div className={styles.userInfoItem}>{user.email}</div>
                            <div className={styles.userInfoItem}>{user.organization_slug}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button
                        text={'Need evauation'}
                        modifier={'button_question'}
                        handleClick={handleClick}
                    />
                    {list.length ? <span className={styles.buttonBadge}>{list.length}</span> : <></>}
                </div>
            </div>
            {/*<div className={styles.roleContainer}>*/}
            {/*   <Title text={'Role'}/>*/}
            {/*   <div>*/}
            {/*      <Button text={'Change to admin'} type={'button'} modifier={'button_accent'}/>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {/*<Title text={'User requests'}/>*/}
            {/*<div className={styles.line}>*/}
            {/*   <Table*/}
            {/*      pageCount={pageCount}*/}
            {/*      list={list}*/}
            {/*      setSort={setSort}*/}
            {/*      setPage={setPage}*/}
            {/*      typeTables={TYPE_TABLES.userRequest}*/}
            {/*   />*/}
            {/*</div>*/}
            <Title text={'History'} />
            <div>
                <Table
                    pageCount={pageCount}
                    list={listUser}
                    setSort={setSort}
                    setPage={setPage}
                    typeTables={TYPE_TABLES.proveUser}
                />
            </div>
        </div>
    );
};
