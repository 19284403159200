import React, {useRef, useState} from 'react';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';
import {Tooltip} from 'react-tooltip';

import {TableDesktopDetails} from '../TableDesktopDetails';
import {Modal} from '../../../Modal';
import {Button} from '../../../Button';

import {ROUTS, TYPE_USERS} from '../../../../constants';
import {apiRegisterRequestActions} from '../../../../api';
import {useAuth} from '../../../../hooks';

import defaultImage from '../../../../assets/img/default.jpg';
import {Copy, DeleteQuiz, Edit, Play, StopVoice} from '../../../../assets/icons';

import styles from './TableDesktopItem.module.scss';

export const TableDesktopItem = props => {
    const {id, position, item, typeTables, fetch, isModalVideo} = props;

    const navigate = useNavigate();
    const {user} = useAuth();
    const {id: user_id} = useParams();

    const [showQuiz, setShowQuiz] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [play, setPlay] = useState(true);
    const [video, setVideo] = useState(null);
    const [questionId, setQuestionId] = useState(item?.answers?.score?.id);
    const [evaluateValue, setEvaluateValue] = useState(item?.answers?.score?.value);
    const audio = useRef(null);
    const [tooltip, setTooltip] = useState({
        content: 'Copy to clipboard',
        variant: 'dark',
    });

    const cssClass = styles.open;

    const handleOpenVideo = video => {
        setVideo(video);

        setShowModal(true);
    };

    const handlePlay = () => {
        audio.current.play();

        audio.current.onended = function () {
            setPlay(prev => !prev);
        };

        setPlay(prev => !prev);
    };

    const handlePause = () => {
        audio.current.pause();

        setPlay(prev => !prev);
    };

    const handleDelete = () => {
        setShowModal(prev => !prev);
    };

    const handleToggle = event => {
        const wrapper = event.target.getAttribute('data-wrapper');
        const edit = event.target.getAttribute('data-edit');

        if (edit) {
            navigate(ROUTS.testCreate);
            return;
        }

        if (!wrapper) return;

        event.currentTarget.classList.toggle(cssClass);
        setShowQuiz(prev => !prev);
    };

    const handleEvaluate = () => {
        setShowQuiz(prev => !prev);
    };

    const handleStartTest = () => {
        navigate(`/user-test/${item?.id}`, {replace: true});
    };

    const handleChangeRating = () => {
        setShowQuiz(prev => !prev);
    };

    const handleShowUser = () => {
        setShowQuiz(prev => !prev);
    };

    const handleRegisterRequest = async action => {
        await apiRegisterRequestActions({user: id, action: action});
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(`${window.origin}?test=${id}&organization=${item.organization}`);
            setTooltip({
                content: 'Text copied!',
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleMouseEnter = () => {
        setTooltip({
            content: 'Copy to clipboard',
            variant: 'dark',
        });
    };

    return (
        <>
            <div className={styles.item}>
                {showModal && isModalVideo && (
                    <Modal
                        video={isModalVideo}
                        setShowModal={setShowModal}
                        videoLink={video}
                    />
                )}
                {showModal && !isModalVideo && (
                    <Modal
                        confirm={true}
                        setShowModal={setShowModal}
                        updateFetch={() => {
                            fetch(id);
                        }}
                    />
                )}
                <div
                    className={styles.wrapper}
                    onClick={typeTables?.toggle ? handleToggle : null}
                    data-wrapper="wrapper"
                    data-wrapper-user="wrapper_user"
                    style={!typeTables?.toggle ? {cursor: 'auto'} : null}
                >
                    <div
                        className={styles.position}
                        data-wrapper="wrapper"
                    >
                        <span data-wrapper="wrapper">{position}</span>
                    </div>
                    {typeTables?.photo && (
                        <div
                            className={styles.photo}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.photo}
                        >
                            {item?.avatar ? (
                                <img
                                    src={item?.avatar ? item?.avatar : defaultImage}
                                    alt=""
                                    data-wrapper="wrapper"
                                />
                            ) : (
                                <img
                                    src={item?.picture ? item?.picture : defaultImage}
                                    alt=""
                                    data-wrapper="wrapper"
                                />
                            )}
                        </div>
                    )}
                    {typeTables?.name && (
                        <div
                            data-wrapper="wrapper"
                            className={styles.name}
                            style={typeTables?.size?.name}
                        >
                            <span data-wrapper="wrapper">
                                {item?.name} {item?.first_name} {item?.last_name} {item?.question_name}
                            </span>
                        </div>
                    )}
                    {typeTables?.question && (
                        <div
                            className={`${styles.button} ${styles.button_question}`}
                            style={typeTables?.size.question}
                        >
                            <button
                                onClick={() => handleOpenVideo(item?.question_link)}
                                type={'button'}
                            >
                                <Play />
                            </button>
                        </div>
                    )}
                    {typeTables?.rightAnswer && (
                        <div
                            className={`${styles.button} ${styles.button_answer}`}
                            style={typeTables?.size?.rightAnswer}
                        >
                            <button
                                onClick={() => handleOpenVideo(item?.question_answer)}
                                type={'button'}
                            >
                                <Play />
                            </button>
                        </div>
                    )}
                    {typeTables?.userAnswer && (
                        <div
                            className={`${styles.button} ${styles.button_question}`}
                            style={typeTables?.size?.userAnswer}
                        >
                            <audio
                                className={styles.audio}
                                controls
                                src={item?.answers?.answer}
                                ref={audio}
                            ></audio>
                            {play ? (
                                <button
                                    className={`${styles.button} ${styles.button_play}`}
                                    type={'button'}
                                    onClick={handlePlay}
                                >
                                    <Play />
                                </button>
                            ) : (
                                <button
                                    className={`${styles.button} ${styles.button_pause}`}
                                    type={'button'}
                                    onClick={handlePause}
                                >
                                    <StopVoice />
                                </button>
                            )}
                        </div>
                    )}
                    {typeTables?.date && (
                        <span
                            className={styles.date}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.date}
                        >
                            {item?.updated_at}
                        </span>
                    )}
                    {typeTables?.email && (
                        <span
                            className={styles.email}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.email}
                        >
                            {item?.email}
                        </span>
                    )}
                    {typeTables?.assignTests && (
                        <span
                            className={styles.usersGroup}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.assignTests}
                        >
                            <Link
                                className={styles.usersGroup}
                                to={`/assign-tests/${id}`}
                                replace={true}
                            >
                                {item?.assigned_tests_count}
                            </Link>
                        </span>
                    )}
                    {typeTables?.role && (
                        <span
                            className={styles.role}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.role}
                        >
                            {item?.type}
                        </span>
                    )}
                    {typeTables?.admin && (
                        <div
                            className={styles.admin}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.admin}
                        >
                            <span data-wrapper="wrapper">{item?.admin_name}</span>
                        </div>
                    )}
                    {typeTables?.superAdminName && (
                        <div
                            className={styles.admin}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.superAdminName}
                        >
                            <span data-wrapper="wrapper">
                                {item?.senior_admin?.first_name} {item?.senior_admin?.last_name}
                            </span>
                        </div>
                    )}
                    {typeTables?.assigned && (
                        <span
                            className={styles.assigned}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.assigned}
                        >
                            2
                        </span>
                    )}
                    {typeTables?.group && (
                        <span
                            className={styles.group}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.group}
                        >
                            {item?.group}
                        </span>
                    )}
                    {typeTables?.numberTests && (
                        <span
                            className={styles.numberTests}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.numberTests}
                        >
                            {item?.count_of_tests}
                        </span>
                    )}
                    {typeTables?.users && (
                        <span
                            className={styles.users}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.users}
                        >
                            {item?.users ? (
                                <>
                                    {item?.users?.length ? (
                                        <button
                                            className={styles.usersGroup}
                                            type={'button'}
                                            onClick={handleShowUser}
                                        >
                                            {item?.users?.length}
                                        </button>
                                    ) : (
                                        0
                                    )}
                                </>
                            ) : (
                                'Public test'
                            )}
                        </span>
                    )}
                    {typeTables?.score && (
                        <div
                            className={styles.score}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.score}
                        >
                            {typeTables?.type?.evaluate && (
                                <>
                                    {evaluateValue ? (
                                        <button
                                            type={'button'}
                                            className={styles.changeRating}
                                            onClick={handleChangeRating}
                                        >
                                            {evaluateValue}/10 {user.type !== 'User' && ` - Change rating`}
                                        </button>
                                    ) : (
                                        <Button
                                            text={'To evaluate'}
                                            type={'button'}
                                            modifier={'button_addTest'}
                                            handleClick={handleEvaluate}
                                        />
                                    )}
                                </>
                            )}
                            {typeTables?.type?.startTest && (
                                <Button
                                    text={'Start test'}
                                    type={'button'}
                                    modifier={'button_addTest'}
                                    handleClick={handleStartTest}
                                />
                            )}

                            {typeTables?.type?.detailTest && (
                                <Button
                                    text={'Details'}
                                    type={'button'}
                                    modifier={'button_addTest'}
                                    handleClick={handleStartTest}
                                />
                            )}
                            {typeTables?.type?.onEvaluate && (
                                <span
                                    className={styles.evaluateText}
                                    data-wrapper="wrapper"
                                    style={typeTables?.size?.group}
                                >
                                    On evaluate
                                </span>
                            )}
                            {typeTables?.type?.userRequest && (
                                <>
                                    {!item.assigned ? (
                                        <>
                                            <Button
                                                nameClass="button__seniorDashboard"
                                                text={'Assign'}
                                            />
                                            <Button
                                                nameClass="button__seniorRefuse"
                                                text={'Refuse'}
                                            />
                                        </>
                                    ) : (
                                        <div
                                            className={
                                                item.assigned === 'Assigned'
                                                    ? styles.userRequestAssigned
                                                    : styles.userRequestRefused
                                            }
                                        >
                                            {item?.assigned}
                                        </div>
                                    )}
                                </>
                            )}
                            {typeTables?.type?.score && (
                                <span
                                    className={`${styles.changeRating}`}
                                    data-wrapper="wrapper"
                                >
                                    {item?.evaluated ? (
                                        <Link
                                            className={styles.changeRating_link}
                                            to={`${id}`}
                                            replace={true}
                                        >
                                            {`${item?.score}/10 - Change rating`}
                                        </Link>
                                    ) : (
                                        <Link
                                            className={styles.evaluate}
                                            to={`${id}`}
                                        >
                                            To evaluate
                                        </Link>
                                    )}
                                </span>
                            )}
                            {typeTables?.type?.needEvaluation && (
                                <span
                                    className={`${styles.changeRating}`}
                                    data-wrapper="wrapper"
                                >
                                    {item?.evaluated ? (
                                        <Link
                                            className={styles.changeRating_link}
                                            to={`${id}`}
                                            replace={true}
                                        >
                                            {`${item?.score}/10 - Change rating`}
                                        </Link>
                                    ) : (
                                        <Link
                                            className={styles.evaluate}
                                            to={`/dashboard/${id}`}
                                        >
                                            To evaluate
                                        </Link>
                                    )}
                                </span>
                            )}
                            {typeTables?.type?.evaluateTest && (
                                <span
                                    className={`${styles.changeRating}`}
                                    data-wrapper="wrapper"
                                >
                                    <Link
                                        className={styles.evaluate}
                                        to={`/user-checked/${user_id ? user_id : user.id}/${item.id}`}
                                    >
                                        Details
                                    </Link>
                                </span>
                            )}
                        </div>
                    )}
                    {typeTables?.actions && (
                        <div
                            className={styles.actions}
                            data-wrapper="wrapper"
                            style={typeTables?.size?.actions}
                        >
                            {typeTables?.type?.edit && (
                                <>
                                    <button
                                        className={`${styles.icon} ${styles.icon_button}`}
                                        type={'button'}
                                        data-edit="edit"
                                    >
                                        <Link to={`${id}`}>
                                            <Edit />
                                        </Link>
                                    </button>
                                    {typeTables?.type?.copy && (
                                        <button
                                            className={`${styles.icon} ${styles.icon_button}`}
                                            type={'button'}
                                            onClick={handleCopy}
                                            onMouseEnter={handleMouseEnter}
                                            data-edit={'copy'}
                                            data-tooltip-id={'copy-tooltip'}
                                            data-tooltip-content={tooltip.content}
                                            data-tooltip-variant={tooltip.variant}
                                        >
                                            <Copy />
                                        </button>
                                    )}
                                    {item.name !== 'Default Group' && (
                                        <button
                                            className={`${styles.icon} ${styles.icon_button}`}
                                            type={'button'}
                                            onClick={handleDelete}
                                        >
                                            <DeleteQuiz />
                                        </button>
                                    )}
                                </>
                            )}
                            {typeTables?.type?.editAdmins && (
                                <>
                                    <button
                                        className={`${styles.icon} ${styles.icon_button}`}
                                        type={'button'}
                                        data-edit="edit"
                                    >
                                        <Link to={`/groups-list/${item.id}`}>
                                            <Edit />
                                        </Link>
                                    </button>
                                    <button
                                        className={`${styles.icon} ${styles.icon_button}`}
                                        type={'button'}
                                        onClick={handleDelete}
                                    >
                                        <DeleteQuiz />
                                    </button>
                                </>
                            )}
                            {typeTables?.type?.access && (
                                <div
                                    className={styles.seniorActions}
                                    style={typeTables?.size?.actions}
                                >
                                    <Button
                                        handleClick={() => handleRegisterRequest('ACCEPT_ADMIN').then(() => fetch())}
                                        nameClass="button__seniorDashboard"
                                        text={'Accept admin'}
                                    />
                                    <Button
                                        handleClick={() => handleRegisterRequest('ACCEPT_USER').then(() => fetch())}
                                        nameClass="button__seniorDashboard"
                                        text={'Accept user'}
                                    />
                                    <Button
                                        handleClick={() => handleRegisterRequest('REFUSE').then(() => fetch())}
                                        nameClass="button__seniorRefuse"
                                        text={'Refuse'}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <TableDesktopDetails
                    toggle={showQuiz}
                    item={item}
                    typeTables={typeTables}
                    evaluateValue={evaluateValue}
                    setShowQuiz={setShowQuiz}
                    setEvaluateValue={setEvaluateValue}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                />
            </div>
            <Tooltip
                id={'copy-tooltip'}
                place={'top'}
                style={{fontSize: '1.6rem'}}
            />
        </>
    );
};
