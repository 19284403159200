export const AUDIO_PERMISSION_MSG = {
    allow_microphone: 'Please allow microphone permission for Sales Simulator.',
    allow_headphones: 'Please use headphones',
    allow_system_microphone: 'Please allow microphone permission in your system settings',
    safari_refuse_microphone:
        'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    firefox_refuse_microphone:
        'NotAllowedError: The request is not allowed by the user agent or the platform in the current context.',
    chrome_refuse_microphone: 'NotAllowedError: Permission denied',
    chrome_system_microphone: 'NotAllowedError: Permission denied by system',
    firefox_system_microphone: 'OverconstrainedError: Invalid constraint',
    safari_headphones_allow: 'OverconstrainedError: Invalid constraint',
    chrome_headphones_allow: 'NotFoundError: Requested device not found',
    firefox_headphones_allow: 'NotFoundError: Requested device not found',
    firefox_headphones_allow_another_option: 'NotFoundError: The object can not be found here.',
};

export const QUIZ_COMPONENTS = {
    start_test: 'start_test',
    test_video: 'test_video',
    timer_message: 'timer_message',
    voice_recorder: 'voice_recorder',
    answer_to_question: 'answer_to_question',
    error_recording: 'error_recording',
};

export const RECORDING = {
    time: 120000,
    width_voice_mobile: 266.46,
    width_voice_desktop: 640,
};
