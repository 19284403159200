export const Polygon = () => {
    return (
        <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M-5.96046e-08 0.732793L3.4641 0.732793L1.73205 3.73279L-5.96046e-08 0.732793Z"
                fill="black"
            />
        </svg>
    );
};
