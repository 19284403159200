import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {apiAnswerToQuestion} from '../../api';
import {QUIZ_COMPONENTS, RECORDING} from '../../constants';

import {VoiceDesktop} from '../../assets/icons/VoiceDesktop';
import {Voice, StopVoiceTest} from '../../assets/icons';

import styles from './VoiceRecord.module.scss';

export const VoiceRecorder = props => {
    const {isTimerEnd, handleClick, questionId, stream} = props;

    const windowWidth = window.innerWidth;
    const mimeType = 'audio/aac';

    const mediaRecorder = useRef(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [time, setTime] = useState(0);
    const [isStart, setIsStart] = useState(false);
    const [width, setWidth] = useState(windowWidth);

    const user = useSelector(state => state.user.user);

    const startRecording = async () => {
        const media = new MediaRecorder(stream, {type: mimeType});
        setIsStart(true);
        mediaRecorder.current = media;
        mediaRecorder.current.start();
        let localAudioChunks = [];
        mediaRecorder.current.ondataavailable = event => {
            if (typeof event.data === 'undefined') return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
    };

    const stopRecording = () => {
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, {type: mimeType});
            const audioFile = new File([audioBlob], 'audio.aac', {type: mimeType});

            const formData = new FormData();
            formData.append('user', user.id);
            formData.append('question', questionId);
            formData.append('answer', audioFile);

            await apiAnswerToQuestion(formData).then(() => {
                handleClick(QUIZ_COMPONENTS.answer_to_question);
            });
        };
    };

    const timeRecording = seconds => {
        return ('0' + Math.floor((time / seconds) % 60)).slice(-2);
    };

    useEffect(() => {
        if (isTimerEnd) {
            startRecording();
        }
    }, [isTimerEnd, stream]);

    useEffect(() => {
        let interval;
        if (isStart) {
            interval = setInterval(() => {
                setTime(prevTime => prevTime + 10);
                if (time === RECORDING.time) {
                    stopRecording();
                }
            }, 10);
        } else if (!isStart) {
            clearInterval();
        }
        return () => clearInterval(interval);
    }, [isStart, time]);

    return (
        <>
            <div className={styles.recording}>
                <>
                    <div className={styles.voice}>
                        {width <= 666 && (
                            <div className={styles.voiceWrapper}>
                                <Voice />
                                <div
                                    className={styles.voice__recording}
                                    style={{left: (time / RECORDING.time) * RECORDING.width_voice_mobile}}
                                />
                            </div>
                        )}
                        {width >= 667 && (
                            <div className={styles.voiceWrapper}>
                                <VoiceDesktop />
                                <div
                                    className={styles.voice__recording}
                                    style={{left: (time / RECORDING.time) * RECORDING.width_voice_desktop}}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.stopWrapper}>
                        {width >= 667 && <div className={styles.line} />}
                        <div
                            onClick={stopRecording}
                            className={styles.svgWrapper}
                        >
                            <StopVoiceTest />
                        </div>
                    </div>

                    <div className={styles.time__recording}>
                        {timeRecording(60000)}:{timeRecording(1000)}
                    </div>
                </>
            </div>
        </>
    );
};
