import styles from './CommentView.module.scss';
import {CommentText} from '../CommentText';
import {CommentVideo} from '../CommentVideo';
import {CommentAudio} from '../CommentAudio';

export const CommentView = props => {
    const {item, fetch} = props;

    return (
        <>
            <div className={styles.wrapper}>
                {item?.comments?.text?.length > 0 && (
                    <div className={styles.item}>
                        <span className={styles.comment}>Comment</span>
                        {item?.comments?.text?.map(item => (
                            <CommentText
                                key={item?.id}
                                item={item}
                                fetch={fetch}
                            />
                        ))}
                    </div>
                )}
                {item?.comments?.video?.length > 0 && (
                    <div className={styles.item}>
                        <span className={styles.comment}>Video</span>
                        {item?.comments?.video?.map(item => (
                            <CommentVideo
                                key={item?.id}
                                item={item}
                                fetch={fetch}
                            />
                        ))}
                    </div>
                )}
                {item?.comments?.audio?.length > 0 && (
                    <div className={styles.item}>
                        <span className={styles.comment}>Audio</span>
                        {item?.comments?.audio?.map(item => (
                            <CommentAudio
                                key={item?.id}
                                item={item}
                                fetch={fetch}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
