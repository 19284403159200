import styles from './RatingMobile.module.scss';
import {Evaluate} from '../../../../Evaluate';
import {Button} from '../../../../Button';
import {apiEvaluateQuestion} from '../../../../../api';
import {useState} from 'react';

export const RatingMobile = props => {
    const {item, evaluateValue, setEvaluateValue, questionId, setQuestionId, setShowRating, disabled} = props;

    const [evaluate, setEvaluate] = useState(null);
    const value = {
        value: evaluate,
        answer: item?.answers?.id,
    };

    const handleClick = async () => {
        if (questionId && evaluateValue) {
            const {data} = await apiEvaluateQuestion(value, questionId, 'PATCH');

            setEvaluateValue(data?.value);
            setShowRating(prev => !prev);
            return;
        }

        if (!evaluateValue) {
            const {data} = await apiEvaluateQuestion(value, null, 'POST');

            setEvaluateValue(data?.value);
            setQuestionId(data?.id);
            setShowRating(prev => !prev);
        }
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.body}>
                    <span className={styles.text}>Score for this question</span>
                    <Evaluate
                        disabled={disabled}
                        setEvaluate={setEvaluate}
                        evaluateValue={evaluateValue}
                    />
                    {/*<Button*/}
                    {/*   type={'button'}*/}
                    {/*   text={'Confirm'}*/}
                    {/*   modifier={'button_accent'}*/}
                    {/*   handleClick={handleClick}*/}
                    {/*/>*/}
                </div>
            </div>
        </>
    );
};
