export const CrossSvg = () => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_40711)">
                <path
                    d="M15.2186 16.0001C15.3731 16.0001 15.5242 15.9543 15.6527 15.8685C15.7812 15.7826 15.8814 15.6606 15.9405 15.5178C15.9997 15.3751 16.0151 15.218 15.985 15.0664C15.9548 14.9148 15.8804 14.7756 15.7711 14.6664L1.3336 0.228853C1.18707 0.0823209 0.988332 0 0.781104 0C0.573876 0 0.375136 0.0823209 0.228604 0.228853C0.0820723 0.375385 -0.000249863 0.574125 -0.000249863 0.781353C-0.000249863 0.988581 0.0820723 1.18732 0.228604 1.33385L14.6661 15.7714C14.7386 15.844 14.8247 15.9016 14.9195 15.9408C15.0143 15.9801 15.116 16.0002 15.2186 16.0001Z"
                    fill="black"
                />
                <path
                    d="M0.781147 16.0001C0.883767 16.0002 0.985403 15.9801 1.08022 15.9408C1.17503 15.9016 1.26116 15.844 1.33365 15.7714L15.7711 1.33385C15.9177 1.18732 16 0.988581 16 0.781353C16 0.574125 15.9177 0.375385 15.7711 0.228853C15.6246 0.0823209 15.4259 0 15.2186 0C15.0114 0 14.8127 0.0823209 14.6661 0.228853L0.228647 14.6664C0.119358 14.7756 0.0449276 14.9148 0.0147705 15.0664C-0.0153866 15.218 8.58307e-05 15.3751 0.0592289 15.5178C0.118372 15.6606 0.218529 15.7826 0.347032 15.8685C0.475534 15.9543 0.626609 16.0001 0.781147 16.0001Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_40711">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="matrix(-1 0 0 1 16 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
