export const Burger = () => {
    return (
        <>
            <svg
                width="30"
                height="15"
                viewBox="0 0 30 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 12H30V15H0V12Z"
                    fill="#1E293A"
                />
                <path
                    d="M0 6H30V9H0V6Z"
                    fill="#1E293A"
                />
                <path
                    d="M0 0H30V3H0V0Z"
                    fill="#1E293A"
                />
            </svg>
        </>
    );
};
