import React, {useEffect, useState} from 'react';
import {Title} from '../../components/Title';
import defaultImage from '../../assets/img/default.jpg';
import styles from './AdminDetailsPage.module.scss';
import {Button} from '../../components/Button';
import {SORT_TYPES} from '../../constants';
import {useDispatch} from 'react-redux';
import {apiRefreshUser, apiUserInfo} from '../../api';
import {Tab} from '../../components/Tab';
import {mock} from './mock';
import {useParams} from 'react-router-dom';
import {AdminGroups} from '../../components/AdminGroups';

const array = [
    {name: 'How to properly catch a crucian carp?', assigned: 'Assigned'},
    {name: 'How to properly catch a crucian carp?', assigned: 'Refused'},
    {name: 'How to properly catch a crucian carp?'},
    {name: 'How to properly catch a crucian carp?'},
    {name: 'How to properly catch a crucian carp?'},
];

export const AdminDetailsPage = () => {
    const [list, setList] = useState(array);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const [component, setComponent] = useState();
    const [user, setUser] = useState('');

    const dispatch = useDispatch();
    const {id} = useParams();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const handleClick = component => {
        setComponent(component);
    };

    const fetchData = async () => {
        const {data} = await apiUserInfo(id);
        setUser(data);
    };

    const renderPages = () => {
        return (() => {
            switch (component) {
                case mock[0].name:
                    return <AdminGroups />;
                default:
                    return null;
            }
        })();
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.container}>
            <Title text={'Admin - Sales Simulator'} />
            <div className={styles.userInfoContainer}>
                <div className={styles.userInfo}>
                    <img
                        className={styles.img}
                        src={defaultImage}
                        alt="avatar"
                    />
                    <div>
                        <div className={styles.userInfo}>
                            <div className={styles.nameText}>
                                <div className={styles.userInfoItem}>Name:</div>
                                <div className={styles.userInfoItem}>Email:</div>
                                <div className={styles.userInfoItem}>Company:</div>
                            </div>
                            <div className={styles.valueText}>
                                <div className={styles.userInfoItem}>
                                    {user.first_name} {user.last_name}
                                </div>
                                <div className={styles.userInfoItem}>{user.email}</div>
                                <div className={styles.userInfoItem}>{user.organization_slug}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        text={'Edit user'}
                        type={'button'}
                        modifier={'button_accent'}
                    />
                </div>
            </div>
            {/*<div className={styles.roleContainer}>*/}
            {/*   <Title text={'Role'}/>*/}
            {/*   <div>*/}
            {/*      <Button text={'Change to admin'} type={'button'}/>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {/*<div className={styles.userContainer}>*/}
            {/*   <Title text={'Users'}/>*/}
            {/*   <div>*/}
            {/*      <Button text={'+Add users'} type={'button'}/>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {/*<div className={styles.line}>*/}
            {/*   <Table*/}
            {/*      pageCount={pageCount}*/}
            {/*      list={list}*/}
            {/*      setSort={setSort}*/}
            {/*      setPage={setPage}*/}
            {/*      typeTables={TYPE_TABLES.userRequest}*/}
            {/*   />*/}
            {/*</div>*/}
            <Tab
                tabs={mock}
                setTab={handleClick}
            />
            {renderPages()}
        </div>
    );
};
