import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Table} from '../Table';

import {SORT_TYPES} from '../../constants';
import {TYPE_TABLES} from '../Table/mock';
import {apiAssignedListQuiz, apiRefreshUser} from '../../api';
import {useAuth} from '../../hooks';

export const AssignedTests = () => {
    const dispatch = useDispatch();
    const {user} = useAuth();

    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async (flag, page) => {
        setIsLoading(true);
        try {
            const {data} = await apiAssignedListQuiz(flag, page, user.id);

            setList(data.results);
            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(sort, page);
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <Table
            isLoading={isLoading}
            pageCount={pageCount}
            list={list}
            setSort={setSort}
            setPage={setPage}
            typeTables={TYPE_TABLES.availableTests}
        />
    );
};
