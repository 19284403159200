export const ArrowLeft = () => {
    return (
        <svg
            width="15"
            height="21"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.749976 5.00002C0.749976 4.90412 0.786632 4.80812 0.859851 4.7349L4.60985 0.984898C4.75638 0.838367 4.99366 0.838367 5.1401 0.984898C5.28654 1.13143 5.28663 1.36871 5.1401 1.51515L1.65523 5.00002L5.1401 8.4849C5.28663 8.63143 5.28663 8.86871 5.1401 9.01515C4.99357 9.16159 4.75629 9.16168 4.60985 9.01515L0.859851 5.26515C0.786632 5.19193 0.749976 5.09593 0.749976 5.00002Z"
                fill="black"
            />
        </svg>
    );
};
