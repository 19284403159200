import {SubTableMobile} from '../../Variations/Mobile';
import {RequestsMobile} from '../../Variations/Mobile/RequestsMobile';
import {UsersInGroupMobile} from '../../Variations/Mobile/UsersInGroupMobile';

export const TableMobileDetails = props => {
    const {toggle, item, typeTables, evaluateValue, questionId, setQuestionId, setEvaluateValue} = props;

    return (
        <>
            {toggle && typeTables?.variations?.subTable && (
                <SubTableMobile
                    list={item}
                    typeTables={typeTables}
                    evaluateValue={evaluateValue}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                    setEvaluateValue={setEvaluateValue}
                />
            )}
            {toggle && typeTables?.variations?.requests && (
                <RequestsMobile
                    list={item}
                    typeTables={typeTables}
                />
            )}
            {toggle && typeTables?.variations?.usersInGroup && (
                <UsersInGroupMobile
                    list={item}
                    typeTables={typeTables}
                />
            )}
        </>
    );
};
