import styles from './CommentsList.module.scss';
import {CommentsItem} from '../CommentsItem';

export const CommentsList = props => {
    const {list, setCommentsList, fetch} = props;
    return (
        <div className={styles.wrapper}>
            {list.map(item => (
                <CommentsItem
                    key={item?.id}
                    item={item}
                    setCommentsList={setCommentsList}
                    fetch={fetch}
                />
            ))}
        </div>
    );
};
