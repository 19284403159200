export const mock = [
    {
        type: 'text',
        name: 'first_name',
        placeholder: 'Write your first name',
        label: 'Name*',
    },
    {
        type: 'text',
        name: 'last_name',
        placeholder: 'Write your last name',
        label: 'Last name*',
    },
    {
        type: 'email',
        name: 'email',
        placeholder: 'Write your E-mail',
        label: 'E-mail*',
    },
];
