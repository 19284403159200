import React from 'react';

import {Timer} from '../Timer';

import styles from './TimerMessage.module.scss';

export const TimerMessage = () => {
    return (
        <div className={styles.timer__block}>
            <Timer />
            <h2>After 5 seconds your mic will start recording</h2>
        </div>
    );
};
