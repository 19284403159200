import styles from './SubTableMobile.module.scss';
import {Modal} from '../../../../Modal';
import React, {useEffect, useRef, useState} from 'react';
import {TableMobileDetailsColum} from '../../../TableMobile/TableMobileDetailsColum';
import {Button} from '../../../../Button';
import {RatingMobile} from '../RatingMobile';
import {Play, StopVoice} from '../../../../../assets/icons';
import {Link, useParams} from 'react-router-dom';
import {useAuth} from '../../../../../hooks';
import {apiUpdateComments} from '../../../../../api';
import {CommentsList} from '../../../../CommentsList';

export const SubTableMobile = props => {
    const {typeTables, list, evaluateValue, questionId, setQuestionId, setEvaluateValue} = props;

    const [showModal, setShowModal] = useState(false);
    const [video, setVideo] = useState(null);
    const [showRating, setShowRating] = useState(evaluateValue);
    const [commentsList, setCommentsList] = useState([]);
    const [play, setPlay] = useState(true);
    const audio = useRef(null);
    const {id: user_id, test_id} = useParams();
    const {user} = useAuth();

    const handleOpenVideo = video => {
        setVideo(video);

        setShowModal(true);
    };

    const handlePlay = (audio, setPlay) => {
        audio.current.play();

        audio.current.onended = function () {
            setPlay(prev => !prev);
        };

        setPlay(prev => !prev);
    };
    const fetchComments = async () => {
        const data = await apiUpdateComments(user_id, test_id);
        data?.data.map(i => {
            if (i.id === list.id) {
                setCommentsList([i]);
            }
        });
    };
    const handlePause = (audio, setPlay) => {
        audio.current.pause();

        setPlay(prev => !prev);
    };

    const handleEvaluate = () => {
        setShowRating(prev => !prev);
    };

    useEffect(() => {
        if (user_id) {
            fetchComments();
        }
    }, []);

    return (
        <>
            <div className={styles.body}>
                {typeTables?.date && (
                    <div className={styles.info}>
                        <span>Date</span>
                        <span>{list?.updated_at}</span>
                    </div>
                )}
                {typeTables?.actions && (
                    <div className={styles.info}>
                        <span>Assigned users</span>
                        <span>2</span>
                    </div>
                )}
                {typeTables?.group && (
                    <div className={styles.info}>
                        <span>Group</span>
                        <span>Private test</span>
                    </div>
                )}
                {typeTables?.admin && (
                    <div className={styles.info}>
                        <span>Admin</span>
                        <span>{list?.admin_name}</span>
                    </div>
                )}
                {typeTables?.admin && (
                    <div className={styles.info}>
                        <span>Score</span>
                        <span>{list?.score}</span>
                    </div>
                )}
                {typeTables?.type?.evaluateTest && (
                    <div className={styles.info}>
                        <span>Test details</span>
                        <span
                            className={`${styles.changeRating}`}
                            data-wrapper="wrapper"
                        >
                            <Link
                                className={styles.evaluate}
                                to={`/user-checked/${user_id ? user_id : user.id}/${list.id}`}
                            >
                                Details
                            </Link>
                        </span>
                    </div>
                )}
                {typeTables?.score && (
                    <div className={`${styles.info} ${styles.info_rating}`}>
                        <div className={styles.box}>
                            <span className={styles.score}>Score</span>
                            <span>
                                {typeTables?.variations?.rating ? (
                                    <>
                                        {evaluateValue ? (
                                            <span>{evaluateValue ? evaluateValue : 0}/10</span>
                                        ) : (
                                            <Button
                                                text={'To evaluate'}
                                                type={'button'}
                                                modifier={'button_addTest'}
                                                handleClick={handleEvaluate}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <span>
                                        {list?.evaluated ? (
                                            <span className={styles.changeRating_link}>{`${list?.score}/10`}</span>
                                        ) : (
                                            <Link
                                                className={styles.evaluate}
                                                to={`${list?.id}`}
                                            >
                                                To evaluate
                                            </Link>
                                        )}
                                    </span>
                                )}
                            </span>
                        </div>
                        {showRating && (
                            <RatingMobile
                                disabled={true}
                                item={list}
                                evaluateValue={evaluateValue}
                                questionId={questionId}
                                setQuestionId={setQuestionId}
                                setEvaluateValue={setEvaluateValue}
                                setShowRating={setShowRating}
                            />
                        )}
                    </div>
                )}
                {commentsList.length > 0 &&
                    (commentsList[0].comments.audio.length > 0 ||
                    commentsList[0].comments.text.length > 0 ||
                    commentsList[0].comments.video.length > 0 ? (
                        <CommentsList list={commentsList} />
                    ) : null)}
                {typeTables?.question && (
                    <div className={`${styles.info} ${styles.video} ${styles.answer}`}>
                        <span>Question</span>
                        <button
                            onClick={() => handleOpenVideo(list?.question_link)}
                            type={'button'}
                        >
                            <Play />
                        </button>
                    </div>
                )}
                {typeTables?.rightAnswer && (
                    <div className={`${styles.info} ${styles.video} ${styles.answer} ${styles.answer_right}`}>
                        <span>Right answer</span>
                        <button
                            onClick={() => handleOpenVideo(list?.question_answer)}
                            type={'button'}
                        >
                            <Play />
                        </button>
                    </div>
                )}
                {typeTables?.userAnswer && (
                    <div className={`${styles.info} ${styles.answer} ${styles.answer_user}`}>
                        <span>User’s answer</span>
                        <audio
                            className={styles.audio}
                            controls
                            src={list?.answers?.answer}
                            ref={audio}
                        ></audio>
                        {play ? (
                            <button
                                className={`${styles.button} ${styles.button_play}`}
                                type={'button'}
                                onClick={() => handlePlay(audio, setPlay)}
                            >
                                <Play />
                            </button>
                        ) : (
                            <button
                                className={`${styles.button} ${styles.button_pause}`}
                                type={'button'}
                                onClick={() => handlePause(audio, setPlay)}
                            >
                                <StopVoice />
                            </button>
                        )}
                    </div>
                )}
                {showModal && (
                    <Modal
                        video={true}
                        setShowModal={setShowModal}
                        videoLink={video}
                    />
                )}
                {list?.questions?.length > 0 &&
                    list?.questions.map((elem, index) => {
                        const quantity = index + 1;
                        return (
                            <TableMobileDetailsColum
                                key={index}
                                quantity={quantity}
                                elem={elem}
                                typeTables={typeTables}
                                handleOpenVideo={handleOpenVideo}
                                handlePlay={handlePlay}
                                handlePause={handlePause}
                            />
                        );
                    })}
            </div>
        </>
    );
};
