export const InfoSvg = ({width, height}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.66634 6.00236C7.29834 6.00236 6.99968 5.70303 6.99968 5.33569C6.99968 4.96836 7.29834 4.66903 7.66634 4.66903C8.03434 4.66903 8.33301 4.96836 8.33301 5.33569C8.33301 5.70303 8.03434 6.00236 7.66634 6.00236ZM8.33301 10.669H6.99968C6.81568 10.669 6.66634 10.5197 6.66634 10.3357C6.66634 10.1517 6.81568 10.0024 6.99968 10.0024H7.33301V7.33569H6.99968C6.81568 7.33569 6.66634 7.18636 6.66634 7.00236C6.66634 6.81836 6.81568 6.66903 6.99968 6.66903H7.66634C7.85034 6.66903 7.99968 6.81836 7.99968 7.00236V10.0024H8.33301C8.51701 10.0024 8.66634 10.1517 8.66634 10.3357C8.66634 10.5197 8.51701 10.669 8.33301 10.669ZM7.66634 2.00236C4.54167 2.00236 1.99967 4.54436 1.99967 7.66903C1.99967 10.7937 4.54167 13.3357 7.66634 13.3357C10.791 13.3357 13.333 10.7937 13.333 7.66903C13.333 4.54436 10.791 2.00236 7.66634 2.00236ZM7.66634 14.0024C4.17434 14.0024 1.33301 11.161 1.33301 7.66903C1.33301 4.17703 4.17434 1.33569 7.66634 1.33569C11.1583 1.33569 13.9997 4.17703 13.9997 7.66903C13.9997 11.161 11.1583 14.0024 7.66634 14.0024Z"
                fill="#2CA6E7"
            />
        </svg>
    );
};
