import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Title} from '../../components/Title';
import {Button} from '../../components/Button';
import {Table} from '../../components/Table';
import {Footer} from '../../components/Footer';

import {apiEditGroup, apiGroups, apiRefreshUser} from '../../api';
import {useAuth} from '../../hooks';
import {ROUTS, SORT_TYPES} from '../../constants';
import {TYPE_TABLES} from '../../components/Table/mock';

import styles from './GroupsListPage.module.scss';

export const GroupsListPage = () => {
    const user = useSelector(state => state.user.user.organization_slug);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchData = async (flag, page) => {
        setIsLoading(true);
        try {
            const {data} = await apiGroups(flag, page, user);
            setData(data?.results);

            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDeleteAndUpdateList = async id => {
        setIsLoading(true);
        try {
            await apiEditGroup(id, 'DELETE');
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClick = () => {
        navigate(ROUTS.groupCreate);
    };

    useEffect(() => {
        fetchData(sort, page);
    }, [sort, page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <>
            <div className={styles.containerPage}>
                <div className={styles.wrapperPage}>
                    <Header isLoggedIn={isLoggedIn} />
                    <main className={styles.main}>
                        <UserHeader />
                        <div className={styles.container}>
                            <Title text={'Groups'} />
                            <div className={styles.wrapper}>
                                <div className={styles.select}>
                                    {/*<label className={`${styles.label} ${styles.label_search}`}>*/}
                                    {/*   {isShow ? 'Search group name' : null}*/}
                                    {/*   <div className={styles.inputBox}>*/}
                                    {/*      <input*/}
                                    {/*         className={styles.input}*/}
                                    {/*         type={'text'}*/}
                                    {/*         placeholder={'Search'}*/}
                                    {/*      />*/}
                                    {/*      <Search/>*/}
                                    {/*   </div>*/}
                                    {/*</label>*/}
                                </div>
                                <Button
                                    text={'Add new'}
                                    modifier={'button_question'}
                                    handleClick={handleClick}
                                />
                            </div>
                            <Table
                                isLoading={isLoading}
                                pageCount={pageCount}
                                list={data}
                                setPage={setPage}
                                setSort={setSort}
                                typeTables={TYPE_TABLES.groupsList}
                                fetch={fetchDeleteAndUpdateList}
                            />
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </>
    );
};
