import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {apiRefreshUser} from '../../api';
import {mock} from './mock';

import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Footer} from '../../components/Footer';
import {Title} from '../../components/Title';
import {NeedEvaluation} from '../../components/NeedEvaluation';
import {UserRequests} from '../../components/UserRequests';
import {RegisterRequests} from '../../components/RegisterRequests';
import {Tab} from '../../components/Tab';

import styles from './DashboardPage.module.scss';

export const SeniorDashboardPage = () => {
    const [component, setComponent] = useState();
    const dispatch = useDispatch();

    const handleClick = component => {
        setComponent(component);
    };

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    const renderPages = () => {
        return (() => {
            switch (component) {
                case mock[0].name:
                    return <NeedEvaluation />;
                case mock[1].name:
                    return <RegisterRequests />;
                default:
                    return null;
            }
        })();
    };

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={true} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={'Dashboard'} />
                        <Tab
                            tabs={mock}
                            setTab={handleClick}
                        />
                        {renderPages()}
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
