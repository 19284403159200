export const Dashboard = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6483 22.5H14.6017C13.581 22.5 12.75 21.669 12.75 20.6483V13.1017C12.75 12.081 13.581 11.25 14.6017 11.25H20.6483C21.669 11.25 22.5 12.081 22.5 13.1017V20.6483C22.5 21.669 21.669 22.5 20.6483 22.5ZM14.6017 12.75C14.4075 12.75 14.25 12.9075 14.25 13.1017V20.6483C14.25 20.8425 14.4075 21 14.6017 21H20.6483C20.8425 21 21 20.8425 21 20.6483V13.1017C21 12.9075 20.8425 12.75 20.6483 12.75H14.6017Z"
                fill="#070806"
            />
            <path
                d="M20.6483 9.75H14.6017C13.581 9.75 12.75 8.919 12.75 7.89825V3.35175C12.75 2.331 13.581 1.5 14.6017 1.5H20.6483C21.669 1.5 22.5 2.331 22.5 3.35175V7.89825C22.5 8.919 21.669 9.75 20.6483 9.75ZM14.6017 3C14.4075 3 14.25 3.1575 14.25 3.35175V7.89825C14.25 8.0925 14.4075 8.25 14.6017 8.25H20.6483C20.8425 8.25 21 8.0925 21 7.89825V3.35175C21 3.1575 20.8425 3 20.6483 3H14.6017Z"
                fill="#070806"
            />
            <path
                d="M9.39825 12.75H3.35175C2.331 12.75 1.5 11.919 1.5 10.8983V3.35175C1.5 2.331 2.331 1.5 3.35175 1.5H9.39825C10.419 1.5 11.25 2.331 11.25 3.35175V10.8983C11.25 11.919 10.419 12.75 9.39825 12.75ZM3.35175 3C3.1575 3 3 3.1575 3 3.35175V10.8983C3 11.0925 3.1575 11.25 3.35175 11.25H9.39825C9.5925 11.25 9.75 11.0925 9.75 10.8983V3.35175C9.75 3.1575 9.5925 3 9.39825 3H3.35175Z"
                fill="#070806"
            />
            <path
                d="M9.39825 22.5H3.35175C2.331 22.5 1.5 21.669 1.5 20.6483V16.1017C1.5 15.081 2.331 14.25 3.35175 14.25H9.39825C10.419 14.25 11.25 15.081 11.25 16.1017V20.6483C11.25 21.669 10.419 22.5 9.39825 22.5ZM3.35175 15.75C3.1575 15.75 3 15.9075 3 16.1017V20.6483C3 20.8425 3.1575 21 3.35175 21H9.39825C9.5925 21 9.75 20.8425 9.75 20.6483V16.1017C9.75 15.9075 9.5925 15.75 9.39825 15.75H3.35175Z"
                fill="#070806"
            />
        </svg>
    );
};
