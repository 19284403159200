import React, {useEffect, useState} from 'react';

import {QUIZ_COMPONENTS} from '../../constants';
import {assignTest} from 'api';

import {Button} from '../Button';
import {Player} from '../Player';

import styles from './UserStartVideo.module.scss';

export const UserStartVideo = props => {
    const {question_link, handleClick, component} = props;
    const test = sessionStorage.getItem('test');

    const [buttonText, setButtonText] = useState('Skip the intro');

    useEffect(() => {
        if (test) {
            assignTest({test: test});
            sessionStorage.clear();
        }
    }, [test]);

    return (
        <div className={styles.videoWrap}>
            <div className={styles.start__player}>
                <Player
                    setButtonText={setButtonText}
                    fullVideo={false}
                    controls={true}
                    video={question_link}
                    component={component}
                />
            </div>
            <div>
                <Button
                    nameClass={'button__start'}
                    text={question_link ? buttonText : 'Start test'}
                    handleClick={() => {
                        handleClick(QUIZ_COMPONENTS.test_video);
                    }}
                />
            </div>
        </div>
    );
};
