import * as Yup from 'yup';

const baseEmailSchema = Yup.string()
    .trim()
    .email('Must be a valid email')
    .min(1, 'Minimum 1 character')
    .max(64, 'Maximum 64 characters');

export const validationSchema = {
    first_name: Yup.string().required('Required').trim().min(1, 'minimum 1 character').max(64, 'maximum 64 characters'),
    last_name: Yup.string().required('Required').trim().min(1, 'minimum 1 character').max(64, 'maximum 64 characters'),
    name: Yup.string().required('Required').trim().min(1, 'minimum 1 character').max(64, 'maximum 64 characters'),
    copy: Yup.string().required('Required'),
    description: Yup.string().required('Required').trim(),
    questions: Yup.array().of(
        Yup.object().shape({
            question_name: Yup.string()
                .required('Required')
                .trim()
                .min(1, 'minimum 1 character')
                .max(64, 'maximum 64 characters'),
            question_link: Yup.string()
                .required('Required')
                .min(1, 'minimum 1 character')
                .max(200, 'maximum 200 characters'),
            question_answer: Yup.string()
                .required('Required')
                .min(1, 'minimum 1 character')
                .max(200, 'maximum 200 characters'),
        })
    ),
    //Personal information
    email: baseEmailSchema.required('Required'),
    //change password
    old_password: Yup.string()
        .required('Required')
        .trim()
        .min(1, 'minimum 1 character')
        .max(64, 'maximum 64 characters'),
    new_password: Yup.string()
        .required('Required')
        .trim()
        .min(1, 'minimum 1 character')
        .max(64, 'maximum 64 characters'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match!")
        .required('Required')
        .trim()
        .min(1, 'minimum 1 character')
        .max(64, 'maximum 64 characters'),
    //login
    password: Yup.string().required('Required').trim(),
    //reset password
    code_email: Yup.string().required('Required').trim().min(6, 'minimum 6 characters').max(6, 'maximum 6 characters'),
    // commit
    commitQuestion: Yup.string().required('Required'),
    commitVideo: Yup.string().required('Required').min(1, 'minimum 1 character').max(200, 'maximum 200 characters'),
    commitAudio: Yup.string().required('Required'),
    // create company
    senior_admin: Yup.object().shape({
        email: Yup.string()
            .required('Required')
            .email('must be a valid email')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        first_name: Yup.string()
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        last_name: Yup.string()
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        password: Yup.string()
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords don't match!")
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
    }),
    senior_admin_edit: Yup.object().shape({
        email: Yup.string()
            .required('Required')
            .email('must be a valid email')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        first_name: Yup.string()
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
        last_name: Yup.string()
            .required('Required')
            .trim()
            .min(1, 'minimum 1 character')
            .max(64, 'maximum 64 characters'),
    }),
    // create group
    organization: Yup.number().required('Organisation is required'),
    admin: Yup.number().required('Admin is required'),
    user_ids: Yup.array().of(Yup.number()).required('At least one user is required'),
    test_ids: Yup.array().of(Yup.number()).required('At least one user is required'),
    companyName: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    company_email: baseEmailSchema,
    copyright: Yup.string(),
    logo: Yup.mixed().nullable(),
    social_media: Yup.array().of(
        Yup.object({
            title: Yup.string().required('Platform name is required'),
            link: Yup.string().url('Must be a valid URL').required('Link is required'),
        })
    ),
};
