import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';

import {loggedInSeniorAdmin, loggedInSuperAdmin, loggedInUser, notLoggedIn} from './mock';
import {ROUTS} from '../../constants';
import {apiRefreshUser} from '../../api';

import styles from './Navigation.module.scss';

export const Navigation = ({isLoggedIn = false, isOpen}) => {
    const dispatch = useDispatch();
    const test = useSelector(state => state.user.user.type);

    const renderPages = () => {
        return (() => {
            switch (test) {
                case 'User':
                    return loggedInUser(ROUTS).map((item, index) => (
                        <li key={index}>
                            <NavLink
                                className={({isActive}) =>
                                    isActive
                                        ? `${styles.active} ${styles.active_loggedIn}`
                                        : `${styles.inactive} ${styles.inactive_loggedIn}`
                                }
                                to={item?.link}
                            >
                                {item?.svg}
                                <span>{item?.navigate}</span>
                            </NavLink>
                        </li>
                    ));
                case 'Senior Admin':
                    return loggedInSeniorAdmin(ROUTS).map((item, index) => (
                        <li key={index}>
                            <NavLink
                                className={({isActive}) =>
                                    isActive
                                        ? `${styles.active} ${styles.active_loggedIn}`
                                        : `${styles.inactive} ${styles.inactive_loggedIn}`
                                }
                                to={item?.link}
                            >
                                {item?.svg}
                                <span>{item?.navigate}</span>
                            </NavLink>
                        </li>
                    ));
                case 'Super Admin':
                    return loggedInSuperAdmin(ROUTS).map((item, index) => (
                        <li key={index}>
                            <NavLink
                                className={({isActive}) =>
                                    isActive
                                        ? `${styles.active} ${styles.active_loggedIn}`
                                        : `${styles.inactive} ${styles.inactive_loggedIn}`
                                }
                                to={item?.link}
                            >
                                {item?.svg}
                                <span>{item?.navigate}</span>
                            </NavLink>
                        </li>
                    ));
                default:
                    return null;
            }
        })();
    };

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <>
            {isOpen && (
                <div
                    className={
                        isLoggedIn
                            ? `${styles.wrapper} ${styles.wrapper_loggedIn}`
                            : `${styles.wrapper} ${styles.wrapper_notLoggedIn}`
                    }
                >
                    <nav
                        className={
                            isLoggedIn
                                ? `${styles.nav} ${styles.nav_loggedIn}`
                                : `${styles.nav} ${styles.nav_notLoggedIn}`
                        }
                    >
                        {isLoggedIn ? (
                            <ul>{renderPages()}</ul>
                        ) : (
                            <ul>
                                {notLoggedIn(ROUTS).map((item, index) => (
                                    <li key={index}>
                                        <Link to={item?.link}>{item?.navigate}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </nav>
                    {isLoggedIn ? null : (
                        <div className={styles.buttons}>
                            <div className={styles.button}>
                                <Link to={ROUTS.login}>Sign In</Link>
                            </div>
                            <div className={styles.button}>
                                <Link to={ROUTS.register}>Sign Up</Link>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
