import styles from './Header.module.scss';
import {Link} from 'react-router-dom';
import {Logo} from '../../assets/icons/Logo/Logo';
import {Navigation} from '../Navigation';
import React, {useEffect, useState} from 'react';
import {Burger, CrossSvg} from '../../assets/icons';
import {DEVICE, ROUTS} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import {getCompany} from 'api';

export const Header = ({isLoggedIn}) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(window.innerWidth >= DEVICE.desktop);
    const company = useSelector(state => state.company.company);

    const handleClick = () => {
        setIsOpen(prev => !prev);
    };

    useEffect(() => {
        let timeoutId;

        function handleResize() {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                setIsOpen(window.innerWidth >= DEVICE.desktop);
            }, 200);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth >= DEVICE.desktop) {
            setIsOpen(true);
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth <= DEVICE.mobile) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        dispatch(getCompany());
    }, []);

    return (
        <header
            className={
                isLoggedIn
                    ? `${styles.header} ${styles.header_loggedIn}`
                    : `${styles.header} ${styles.header_notLoggedIn}`
            }
        >
            <Link to={'/'}>
                <Logo logo={company?.logo} />
            </Link>
            <Navigation
                isLoggedIn={isLoggedIn}
                isOpen={isOpen}
            />
            {isLoggedIn ? null : (
                <div className={styles.buttons}>
                    <div className={styles.button}>
                        <Link to={ROUTS.login}>Sign In</Link>
                    </div>
                    <div className={styles.button}>
                        <Link to={ROUTS.register}>Sign Up</Link>
                    </div>
                </div>
            )}
            <button
                type={'button'}
                onClick={handleClick}
            >
                {isOpen ? <CrossSvg /> : <Burger />}
            </button>
        </header>
    );
};
