import React, {useState} from 'react';

import {Button} from '../Button';
import {Player} from '../Player';

import styles from './AnswerToQuestion.module.scss';

export const AnswerToQuestion = props => {
    const {handleClick, answerLink, textButton, component} = props;
    return (
        <div className={styles.videoWrapper}>
            <Player
                video={answerLink}
                fullVideo={true}
                controls={true}
                component={component}
            />

            <Button
                text={textButton}
                type={'button'}
                nameClass="button__nextQuestion"
                handleClick={handleClick}
            />
        </div>
    );
};
