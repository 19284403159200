export const EyeShowIcon = () => {
    return (
        <svg
            height="12"
            viewBox="0 0 24 24"
            width="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#949494"
        >
            <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
            <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
        </svg>
    );
};
