import styles from './TableMobile.module.scss';
import {Paginate} from '../../Paginate';
import {TableMobileItem} from './TableMobileItem';

export const TableMobile = props => {
    const {pageCount, list, setList, setPage, typeTables, fetch} = props;
    return (
        <>
            <div className={styles.header}>
                <span className={styles.id}>№</span>
                <span>{typeTables?.text?.name ? typeTables?.text?.name : 'Name'}</span>
            </div>

            <div className={styles.list}>
                {list.length > 0 &&
                    list.map((item, index) => {
                        return (
                            <TableMobileItem
                                key={item?.id}
                                id={item?.id}
                                position={item?.index ? item?.index : index + 1}
                                update={setList}
                                item={item}
                                typeTables={typeTables}
                                fetch={fetch}
                            />
                        );
                    })}
                {pageCount > 1 && (
                    <Paginate
                        pageCount={pageCount}
                        setPage={setPage}
                    />
                )}
            </div>
        </>
    );
};
