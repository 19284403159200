export const TYPE_TABLES = {
    // table CompaniesListPage
    companies: {
        toggle: false, // to open under tables
        name: true,
        superAdminName: true,
        users: true,
        actions: true,
        type: {
            edit: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 40%',
                sort: false,
            },
            superAdminName: {
                flex: '0 1 25%',
            },
            users: {
                flex: '0 1 10%',
                sort: false,
            },
            actions: {
                flex: '0 1 20%',
                sort: false,
            },
        },
        // structure sub table
        sizeQuestions: {
            //should be 100%
            number: {
                flex: '0 1 10%',
            },
            name: {
                flex: '0 1 45%',
            },
            email: {
                flex: '0 1 45%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Company',
            users: 'Users',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Test name',
        },
        // variations what to show when 'opening' row
        variations: {
            usersInGroup: true,
        },
    },
    // table GroupsListPage
    groupsList: {
        toggle: false, // to open under tables
        name: true,
        admin: true,
        users: true,
        actions: true,
        type: {
            edit: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 40%',
                sort: true,
            },
            admin: {
                flex: '0 1 25%',
            },
            users: {
                flex: '0 1 10%',
                sort: false,
            },
            actions: {
                flex: '0 1 20%',
                sort: false,
            },
        },
        // structure sub table
        sizeQuestions: {
            //should be 100%
            number: {flex: '0 1 10%'},
            name: {flex: '0 1 45%'},
            email: {flex: '0 1 45%'},
        },
        // text in TableDesktop_header
        text: {
            name: 'Group name',
            users: 'Users',
        },
        // text in  SubTable_header
        textQuestions: {
            name: "User's name",
        },
        // variations what to show when 'opening' row
        variations: {
            usersInGroup: true,
        },
    },
    // list-test page table TestsListPage
    listQuiz: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        // group: true,
        // assigned: true,
        actions: true,
        type: {
            edit: true,
            copy: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 18%',
            },
            name: {
                flex: '0 1 37%',
                sort: true,
            },
            date: {
                flex: '0 1 20%',
                sort: true,
            },
            // group: {
            //    flex: '0 1 10%',
            // },
            // assigned: {
            //    flex: '0 1 15%',
            //    sort: false,
            // },
            actions: {
                flex: '0 1 20%',
                sort: false,
            },
        },
        sizeQuestions: {
            //should be 100%
            number: {
                flex: '0 1 10%',
            },
            name: {
                flex: '0 1 50%',
            },
            questions: {
                flex: '0 1 20%',
            },
            rightAnswer: {
                flex: '0 1 20%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            group: 'Group',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            subTable: true,
        },
    },
    // dashboard page table DashboardPage
    user: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        admin: true,
        score: true,
        actions: false,
        // content type inside the cell
        type: {
            score: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 40%',
                sort: false,
            },
            date: {
                flex: '0 1 17%',
                sort: false,
            },
            admin: {
                flex: '0 1 15%',
                sort: true,
            },
            score: {
                flex: '0 1 15%',
                sort: true,
            },
        },
        sizeQuestions: {
            //should be 100%
            number: {
                flex: '0 1 10%',
            },
            name: {
                flex: '0 1 30%',
            },
            questions: {
                flex: '0 1 15%',
            },
            rightAnswer: {
                flex: '0 1 15%',
            },
            userAnswer: {
                flex: '0 1 15%',
            },
            score: {
                flex: '0 1 15%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            subTable: true,
        },
    },
    proveUser: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        admin: true,
        score: true,
        actions: false,
        // content type inside the cell
        type: {
            evaluateTest: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 40%',
                sort: false,
            },
            date: {
                flex: '0 1 17%',
                sort: false,
            },
            admin: {
                flex: '0 1 15%',
                sort: true,
            },
            score: {
                flex: '0 1 15%',
                sort: true,
            },
        },
        sizeQuestions: {
            //should be 100%
            number: {
                flex: '0 1 10%',
            },
            name: {
                flex: '0 1 30%',
            },
            questions: {
                flex: '0 1 15%',
            },
            rightAnswer: {
                flex: '0 1 15%',
            },
            userAnswer: {
                flex: '0 1 15%',
            },
            score: {
                flex: '0 1 15%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            subTable: true,
        },
    },
    // Users page table SingleQuizPage
    commitUserAnswer: {
        toggle: false, // to open under tables
        name: true,
        question: true,
        rightAnswer: true,
        userAnswer: true,
        score: true,
        // content type inside the cell
        type: {
            evaluate: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 34%',
            },
            question: {
                flex: '0 1 12%',
            },
            rightAnswer: {
                flex: '0 1 12%',
            },
            userAnswer: {
                flex: '0 1 13%',
            },
            score: {
                flex: '0 1 24%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name of the question',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            rating: true,
            subTable: true,
        },
    },
    seniorAdmin: {
        toggle: true, // to open under tables
        name: true,
        email: false,
        phone: false,
        date: false,
        admin: true,
        group: true,
        actions: true,
        type: {
            access: true,
            // evaluate: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 12%',
                justifyContent: 'center',
                padding: 0,
            },
            admin: {
                flex: '0 1 22%',
                justifyContent: 'center',
                padding: 0,
                sort: false,
            },
            group: {
                flex: '0 1 22%',
                justifyContent: 'center',
                padding: 0,
                sort: false,
            },
            actions: {
                flex: '0 1 39%',
                justifyContent: 'center',
                padding: 0,
                sort: false,
            },
        },
        sizeQuestions: {
            //should be 100%
            email: {
                flex: '0 1 35%',
                justifyContent: 'center',
            },
            phone: {
                flex: '0 1 35%',
                justifyContent: 'center',
            },
            date: {
                flex: '0 1 30%',
                justifyContent: 'center',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
        },
        variations: {
            requests: true,
        },
    },
    // single-quiz page table SingleQuizPage
    listTest: {
        toggle: false, // to open under tables
        photo: true,
        name: true,
        question: true,
        rightAnswer: true,
        userAnswer: true,
        score: true,
        // content type inside the cell
        type: {
            evaluate: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 30%',
            },
            question: {
                flex: '0 1 12%',
            },
            rightAnswer: {
                flex: '0 1 12%',
            },
            userAnswer: {
                flex: '0 1 13%',
            },
            score: {
                flex: '0 1 20%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name of the question',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            rating: true,
        },
    },
    availableTests: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        group: false,
        score: true,
        type: {
            startTest: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 32%',
                sort: true,
            },
            date: {
                flex: '0 1 18%',
                sort: true,
            },
            group: {
                flex: '0 1 15%',
            },
            score: {
                flex: '0 1 36%',
                sort: false,
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            group: 'Group',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
    },
    checkedTests: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        group: true,
        score: true,
        type: {
            detailTest: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 32%',
                sort: true,
            },
            date: {
                flex: '0 1 18%',
                sort: true,
            },
            group: {
                flex: '0 1 15%',
            },
            score: {
                flex: '0 1 21%',
                sort: false,
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            group: 'Group',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
    },
    onEvaluateTests: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        date: true,
        group: true,
        score: true,
        type: {
            onEvaluate: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 32%',
                sort: true,
            },
            date: {
                flex: '0 1 18%',
                sort: true,
            },
            group: {
                flex: '1 1 15%',
            },
            score: {
                flex: '0 1 21%',
                sort: false,
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            group: 'Group',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
    },
    userRequest: {
        name: true,
        score: true,
        type: {
            userRequest: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 70%',
                sort: true,
            },
            score: {
                flex: '0 1 25%',
                sort: false,
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            group: 'Group',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
    },
    users: {
        toggle: false, // to open under tables
        photo: true,
        name: true,
        email: true,
        assignTests: true,
        group: true,
        role: true,
        actions: true,
        type: {
            edit: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 6%',
                sort: false,
            },
            name: {
                flex: '0 1 20%',
                sort: true,
            },
            email: {
                flex: '0 1 22%',
                sort: false,
            },
            role: {
                flex: '0 1 9%',
            },
            assignTests: {
                flex: '0 1 9%',
            },
            group: {
                flex: '0 1 20%',
                sort: false,
            },
            actions: {
                flex: '0 1 18%',
                sort: false,
            },
        },
        // structure sub table
        sizeQuestions: {
            //should be 100%
            number: {flex: '0 1 10%'},
            name: {flex: '0 1 45%'},
            email: {flex: '0 1 45%'},
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
            users: 'Users',
        },
        // text in  SubTable_header
        textQuestions: {
            name: "User's name",
        },
        // variations what to show when 'opening' row
        variations: {
            usersInGroup: true,
        },
    },
    needEvaluation: {
        toggle: true, // to open under tables
        photo: true,
        name: true,
        group: true,
        numberTests: true,
        score: true,
        actions: false,
        // content type inside the cell
        type: {
            needEvaluation: true,
        },
        size: {
            //should be 95%
            photo: {
                flex: '0 1 8%',
            },
            name: {
                flex: '0 1 40%',
                sort: false,
            },
            group: {
                flex: '0 1 17%',
                sort: false,
            },
            numberTests: {
                flex: '0 1 15%',
                sort: true,
            },
            score: {
                flex: '0 1 15%',
                sort: true,
            },
        },
        sizeQuestions: {
            //should be 100%
            number: {
                flex: '0 1 10%',
            },
            name: {
                flex: '0 1 30%',
            },
            questions: {
                flex: '0 1 15%',
            },
            rightAnswer: {
                flex: '0 1 15%',
            },
            userAnswer: {
                flex: '0 1 15%',
            },
            score: {
                flex: '0 1 15%',
            },
        },
        // text in TableDesktop_header
        text: {
            name: 'Name',
        },
        // text in  SubTable_header
        textQuestions: {
            name: 'Name of the question',
        },
        // variations what to show when 'opening' row
        variations: {
            subTable: false,
        },
    },
    adminGroupsList: {
        toggle: false, // to open under tables
        name: true,
        admin: true,
        users: true,
        actions: true,
        type: {
            editAdmins: true,
        },
        size: {
            //should be 95%
            name: {
                flex: '0 1 40%',
                sort: false,
            },
            admin: {
                flex: '0 1 25%',
            },
            users: {
                flex: '0 1 10%',
                sort: false,
            },
            actions: {
                flex: '0 1 20%',
                sort: false,
            },
        },
        // structure sub table
        sizeQuestions: {
            //should be 100%
            number: {flex: '0 1 10%'},
            name: {flex: '0 1 45%'},
            email: {flex: '0 1 45%'},
        },
        // text in TableDesktop_header
        text: {
            name: 'Group name',
            users: 'Users',
        },
        // text in  SubTable_header
        textQuestions: {
            name: "User's name",
        },
        // variations what to show when 'opening' row
        variations: {
            usersInGroup: true,
        },
    },
};
