import styles from './VoiceRecorderComment.module.scss';
import {Play, StopVoice} from '../../assets/icons';
import {useEffect, useState} from 'react';
import {AUDIO_PERMISSION_MSG} from '../../constants';

export const VoiceRecorderComment = props => {
    const {handleStartRecorder, handleStopRecorder, formProps} = props;

    const [isPlay, setIsPlay] = useState(true);
    const [error, setError] = useState(null);
    const [stream, setStream] = useState(null);

    const errorMessage = () => {
        if (
            error === AUDIO_PERMISSION_MSG.safari_refuse_microphone ||
            error === AUDIO_PERMISSION_MSG.chrome_refuse_microphone ||
            error === AUDIO_PERMISSION_MSG.firefox_refuse_microphone
        ) {
            return AUDIO_PERMISSION_MSG.allow_microphone;
        } else if (
            error === AUDIO_PERMISSION_MSG.chrome_system_microphone ||
            error === AUDIO_PERMISSION_MSG.firefox_system_microphone
        ) {
            return AUDIO_PERMISSION_MSG.allow_system_microphone;
        } else if (
            error === AUDIO_PERMISSION_MSG.chrome_headphones_allow ||
            error === AUDIO_PERMISSION_MSG.firefox_headphones_allow ||
            error === AUDIO_PERMISSION_MSG.safari_headphones_allow ||
            error === AUDIO_PERMISSION_MSG.firefox_headphones_allow_another_option
        ) {
            return AUDIO_PERMISSION_MSG.allow_headphones;
        } else {
            return error;
        }
    };

    const getUserMedia = async () => {
        try {
            setStream(await navigator.mediaDevices.getUserMedia({audio: true}));
        } catch (error) {
            setError(error.toString());
        }
    };

    useEffect(() => {
        if (stream) return;

        getUserMedia();
    }, []);

    return (
        <>
            {isPlay ? (
                <div className={styles.wrapper}>
                    <button
                        className={styles.play}
                        type={'button'}
                        onClick={() => {
                            handleStartRecorder();
                            setIsPlay(prev => !prev);
                        }}
                    >
                        <Play />
                    </button>
                    <span className={styles.text}>Start recording your voice commentary</span>
                    <span className={styles.error}>{errorMessage()}</span>
                </div>
            ) : (
                <div className={styles.wrapper}>
                    <button
                        className={styles.stop}
                        type={'button'}
                        onClick={() => {
                            handleStopRecorder(formProps);
                            setIsPlay(prev => !prev);
                        }}
                    >
                        <StopVoice />
                    </button>
                </div>
            )}
        </>
    );
};
