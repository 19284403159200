import React from 'react';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';

import {Footer} from '../../components/Footer';
import {Header} from '../../components/Header';
import {Title} from '../../components/Title';
import {Input} from '../../components/Input';
import {Button} from '../../components/Button';

import {validationSchema, userAuthRedirect} from '../../utils';
import {apiLogIn} from '../../api';
import {ROUTS} from '../../constants';

import styles from './LoginPage.module.scss';

export const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async values => {
        dispatch(apiLogIn(values));
        userAuthRedirect(navigate);
    };

    const handleForgotPassword = values => {
        const saveEmail = values?.email;

        navigate(ROUTS.resetPassword, {state: {saveEmail}});
    };

    // TODO коли нажали "запамятати мене" задаєм якийсь термін токену при запису в локалСтор

    const initialValues = {
        email: '',
        password: '',
    };

    return (
        <>
            <Header isLoggedIn={false} />
            <main className={styles.container}>
                <div className={styles.wrapper}>
                    <Title text={'Sign In'} />
                    <p className={styles.subTitle}>Hello User, enter your login details</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            email: validationSchema.email,
                            password: validationSchema.password,
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({values}) => (
                            <Form className={styles.form}>
                                <label className={styles.label}>
                                    E-mail
                                    <Input
                                        type={'email'}
                                        name={'email'}
                                        placeholder={'Write your E-mail'}
                                    />
                                </label>
                                <label className={styles.label}>
                                    Password
                                    <Input
                                        type={'password'}
                                        name={'password'}
                                        placeholder={'Sales Simulator'}
                                    />
                                </label>
                                <div className={styles.inner}>
                                    <span
                                        className={styles.forgot}
                                        onClick={() => handleForgotPassword(values)}
                                    >
                                        Forgot your password?
                                    </span>
                                </div>
                                <Button
                                    type={'submit'}
                                    text={'Login'}
                                    modifier={'button_accent'}
                                />
                            </Form>
                        )}
                    </Formik>
                    <div className={styles.box}>
                        <p className={styles.text}>
                            Don't have an account?{' '}
                            <Link
                                to={ROUTS.register}
                                className={styles.goto}
                            >
                                Register here.
                            </Link>
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};
