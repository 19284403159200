import React from 'react';
import {Link} from 'react-router-dom';

import {useSelector} from 'react-redux';
import {Logo} from '../../assets/icons/Logo/Logo';

import styles from './Footer.module.scss';

export const Footer = () => {
    const company = useSelector(state => state.company.company);

    const copyrightDate = () => {
        const date = new Date();
        const currentYear = date.getFullYear();

        return currentYear;
    };

    return (
        <footer className={styles.wrapper}>
            <div className={styles.body}>
                <Link to={'/'}>
                    <Logo
                        logo={company?.logo}
                        text={company?.name}
                    />
                </Link>
                <div className={styles.inner}>
                    <div className={`${styles.list} ${styles.list_info}`}>
                        {company?.phone && (
                            <a
                                className={styles.phone}
                                href={`tel:${company?.phone}`}
                            >
                                {company?.phone}
                            </a>
                        )}
                        {company?.address && <span className={styles.address}>{company?.address}</span>}
                        {company?.email && <a href={`mailto:${company?.email}`}>{company?.email}</a>}
                    </div>
                    {company?.social_media && (
                        <div className={`${styles.list} ${styles.list_socialNetworks}`}>
                            {company?.social_media?.map((item, index) => (
                                <a
                                    key={index}
                                    href={item?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item?.title}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <span className={styles.copyright}>
                    ©{copyrightDate()} {company?.copyright}
                </span>
            </div>
        </footer>
    );
};
