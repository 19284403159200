import styles from './HomePage.module.scss';
import {Header} from '../../components/Header';
import {useEffect} from 'react';
import {apiRefreshUser} from '../../api';
import {useDispatch} from 'react-redux';
import {useAuth} from '../../hooks';

export const HomePage = () => {
    const dispatch = useDispatch();
    const {isLoggedIn} = useAuth();

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <main className={styles.container}>
            <Header isLoggedIn={isLoggedIn} />
            <h1 className={styles.title}>Sales Simulator</h1>
        </main>
    );
};
