import styles from './Evaluate.module.scss';
import {Rating} from '../../assets/icons';
import {useEffect, useState} from 'react';

export const Evaluate = ({setEvaluate, evaluateValue, disabled}) => {
    const [rating, setRating] = useState(evaluateValue);
    const [hover, setHover] = useState(evaluateValue ?? 0);

    const MAX_EVALUATE = 10;

    useEffect(() => {
        setEvaluate(rating);
    }, [rating]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                {[...Array(MAX_EVALUATE)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            disabled={disabled}
                            type={'button'}
                            key={index}
                            className={index <= (hover || rating) ? `${styles.on}` : `${styles.off}`}
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <Rating />
                        </button>
                    );
                })}
            </div>
            <div className={styles.value}>
                <span>{hover}</span>/<span>{MAX_EVALUATE}</span>
            </div>
        </div>
    );
};
