import styles from './ModalTestDescription.module.scss';
import {CrossSvg} from '../../../assets/icons';

export const ModalTestDescription = props => {
    const {description, onClose} = props;

    return (
        <div className={styles.wrapper}>
            <button
                className={styles.deleteBtn}
                type={'button'}
                onClick={() => onClose(false)}
            >
                <CrossSvg />
            </button>
            <p className={styles.text}>{description}</p>
        </div>
    );
};
