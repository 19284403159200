import React, {useEffect, useState} from 'react';
import {apiNeedEvaluationTests, apiRefreshUser} from '../../api';
import {useDispatch} from 'react-redux';
import {Table} from '../Table';
import {TYPE_TABLES} from '../Table/mock';
import {useAuth} from '../../hooks';

export const NeedEvaluation = () => {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const {user} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const {data} = await apiNeedEvaluationTests(user.organization_id);
            setList(data?.results);
            setCount(data?.count);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <>
            <Table
                isLoading={isLoading}
                list={list}
                pageCount={pageCount}
                setPage={setPage}
                typeTables={TYPE_TABLES.needEvaluation}
            />
        </>
    );
};
