import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Modal} from '../../../Modal';
import {TableMobileDetails} from '../TableMobileDetails';

import {ROUTS} from '../../../../constants';

import defaultImage from '../../../../assets/img/default.jpg';
import {Copy, DeleteQuiz, Edit} from '../../../../assets/icons';

import styles from './TableMobileItem.module.scss';
import {Tooltip} from 'react-tooltip';

export const TableMobileItem = props => {
    const {id, position, item, typeTables, fetch} = props;

    const navigate = useNavigate();

    const [showQuiz, setShowQuiz] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [questionId, setQuestionId] = useState(item?.answers?.score?.id);
    const [evaluateValue, setEvaluateValue] = useState(item?.answers?.score?.value);
    const [tooltip, setTooltip] = useState({
        content: 'Copy to clipboard',
        variant: 'dark',
    });

    const cssClass = styles.open;

    const handleToggle = async event => {
        const wrapper = event.target.getAttribute('data-wrapper');
        const edit = event.target.getAttribute('data-edit');
        const copy = event.target.getAttribute('data-copy');

        if (edit) {
            navigate(ROUTS.testCreate);
            return;
        }

        if (copy) {
            await handleCopy();
            return;
        }

        if (!wrapper) {
            setShowModal(prev => !prev);
            return;
        }

        event.currentTarget.classList.toggle(cssClass);

        setShowQuiz(prev => !prev);
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(`${window.origin}?test=${id}&organization=${item.organization}`);
            setTooltip({
                content: 'Text copied!',
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleMouseEnter = () => {
        setTooltip({
            content: 'Copy to clipboard',
            variant: 'dark',
        });
    };

    return (
        <>
            <div className={styles.item}>
                {showModal && (
                    <Modal
                        confirm={true}
                        setShowModal={setShowModal}
                        updateFetch={() => {
                            fetch(id);
                        }}
                    />
                )}
                <div
                    className={styles.wrapper}
                    onClick={handleToggle}
                    data-wrapper="wrapper"
                >
                    <span
                        data-wrapper="wrapper"
                        className={styles.position}
                    >
                        {position}
                    </span>
                    {typeTables?.photo && (
                        <img
                            className={styles.img}
                            data-wrapper="wrapper"
                            src={item?.picture ? item?.picture : defaultImage}
                            alt=""
                        />
                    )}
                    {typeTables?.name && (
                        <span
                            data-wrapper="wrapper"
                            className={styles.name}
                        >
                            {item?.name}
                            {item?.question_name}
                        </span>
                    )}
                    {typeTables?.type?.edit && (
                        <>
                            <button
                                className={styles.icon}
                                type={'button'}
                                data-edit="edit"
                            >
                                <Link to={`${id}`}>
                                    <Edit />
                                </Link>
                            </button>
                            {typeTables?.type?.copy && (
                                <button
                                    className={styles.icon}
                                    type={'button'}
                                    onClick={handleCopy}
                                    onMouseEnter={handleMouseEnter}
                                    data-copy={'copy'}
                                    data-tooltip-id={'copy-tooltip'}
                                    data-tooltip-content={tooltip.content}
                                    data-tooltip-variant={tooltip.variant}
                                >
                                    <Copy />
                                </button>
                            )}
                            <button
                                className={styles.icon}
                                type={'button'}
                            >
                                <DeleteQuiz />
                            </button>
                        </>
                    )}
                    {typeTables?.type?.startTest && (
                        <span
                            className={`${styles.changeRating}`}
                            data-wrapper="wrapper"
                        >
                            <Link
                                className={styles.evaluate}
                                to={`/user-test/${item?.id}`}
                            >
                                Start test
                            </Link>
                        </span>
                    )}
                </div>
                <TableMobileDetails
                    toggle={showQuiz}
                    item={item}
                    typeTables={typeTables}
                    evaluateValue={evaluateValue}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                    setEvaluateValue={setEvaluateValue}
                />
            </div>
            <Tooltip
                id={'copy-tooltip'}
                place={'top'}
                style={{fontSize: '1.6rem'}}
            />
        </>
    );
};
