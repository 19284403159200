export const Users = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.2109 19.5938H6.1337C5.8335 19.5938 5.6525 19.4262 5.5712 19.3264C5.43098 19.1539 5.37674 18.9295 5.42233 18.7104C6.15251 15.2031 9.24777 12.6419 12.8211 12.5588C12.8804 12.561 12.94 12.5625 13 12.5625C15.6363 12.5625 17.7813 10.4177 17.7813 7.78125C17.7813 5.14481 15.6363 3 13 3C10.3636 3 8.21876 5.14481 8.21876 7.78125C8.21876 9.34598 8.97435 10.7377 10.1399 11.6104C9.07213 11.9618 8.07004 12.5085 7.19319 13.2298C5.58548 14.5523 4.46762 16.3969 4.04561 18.4238C3.91336 19.059 4.07184 19.7113 4.48026 20.2137C4.88661 20.7134 5.48935 21 6.1337 21H13.2109C13.5992 21 13.9141 20.6852 13.9141 20.2969C13.9141 19.9085 13.5992 19.5938 13.2109 19.5938ZM9.62501 7.78125C9.62501 5.9203 11.1389 4.40625 13 4.40625C14.861 4.40625 16.375 5.9203 16.375 7.78125C16.375 9.61555 14.9041 11.1127 13.0798 11.1553C13.0536 11.1523 13.0271 11.1503 13 11.1503C12.9451 11.1503 12.8903 11.1512 12.8355 11.1521C11.0506 11.0662 9.62501 9.58713 9.62501 7.78125ZM22 17.4837C22 17.8721 21.6851 18.1868 21.2969 18.1868H16.0234C15.6351 18.1868 15.3203 17.8721 15.3203 17.4837C15.3203 17.0955 15.6351 16.7806 16.0234 16.7806H21.2969C21.6851 16.7806 22 17.0955 22 17.4837ZM16.0234 13.9688H21.2969C21.6851 13.9688 22 14.2835 22 14.6719C22 15.0602 21.6851 15.375 21.2969 15.375H16.0234C15.6351 15.375 15.3203 15.0602 15.3203 14.6719C15.3203 14.2835 15.6351 13.9688 16.0234 13.9688ZM22 20.2969C22 20.6852 21.6851 21 21.2969 21H16.0234C15.6351 21 15.3203 20.6852 15.3203 20.2969C15.3203 19.9085 15.6351 19.5938 16.0234 19.5938H21.2969C21.6851 19.5938 22 19.9085 22 20.2969Z"
                fill="#070806"
            />
        </svg>
    );
};
