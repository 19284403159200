import styles from './UserCheckedPage.module.scss';
import {Header} from '../../components/Header';
import {UserHeader} from '../../components/UserHeader';
import {Title} from '../../components/Title';
import {Footer} from '../../components/Footer';
import {SORT_TYPES} from '../../constants';
import {useEffect, useState} from 'react';
import {Table} from '../../components/Table';
import {useDispatch} from 'react-redux';
import {apiRefreshUser, apiTestQuiz, apiUserAnswersTest, apiUserInfo} from '../../api';
import {useAuth} from '../../hooks';
import {TYPE_TABLES} from '../../components/Table/mock';
import {useParams} from 'react-router-dom';

export const UserCheckedPage = () => {
    const [count, setCount] = useState(0);
    const [sort, setSort] = useState(SORT_TYPES.dateDescending);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [testInfo, setTestInfo] = useState({});
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    const {isLoggedIn} = useAuth();
    const {id, test_id} = useParams();
    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    const fetchAnswers = async () => {
        const {data} = await apiUserAnswersTest(id, test_id);

        setList(data?.questions);

        setOptions(data?.options);
    };
    const fetchUserInfo = async () => {
        const data = await apiUserInfo(id);

        setUserInfo(data?.data);
    };

    const fetchTestInfo = async () => {
        const data = await apiTestQuiz(test_id);

        setTestInfo(data?.data);
    };

    useEffect(() => {
        fetchUserInfo();
        fetchTestInfo();
        fetchAnswers(id, test_id);
    }, []);

    useEffect(() => {
        dispatch(apiRefreshUser());
    }, []);

    return (
        <div className={styles.containerPage}>
            <div className={styles.wrapperPage}>
                <Header isLoggedIn={isLoggedIn} />
                <main className={styles.main}>
                    <UserHeader />
                    <div className={styles.container}>
                        <Title text={`${userInfo.first_name} ${userInfo.last_name} - ${testInfo.name}`} />
                        <Table
                            pageCount={pageCount}
                            list={list}
                            typeTables={TYPE_TABLES.commitUserAnswer}
                            setSort={setSort}
                            setPage={setPage}
                            isModalVideo={true}
                        />
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
