export const mock = [
    {
        type: 'text',
        name: 'senior_admin.first_name',
        placeholder: 'Write name',
        label: 'Name*',
    },
    {
        type: 'text',
        name: 'senior_admin.last_name',
        placeholder: 'Write middle name',
        label: 'Last name*',
    },
    {
        type: 'email',
        name: 'senior_admin.email',
        placeholder: 'Write E-mail',
        label: 'E-mail*',
    },
];
