import styles from './ModalVideo.module.scss';
import ReactPlayer from 'react-player';
import {useEffect, useState} from 'react';

import {CrossSvg} from 'assets/icons';

export const ModalVideo = ({videoLink, onClose}) => {
    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);

    const handlePlay = () => {
        const player = document.getElementById('video-player');

        player.requestFullscreen();
    };

    useEffect(() => {
        setScreenHeight(window.innerHeight * 0.8);
        setScreenWidth(window.innerWidth);
    }, []);

    return (
        <div className={styles.wrapper}>
            <button
                className={styles.deleteBtn}
                type={'button'}
                onClick={() => onClose(prev => !prev)}
            >
                <CrossSvg />
            </button>
            <ReactPlayer
                url={videoLink}
                width={screenWidth}
                height={screenHeight}
                controls={true}
                playing={true}
                onPlay={handlePlay}
            />
        </div>
    );
};
