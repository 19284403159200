import styles from './UsersInGroupMobile.module.scss';
import {TableMobileDetailsColum} from '../../../TableMobile/TableMobileDetailsColum';
import {useState} from 'react';

export const UsersInGroupMobile = props => {
    const {typeTables, list} = props;

    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow(prev => !prev);
    };

    return (
        <>
            <div className={styles.body}>
                <div className={styles.questionTable}>
                    <div className={styles.header}>
                        {typeTables?.admin && (
                            <div className={`${styles.row} ${styles.admin}`}>
                                <span className={styles.post}>Admin</span>
                                <span className={styles.name}>J.Baden</span>
                            </div>
                        )}
                        {typeTables?.users && (
                            <div className={`${styles.row} ${styles.users}`}>
                                <span className={styles.post}>User's</span>
                                {list?.users?.length ? (
                                    <button
                                        className={styles.quantity}
                                        type={'button'}
                                        onClick={handleClick}
                                    >
                                        <span>{list?.users?.length}</span>
                                    </button>
                                ) : (
                                    <span>0</span>
                                )}
                            </div>
                        )}
                    </div>
                    {isShow &&
                        list?.users?.length > 0 &&
                        list?.users.map((elem, index) => {
                            const quantity = index + 1;
                            return (
                                <TableMobileDetailsColum
                                    key={index}
                                    quantity={quantity}
                                    elem={elem}
                                    typeTables={typeTables}
                                />
                            );
                        })}
                </div>
            </div>
        </>
    );
};
