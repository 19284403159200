import React from 'react';
import styles from './Requests.module.scss';

export const Requests = props => {
    const {typeTables, list} = props;
    return (
        <>
            <div className={styles.body}>
                <div className={styles.questionTable}>
                    <div className={styles.header}>
                        {typeTables?.sizeQuestions?.email && (
                            <div
                                className={styles.question}
                                style={typeTables?.sizeQuestions?.email}
                            >
                                <span>Email</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.phone && (
                            <div
                                className={styles.answer}
                                style={typeTables?.sizeQuestions?.phone}
                            >
                                <span>Phone</span>
                            </div>
                        )}
                        {typeTables?.sizeQuestions?.date && (
                            <div
                                className={styles.answer}
                                style={typeTables?.sizeQuestions?.date}
                            >
                                <span>Date</span>
                            </div>
                        )}
                    </div>
                    <div className={styles.row}>
                        <div
                            className={styles.description}
                            style={typeTables?.sizeQuestions?.email}
                        >
                            <span>{list?.email}</span>
                        </div>
                        <div
                            className={styles.description}
                            style={typeTables?.sizeQuestions?.phone}
                        >
                            <span>{list?.phone}</span>
                        </div>
                        <div
                            className={styles.description}
                            style={typeTables?.sizeQuestions?.date}
                        >
                            <span>{list?.updated_at}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
