export const Notification = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.96533 11.33L2.82033 10.1C2.93704 9.9328 2.99984 9.73391 3.00033 9.53V6C3.00033 4.67392 3.52711 3.40215 4.46479 2.46447C5.40247 1.52678 6.67424 1 8.00033 1C9.32641 1 10.5982 1.52678 11.5359 2.46447C12.4735 3.40215 13.0003 4.67392 13.0003 6V9.53C13.0008 9.73391 13.0636 9.9328 13.1803 10.1L14.0353 11.33C14.1648 11.4734 14.2499 11.6513 14.2804 11.8421C14.3109 12.0329 14.2855 12.2285 14.2072 12.4051C14.129 12.5818 14.0012 12.732 13.8393 12.8375C13.6775 12.9431 13.4885 12.9995 13.2953 13H10.4503C10.3356 13.5651 10.029 14.0732 9.58246 14.4382C9.13595 14.8031 8.577 15.0025 8.00033 15.0025C7.42365 15.0025 6.8647 14.8031 6.4182 14.4382C5.97169 14.0732 5.66508 13.5651 5.55033 13H2.70533C2.5121 12.9995 2.32315 12.9431 2.16132 12.8375C1.99948 12.732 1.8717 12.5818 1.79342 12.4051C1.71514 12.2285 1.68972 12.0329 1.72023 11.8421C1.75073 11.6513 1.83587 11.4734 1.96533 11.33ZM8.00033 14C8.3098 13.9991 8.61143 13.9026 8.86386 13.7235C9.11629 13.5445 9.30717 13.2918 9.41033 13H6.59033C6.69348 13.2918 6.88436 13.5445 7.13679 13.7235C7.38923 13.9026 7.69085 13.9991 8.00033 14ZM13.2953 12C13.2727 11.979 13.2526 11.9555 13.2353 11.93L12.3603 10.67C12.1269 10.3356 12.0013 9.93782 12.0003 9.53V6C12.0003 4.93913 11.5789 3.92172 10.8288 3.17157C10.0786 2.42143 9.06119 2 8.00033 2C6.93946 2 5.92204 2.42143 5.1719 3.17157C4.42175 3.92172 4.00033 4.93913 4.00033 6V9.53C3.99936 9.93782 3.87375 10.3356 3.64033 10.67L2.76533 11.93C2.74806 11.9555 2.72793 11.979 2.70533 12H13.2953Z"
                fill="black"
            />
        </svg>
    );
};
